import { Component, OnInit } from '@angular/core';
import * as JSZip from 'jszip';
@Component({
  selector: 'app-zip-upload',
  templateUrl: './zip-upload.component.html',
  styleUrls: ['./zip-upload.component.scss']
})
export class ZipUploadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
