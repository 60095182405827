export const countries = [
  {
    "name": "Afghanistan (‫افغانستان‬‎)",
    "iso2": "AF",
    "phoneCode": "93"
  },
  {
    "name": "Albania (Shqipëri)",
    "iso2": "AL",
    "phoneCode": "355"
  },
  {
    "name": "Algeria (‫الجزائر‬‎)",
    "iso2": "DZ",
    "phoneCode": "213"
  },
  {
    "name": "American Samoa",
    "iso2": "AS",
    "phoneCode": "1",
    "multipleCodes": [ "684" ]
  },
  {
    "name": "Andorra",
    "iso2": "AD",
    "phoneCode": "376"
  },
  {
    "name": "Angola",
    "iso2": "AO",
    "phoneCode": "244"
  },
  {
    "name": "Anguilla",
    "iso2": "AI",
    "phoneCode": "1",
    "multipleCodes": [ "264" ]
  },
  {
    "name": "Antigua and Barbuda",
    "iso2": "AG",
    "phoneCode": "1",
    "multipleCodes": [ "268" ]
  },
  {
    "name": "Argentina",
    "iso2": "AR",
    "phoneCode": "54"
  },
  {
    "name": "Armenia (Հայաստան)",
    "iso2": "AM",
    "phoneCode": "374"
  },
  {
    "name": "Aruba",
    "iso2": "AW",
    "phoneCode": "297"
  },
  {
    "name": "Australia",
    "iso2": "AU",
    "phoneCode": "61",
    "multipleCodes": [ "0" ]
  },
  {
    "name": "Austria (Österreich)",
    "iso2": "AT",
    "phoneCode": "43"
  },
  {
    "name": "Azerbaijan (Azərbaycan)",
    "iso2": "AZ",
    "phoneCode": "994"
  },
  {
    "name": "Bahamas",
    "iso2": "BS",
    "phoneCode": "1",
    "multipleCodes": [ "242" ]
  },
  {
    "name": "Bahrain (‫البحرين‬‎)",
    "iso2": "BH",
    "phoneCode": "973"
  },
  {
    "name": "Bangladesh (বাংলাদেশ)",
    "iso2": "BD",
    "phoneCode": "880"
  },
  {
    "name": "Barbados",
    "iso2": "BB",
    "phoneCode": "1",
    "multipleCodes": [ "246" ]
  },
  {
    "name": "Belarus (Беларусь)",
    "iso2": "BY",
    "phoneCode": "375"
  },
  {
    "name": "Belgium (België)",
    "iso2": "BE",
    "phoneCode": "32"
  },
  {
    "name": "Belize",
    "iso2": "BZ",
    "phoneCode": "501"
  },
  {
    "name": "Benin (Bénin)",
    "iso2": "BJ",
    "phoneCode": "229"
  },
  {
    "name": "Bermuda",
    "iso2": "BM",
    "phoneCode": "1",
    "multipleCodes": [ "441" ]
  },
  {
    "name": "Bhutan (འབྲུག)",
    "iso2": "BT",
    "phoneCode": "975"
  },
  {
    "name": "Bolivia",
    "iso2": "BO",
    "phoneCode": "591"
  },
  {
    "name": "Bosnia and Herzegovina (Босна и Херцеговина)",
    "iso2": "BA",
    "phoneCode": "387"
  },
  {
    "name": "Botswana",
    "iso2": "BW",
    "phoneCode": "267"
  },
  {
    "name": "Brazil (Brasil)",
    "iso2": "BR",
    "phoneCode": "55"
  },
  {
    "name": "British Indian Ocean Territory",
    "iso2": "IO",
    "phoneCode": "246"
  },
  {
    "name": "British Virgin Islands",
    "iso2": "VG",
    "phoneCode": "1",
    "multipleCodes": [ "284" ]
  },
  {
    "name": "Brunei",
    "iso2": "BN",
    "phoneCode": "673"
  },
  {
    "name": "Bulgaria (България)",
    "iso2": "BG",
    "phoneCode": "359"
  },
  {
    "name": "Burkina Faso",
    "iso2": "BF",
    "phoneCode": "226"
  },
  {
    "name": "Burundi (Uburundi)",
    "iso2": "BI",
    "phoneCode": "257"
  },
  {
    "name": "Cambodia (កម្ពុជា)",
    "iso2": "KH",
    "phoneCode": "855"
  },
  {
    "name": "Cameroon (Cameroun)",
    "iso2": "CM",
    "phoneCode": "237"
  },
  {
    "name": "Canada",
    "iso2": "CA",
    "phoneCode": "1",
    "multipleCodes": [ "204", "226", "236", "249", "250", "289", "306", "343", "365", "387", "403", "416", "418", "431", "437", "438", "450", "506", "514", "519", "548", "579", "581", "587", "604", "613", "639", "647", "672", "705", "709", "742", "778", "780", "782", "807", "819", "825", "867", "873", "902", "905" ]
  },
  {
    "name": "Cape Verde (Kabu Verdi)",
    "iso2": "CV",
    "phoneCode": "238"
  },
  {
    "name": "Caribbean Netherlands",
    "iso2": "BQ",
    "phoneCode": "599",
    "multipleCodes": [ "1" ]
  },
    {
      "name": "Cayman Islands",
      "iso2": "KY",
      "phoneCode": "1",
      "multipleCodes": [ "345" ]
    },
    {
      "name": "Central African Republic (République centrafricaine)",
      "iso2": "CF",
      "phoneCode": "236"
    },
    {
      "name": "Chad (Tchad)",
      "iso2": "TD",
      "phoneCode": "235"
    },
    {
      "name": "Chile",
      "iso2": "CL",
      "phoneCode": "56"
    },
    {
      "name": "China (中国)",
      "iso2": "CN",
      "phoneCode": "86"
    },
    {
      "name": "Christmas Island",
      "iso2": "CX",
      "phoneCode": "61",
      "multipleCodes": [ "2" ]
    },
    {
      "name": "Cocos (Keeling) Islands",
      "iso2": "CC",
      "phoneCode": "61",
      "multipleCodes": [ "1" ]
    },
    {
      "name": "Colombia",
      "iso2": "CO",
      "phoneCode": "57"
    },
    {
      "name": "Comoros (‫جزر القمر‬‎)",
      "iso2": "KM",
      "phoneCode": "269"
    },
    {
      "name": "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
      "iso2": "CD",
      "phoneCode": "243"
    },
    {
      "name": "Congo (Republic) (Congo-Brazzaville)",
      "iso2": "CG",
      "phoneCode": "242"
    },
    {
      "name": "Cook Islands",
      "iso2": "CK",
      "phoneCode": "682"
    },
    {
      "name": "Costa Rica",
      "iso2": "CR",
      "phoneCode": "506"
    },
    {
      "name": "Côte d’Ivoire",
      "iso2": "CI",
      "phoneCode": "225"
    },
    {
      "name": "Croatia (Hrvatska)",
      "iso2": "HR",
      "phoneCode": "385"
    },
    {
      "name": "Cuba",
      "iso2": "CU",
      "phoneCode": "53"
    },
    {
      "name": "Curaçao",
      "iso2": "CW",
      "phoneCode": "599",
      "multipleCodes": [ "0" ]
    },
    {
      "name": "Cyprus (Κύπρος)",
      "iso2": "CY",
      "phoneCode": "357"
    },
    {
      "name": "Czech Republic (Česká republika)",
      "iso2": "CZ",
      "phoneCode": "420"
    },
    {
      "name": "Denmark (Danmark)",
      "iso2": "DK",
      "phoneCode": "45"
    },
    {
      "name": "Djibouti",
      "iso2": "DJ",
      "phoneCode": "253"
    },
    {
      "name": "Dominica",
      "iso2": "DM",
      "phoneCode": "1767"
    },
    {
      "name": "Dominican Republic (República Dominicana)",
      "iso2": "DO",
      "phoneCode": "1",
      "multipleCodes": [ "2" ],
      "multipleAreaCodes": [ "809", "829", "849" ]
    },
    {
      "name": "Ecuador",
      "iso2": "EC",
      "phoneCode": "593"
    },
    {
      "name": "Egypt (‫مصر‬‎)",
      "iso2": "EG",
      "phoneCode": "20"
    },
    {
      "name": "El Salvador",
      "iso2": "SV",
      "phoneCode": "503"
    },
    {
      "name": "Equatorial Guinea (Guinea Ecuatorial)",
      "iso2": "GQ",
      "phoneCode": "240"
    },
    {
      "name": "Eritrea",
      "iso2": "ER",
      "phoneCode": "291"
    },
    {
      "name": "Estonia (Eesti)",
      "iso2": "EE",
      "phoneCode": "372"
    },
    {
      "name": "Ethiopia",
      "iso2": "ET",
      "phoneCode": "251"
    },
    {
      "name": "Falkland Islands (Islas Malvinas)",
      "iso2": "FK",
      "phoneCode": "500"
    },
    {
      "name": "Faroe Islands (Føroyar)",
      "iso2": "FO",
      "phoneCode": "298"
    },
    {
      "name": "Fiji",
      "iso2": "FJ",
      "phoneCode": "679"
    },
    {
      "name": "Finland (Suomi)",
      "iso2": "FI",
      "phoneCode": "358",
    "multipleCodes": [ "0" ]
    },
    {
      "name": "France",
      "iso2": "FR",
      "phoneCode": "33"
    },
    {
      "name": "French Guiana (Guyane française)",
      "iso2": "GF",
      "phoneCode": "594"
    },
    {
      "name": "French Polynesia (Polynésie française)",
      "iso2": "PF",
      "phoneCode": "689"
    },
    {
      "name": "Gabon",
      "iso2": "GA",
      "phoneCode": "241"
    },
    {
      "name": "Gambia",
      "iso2": "GM",
      "phoneCode": "220"
    },
    {
      "name": "Georgia (საქართველო)",
      "iso2": "GE",
      "phoneCode": "995"
    },
    {
      "name": "Germany (Deutschland)",
      "iso2": "DE",
      "phoneCode": "49"
    },
    {
      "name": "Ghana (Gaana)",
      "iso2": "GH",
      "phoneCode": "233"
    },
    {
      "name": "Gibraltar",
      "iso2": "GI",
      "phoneCode": "350"
    },
    {
      "name": "Greece (Ελλάδα)",
      "iso2": "GR",
      "phoneCode": "30"
    },
      {
        "name": "Greenland (Kalaallit Nunaat)",
        "iso2": "GL",
        "phoneCode": "299"
      },
      {
        "name": "Grenada",
        "iso2": "GD",
        "phoneCode": "1473"
      },
      {
        "name": "Guadeloupe",
        "iso2": "GP",
        "phoneCode": "590",
        "multipleCodes": [ "0" ]
      },
      {
        "name": "Guam",
        "iso2": "GU",
        "phoneCode": "1",
        "multipleCodes": [ "671" ]
      },
      {
        "name": "Guatemala",
        "iso2": "GT",
        "phoneCode": "502"
      },
      {
        "name": "Guernsey",
        "iso2": "GG",
        "phoneCode": "44",
        "multipleCodes": [ "1" ],
        "multipleAreaCodes": [ "1481" ]
      },
      {
        "name": "Guinea (Guinée)",
        "iso2": "GN",
        "phoneCode": "224"
      },
      {
        "name": "Guinea-Bissau (Guiné Bissau)",
        "iso2": "GW",
        "phoneCode": "245"
      },
      {
        "name": "Guyana",
        "iso2": "GY",
        "phoneCode": "592"
      },
      {
        "name": "Haiti",
        "iso2": "HT",
        "phoneCode": "509"
      },
      {
        "name": "Honduras",
        "iso2": "HN",
        "phoneCode": "504"
      },
      {
        "name": "Hong Kong (香港)",
        "iso2": "HK",
        "phoneCode": "852"
      },
      {
        "name": "Hungary (Magyarország)",
        "iso2": "HU",
        "phoneCode": "36"
      },
      {
        "name": "Iceland (Ísland)",
        "iso2": "IS",
        "phoneCode": "354"
      },
      {
        "name": "India (भारत)",
        "iso2": "IN",
        "phoneCode": "91"
      },
      {
        "name": "Indonesia",
        "iso2": "ID",
        "phoneCode": "62"
      },
      {
        "name": "Iran (‫ایران‬‎)",
        "iso2": "IR",
        "phoneCode": "98"
      },
      {
        "name": "Iraq (‫العراق‬‎)",
        "iso2": "IQ",
        "phoneCode": "964"
      },
      {
        "name": "Ireland",
        "iso2": "IE",
        "phoneCode": "353"
      },
      {
        "name": "Isle of Man",
        "iso2": "IM",
        "phoneCode": "44",
        "multipleCodes": [ "2" ],
        "multipleAreaCodes": [ "1624" ]
      },
      {
        "name": "Italy (Italia)",
        "iso2": "IT",
        "phoneCode": "39",
        "multipleCodes": [ "0" ]
      },
        {
          "name": "Jamaica",
          "iso2": "JM",
          "phoneCode": "1",
          "multipleCodes": ["876"]
        },
        {
          "name": "Japan (日本)",
          "iso2": "JP",
          "phoneCode": "81"
        },
        {
          "name": "Jersey",
          "iso2": "JE",
          "phoneCode": "44",
          "multipleCodes": ["3"],
          "multipleAreaCodes": ["1534"]
        },
        {
          "name": "Jordan (‫الأردن‬‎)",
          "iso2": "JO",
          "phoneCode": "962"
        },
        {
          "name": "Kazakhstan (Казахстан)",
          "iso2": "KZ",
          "phoneCode": "7",
          "multipleCodes": ["1"]
        },
        {
          "name": "Kenya",
          "iso2": "KE",
          "phoneCode": "254"
        },
        {
          "name": "Kiribati",
          "iso2": "KI",
          "phoneCode": "686"
        },
        {
          "name": "Kosovo",
          "iso2": "XK",
          "phoneCode": "383"
        },
        {
          "name": "Kuwait (‫الكويت‬‎)",
          "iso2": "KW",
          "phoneCode": "965"
        },
        {
          "name": "Kyrgyzstan (Кыргызстан)",
          "iso2": "KG",
          "phoneCode": "996"
        },
        {
          "name": "Laos (ລາວ)",
          "iso2": "LA",
          "phoneCode": "856"
        },
        {
          "name": "Latvia (Latvija)",
          "iso2": "LV",
          "phoneCode": "371"
        },
        {
          "name": "Lebanon (‫لبنان‬‎)",
          "iso2": "LB",
          "phoneCode": "961"
        },
        {
          "name": "Lesotho",
          "iso2": "LS",
          "phoneCode": "266"
        },
        {
          "name": "Liberia",
          "iso2": "LR",
          "phoneCode": "231"
        },
        {
          "name": "Libya (‫ليبيا‬‎)",
          "iso2": "LY",
          "phoneCode": "218"
        },
        {
          "name": "Liechtenstein",
          "iso2": "LI",
          "phoneCode": "423"
        },
        {
          "name": "Lithuania (Lietuva)",
          "iso2": "LT",
          "phoneCode": "370"
        },
        {
          "name": "Luxembourg",
          "iso2": "LU",
          "phoneCode": "352"
        },
        {
          "name": "Macau (澳門)",
          "iso2": "MO",
          "phoneCode": "853"
        },
        {
          "name": "Macedonia (FYROM) (Македонија)",
          "iso2": "MK",
          "phoneCode": "389"
        },
        {
          "name": "Madagascar (Madagasikara)",
          "iso2": "MG",
          "phoneCode": "261"
        },
        {
          "name": "Malawi",
          "iso2": "MW",
          "phoneCode": "265"
        },
        {
          "name": "Malaysia",
          "iso2": "MY",
          "phoneCode": "60"
        },
        {
          "name": "Maldives",
          "iso2": "MV",
          "phoneCode": "960"
        },
        {
          "name": "Mali",
          "iso2": "ML",
          "phoneCode": "223"
        },
        {
          "name": "Malta",
          "iso2": "MT",
          "phoneCode": "356"
        },
        {
          "name": "Marshall Islands",
          "iso2": "MH",
          "phoneCode": "692"
        },
        {
          "name": "Martinique",
          "iso2": "MQ",
          "phoneCode": "596"
        },
        {
          "name": "Mauritania (‫موريتانيا‬‎)",
          "iso2": "MR",
          "phoneCode": "222"
        },
        {
          "name": "Mauritius (Moris)",
          "iso2": "MU",
          "phoneCode": "230"
        },
        {
          "name": "Mayotte",
          "iso2": "YT",
          "phoneCode": "262",
          "multipleCodes": ["1"]
        },
        {
          "name": "Mexico (México)",
          "iso2": "MX",
          "phoneCode": "52"
        },
        {
          "name": "Micronesia",
          "iso2": "FM",
          "phoneCode": "691"
        },
        {
          "name": "Moldova (Republica Moldova)",
          "iso2": "MD",
          "phoneCode": "373"
        },
        {
          "name": "Monaco",
          "iso2": "MC",
          "phoneCode": "377"
        },
        {
          "name": "Mongolia (Монгол)",
          "iso2": "MN",
          "phoneCode": "976"
        },
        {
          "name": "Montenegro (Crna Gora)",
          "iso2": "ME",
          "phoneCode": "382"
        },
        {
          "name": "Montserrat",
          "iso2": "MS",
          "phoneCode": "1",
          "multipleCodes": ["664"]
        },
        {
          "name": "Morocco (‫المغرب‬‎)",
          "iso2": "MA",
          "phoneCode": "212",
          "multipleCodes": ["0"]
        },
        {
          "name": "Mozambique (Moçambique)",
          "iso2": "MZ",
          "phoneCode": "258"
        },
        {
          "name": "Myanmar (Burma) (မြန်မာ)",
          "iso2": "MM",
          "phoneCode": "95"
        },
        {
          "name": "Namibia (Namibië)",
          "iso2": "NA",
          "phoneCode": "264"
        },
        {
          "name": "Nauru",
          "iso2": "NR",
          "phoneCode": "674"
        },
        {
          "name": "Nepal (नेपाल)",
          "iso2": "NP",
          "phoneCode": "977"
        },
        {
          "name": "Netherlands (Nederland)",
          "iso2": "NL",
          "phoneCode": "31"
        },
        {
          "name": "New Caledonia (Nouvelle-Calédonie)",
          "iso2": "NC",
          "phoneCode": "687"
        },
        {
          "name": "New Zealand",
          "iso2": "NZ",
          "phoneCode": "64"
        },
        {
          "name": "Nicaragua",
          "iso2": "NI",
          "phoneCode": "505"
        },
        {
          "name": "Niger (Nijar)",
          "iso2": "NE",
          "phoneCode": "227"
        },
        {
          "name": "Nigeria",
          "iso2": "NG",
          "phoneCode": "234"
        },
        {
          "name": "Niue",
          "iso2": "NU",
          "phoneCode": "683"
        },
        {
          "name": "Norfolk Island",
          "iso2": "NF",
          "phoneCode": "672"
        },
        {
          "name": "North Korea (조선 민주주의 인민 공화국)",
          "iso2": "KP",
          "phoneCode": "850"
        },
        {
          "name": "Northern Mariana Islands",
          "iso2": "MP",
          "phoneCode": "1670"
        },
        {
          "name": "Norway (Norge)",
          "iso2": "NO",
          "phoneCode": "47",
          "multipleCodes": ["0"]
        },

          {
            "name": "Oman (‫عُمان‬‎)",
            "iso2": "OM",
            "phoneCode": "968"
          },
          {
            "name": "Pakistan (‫پاکستان‬‎)",
            "iso2": "PK",
            "phoneCode": "92"
          },
          {
            "name": "Palau",
            "iso2": "PW",
            "phoneCode": "680"
          },
          {
            "name": "Palestine (‫فلسطين‬‎)",
            "iso2": "PS",
            "phoneCode": "970"
          },
          {
            "name": "Panama (Panamá)",
            "iso2": "PA",
            "phoneCode": "507"
          },
          {
            "name": "Papua New Guinea",
            "iso2": "PG",
            "phoneCode": "675"
          },
          {
            "name": "Paraguay",
            "iso2": "PY",
            "phoneCode": "595"
          },
          {
            "name": "Peru (Perú)",
            "iso2": "PE",
            "phoneCode": "51"
          },
          {
            "name": "Philippines",
            "iso2": "PH",
            "phoneCode": "63"
          },
          {
            "name": "Poland (Polska)",
            "iso2": "PL",
            "phoneCode": "48"
          },
          {
            "name": "Portugal",
            "iso2": "PT",
            "phoneCode": "351"
          },
          {
            "name": "Puerto Rico",
            "iso2": "PR",
            "phoneCode": "1",
            "multipleCodes": ["787", "939"]
          },
          {
            "name": "Qatar (‫قطر‬‎)",
            "iso2": "QA",
            "phoneCode": "974"
          },
          {
            "name": "Réunion (La Réunion)",
            "iso2": "RE",
            "phoneCode": "262",
            "multipleCodes": ["0"]
          },
          {
            "name": "Romania (România)",
            "iso2": "RO",
            "phoneCode": "40"
          },
          {
            "name": "Russia (Россия)",
            "iso2": "RU",
            "phoneCode": "7",
            "multipleCodes": ["0"]
          },
          {
            "name": "Rwanda",
            "iso2": "RW",
            "phoneCode": "250"
          },
          {
            "name": "Saint Barthélemy (Saint-Barthélemy)",
            "iso2": "BL",
            "phoneCode": "590",
            "multipleCodes": ["1"]
          },
          {
            "name": "Saint Helena",
            "iso2": "SH",
            "phoneCode": "290"
          },
          {
            "name": "Saint Kitts and Nevis",
            "iso2": "KN",
            "phoneCode": "1869"
          },
          {
            "name": "Saint Lucia",
            "iso2": "LC",
            "phoneCode": "1",
            "multipleCodes": ["758"]
          },
          {
            "name": "Saint Martin (Saint-Martin (partie française))",
            "iso2": "MF",
            "phoneCode": "590",
            "multipleCodes": ["2"]
          },
          {
            "name": "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
            "iso2": "PM",
            "phoneCode": "508"
          },
          {
            "name": "Saint Vincent and the Grenadines",
            "iso2": "VC",
            "phoneCode": "1",
            "multipleCodes": ["784"]
          },
          {
            "name": "Samoa",
            "iso2": "WS",
            "phoneCode": "685"
          },
          {
            "name": "San Marino",
            "iso2": "SM",
            "phoneCode": "378"
          },
          {
            "name": "São Tomé and Príncipe (São Tomé e Príncipe)",
            "iso2": "ST",
            "phoneCode": "239"
          },
          {
            "name": "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
            "iso2": "SA",
            "phoneCode": "966"
          },
          {
            "name": "Senegal (Sénégal)",
            "iso2": "SN",
            "phoneCode": "221"
          },
          {
            "name": "Serbia (Србија)",
            "iso2": "RS",
            "phoneCode": "381"
          },
          {
            "name": "Seychelles",
            "iso2": "SC",
            "phoneCode": "248"
          },
          {
            "name": "Sierra Leone",
            "iso2": "SL",
            "phoneCode": "232"
          },
          {
            "name": "Singapore",
            "iso2": "SG",
            "phoneCode": "65"
          },
          {
            "name": "Sint Maarten",
            "iso2": "SX",
            "phoneCode": "1",
          "multipleCodes": ["721"]
          },
          {
            "name": "Slovakia (Slovensko)",
            "iso2": "SK",
            "phoneCode": "421"
          },
          {
            "name": "Slovenia (Slovenija)",
            "iso2": "SI",
            "phoneCode": "386"
          },
          {
            "name": "Solomon Islands",
            "iso2": "SB",
            "phoneCode": "677"
          },
          {
            "name": "Somalia (Soomaaliya)",
            "iso2": "SO",
            "phoneCode": "252"
          },
          {
            "name": "South Africa",
            "iso2": "ZA",
            "phoneCode": "27"
          },
          {
            "name": "South Korea (대한민국)",
            "iso2": "KR",
            "phoneCode": "82"
          },
          {
            "name": "South Sudan (‫جنوب السودان‬‎)",
            "iso2": "SS",
            "phoneCode": "211"
          },
          {
            "name": "Spain (España)",
            "iso2": "ES",
            "phoneCode": "34"
          },
          {
            "name": "Sri Lanka (ශ්‍රී ලංකාව)",
            "iso2": "LK",
            "phoneCode": "94"
          },
          {
            "name": "Sudan (‫السودان‬‎)",
            "iso2": "SD",
            "phoneCode": "249"
          },
          {
            "name": "Suriname",
            "iso2": "SR",
            "phoneCode": "597"
          },
          {
            "name": "Svalbard and Jan Mayen",
            "iso2": "SJ",
            "phoneCode": "47",
            "multipleCodes": ["1"]
          },
          {
            "name": "Swaziland",
            "iso2": "SZ",
            "phoneCode": "268"
          },
          {
            "name": "Sweden (Sverige)",
            "iso2": "SE",
            "phoneCode": "46"
          },
          {
            "name": "Switzerland (Schweiz)",
            "iso2": "CH",
            "phoneCode": "41"
          },
          {
            "name": "Syria (‫سوريا‬‎)",
          "iso2": "SY",
          "phoneCode": "963"
        },
          {
            "name": "Taiwan (台灣)",
            "iso2": "TW",
            "phoneCode": "886"
          },
          {
            "name": "Tajikistan",
            "iso2": "TJ",
            "phoneCode": "992"
          },
          {
            "name": "Tanzania",
            "iso2": "TZ",
            "phoneCode": "255"
          },
          {
            "name": "Thailand (ไทย)",
            "iso2": "TH",
            "phoneCode": "66"
          },
          {
            "name": "Timor-Leste",
            "iso2": "TL",
            "phoneCode": "670"
          },
          {
            "name": "Togo",
            "iso2": "TG",
            "phoneCode": "228"
          },
          {
            "name": "Tokelau",
            "iso2": "TK",
            "phoneCode": "690"
          },
          {
            "name": "Tonga",
            "iso2": "TO",
            "phoneCode": "676"
          },
          {
            "name": "Trinidad and Tobago",
            "iso2": "TT",
            "phoneCode": "1",
            "multipleCodes": ["868"]
          },
          {
            "name": "Tunisia (‫تونس‬‎)",
            "iso2": "TN",
            "phoneCode": "216"
          },
          {
            "name": "Turkey (Türkiye)",
            "iso2": "TR",
            "phoneCode": "90"
          },
          {
            "name": "Turkmenistan",
            "iso2": "TM",
            "phoneCode": "993"
          },
          {
            "name": "Turks and Caicos Islands",
            "iso2": "TC",
            "phoneCode": "1649"
          },
          {
            "name": "Tuvalu",
            "iso2": "TV",
            "phoneCode": "688"
          },
          {
            "name": "U.S. Virgin Islands",
            "iso2": "VI",
            "phoneCode": "1",
            "multipleCodes": ["340"]
          },
          {
            "name": "Uganda",
            "iso2": "UG",
            "phoneCode": "256"
          },
          {
            "name": "Ukraine (Україна)",
            "iso2": "UA",
            "phoneCode": "380"
          },
          {
            "name": "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
            "iso2": "AE",
            "phoneCode": "971"
          },
          {
            "name": "United Kingdom",
            "iso2": "GB",
            "phoneCode": "44",
            "multipleCodes": ["0"]
          },
          {
            "name": "United States",
            "iso2": "US",
            "phoneCode": "1",
            "multipleCodes": ["0"]
          },
          {
            "name": "Uruguay",
            "iso2": "UY",
            "phoneCode": "598"
          },
          {
            "name": "Uzbekistan (Oʻzbekiston)",
            "iso2": "UZ",
            "phoneCode": "998"
          },
          {
            "name": "Vanuatu",
            "iso2": "VU",
            "phoneCode": "678"
          },
          {
            "name": "Vatican City (Città del Vaticano)",
            "iso2": "VA",
            "phoneCode": "39",
            "multipleCodes": ["1"]
          },
          {
            "name": "Venezuela",
            "iso2": "VE",
            "phoneCode": "58"
          },
          {
            "name": "Vietnam (Việt Nam)",
            "iso2": "VN",
            "phoneCode": "84"
          },
          {
            "name": "Wallis and Futuna",
            "iso2": "WF",
            "phoneCode": "681"
          },
          {
            "name": "Western Sahara (‫الصحراء الغربية‬‎)",
            "iso2": "EH",
            "phoneCode": "212",
            "multipleCodes": ["1"]
          },
          {
            "name": "Yemen (‫اليمن‬‎)",
            "iso2": "YE",
            "phoneCode": "967"
          },
          {
            "name": "Zambia",
            "iso2": "ZM",
            "phoneCode": "260"
          },
          {
            "name": "Zimbabwe",
            "iso2": "ZW",
            "phoneCode": "263"
          },
          {
            "name": "Åland Islands",
            "iso2": "AX",
            "phoneCode": "358",
            "multipleCodes": ["1"]
          }
        ]
        
    