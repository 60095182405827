import { Injectable } from "@angular/core";
import axios from "axios";
import * as https from "https";
import * as fs from "fs";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ElasticsearchService {
  private readonly baseUrl = "https://13.36.73.200:9200"; // Replace with your Elasticsearch server URL

  private readonly axiosInstance = axios.create({
    baseURL: this.baseUrl,
    timeout: 15000,
    headers: {
      Authorization: "Basic YW50aG9zOjEyMzQxMjM0",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  });
  private apiUrl = 'http://your-elasticsearch-server:9200'; // Replace with your Elasticsearch server URL
  constructor(private http: HttpClient) {}

  getTestData() {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://13.36.73.200:9200/tester/_search?size=10",
      headers: {
        Authorization: "Basic YW50aG9zOjEyMzQxMjM0",
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }



  async createSpace(spaceName) {
    try {
      let Axios = axios.create({
        baseURL: "https://us-central1-diggri.cloudfunctions.net",
        timeout: 45000,
      });

      await Axios.post("/createSpace", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        body: {
          spaceName: spaceName,
       
        },
        spaceName: spaceName,
  
      }).then((res)=>{alert(res)});
    } catch (error) {
      console.error("Add MFA Error:", error);
      throw error;
    }
  }

  // async createSpace(spaceName: string) {
  //   var axios = require('axios');
  //   var data = JSON.stringify({
  //     "id": spaceName,
  //     "name": spaceName,
  //     // "description": "This is the Marketing Space",
  //     "initials": "MK",
  //     "disabledFeatures": [],
  //     // "imageUrl": imageURL
  //   });
    
  //   var config = {
  //     method: 'post',
  //     url: 'http://analytics.diggri.com:5601/api/spaces/space',
  //     headers: { 
  //       'Content-Type': 'application/json', 
  //       'kbn-xsrf': '', 
  //       'Authorization': 'Basic YW50aG9zOjEyMzQxMjM0'
  //     },
  //     data : data
  //   };
    
  //  return await axios(config)
  // }

}


