import { Injectable } from "@angular/core";
import { getFirebaseBackend, firebase } from "../../authUtils";
import { UserX } from "../models/users";
import { AuthenticationService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {


  constructor(private authService : AuthenticationService) {}
  getCategoriesDocuments(){
    let formRef = firebase.firestore().collection("/categories/");

    return formRef;
  }
  getCategoriesByID(id) {
    return firebase.firestore().collection("/categories/").doc(id).get()
  }
  getCategoriesByEvent() {}
  getCategoriesByCategoriesID() {}
  updateCategories(id,data) {

    return firebase.firestore()
      .collection("/categories/")
      .doc(id)
      .update({ ...data },{merge:true})
      .then((response: any) => {
        const form = response;
        return form;
      });
  }
  getCategoriesByName(name) {
    const db = firebase.firestore();
    return db
      .collection("/categories/")
      .where('name','==',name)
      .get()
      .then((response: any) => {
    
        return response;
      });
  }

  getCategoriesByEventID(id) {
    let user = this.authService.currentUserValue;
    let event = user.event;
    let forms = [];
    let events = [];
    return new Promise<any>( (resolve, reject) => {
       firebase
        .firestore()
        .collection("/categories/")
        .where("eventID", "==", id)
        .get()
        .then((result) => {
          result.forEach((data) => {
            let form = data.data();
           if(user.forms && user.forms.length > 0){
            user.forms.forEach(element => {
              element
              if(element.uid == form.uid  && !form?.deleted){
                console.log(form)
                forms.push(form);
              }
            });
           }else{
            if(!form?.deleted)
            forms.push(form);
           }
          });

          resolve(forms);
        });
    });
  }


  getCategoriesByFormID(id){
    let user = this.authService.currentUserValue;
    let event = user.event;
    let forms = [];
    let events = [];
    return new Promise<any>(async (resolve, reject) => {
      await firebase
        .firestore()
        .collection("/categories/")
        .where("form.uid", "==", id)
        .get()
        .then((result) => {
          result.forEach((data) => {
            let form = data.data();
           if(user.forms && user.forms.length > 0){
            user.forms.forEach(element => {
              if(element.uid == form.uid && !form?.deleted){
                forms.push(form);
              }
            });
           }else{
            if(!form?.deleted)
            forms.push(form);
           }
          });

          resolve(forms);
        });
    });
  }


  createCategories(data) {
    let user = this.authService.currentUserData();
    const db = firebase.firestore();
    const ref = db.collection("categories").doc();
    const id = ref.id;
    const createdAt = new Date().getTime()
    return db
      .collection("/categories/")
      .doc(id)
      .set({ ...data, uid: id,createdAt:createdAt,createdBy:user.uid })
      .then((response: any) => {
        const form = response;
        return form;
      });
  }
 
  canRead(user: UserX): boolean {
    const allowed = ["owner", "admin", "user", "mofa"];
    return this.checkAuthorization(user, allowed);
  }

  canEdit(user: UserX): boolean {
    const allowed = ["owner", "admin", "mofa"];
    return this.checkAuthorization(user, allowed);
  }

  canDelete(user: UserX): boolean {
    const allowed = ["owner"];
    return this.checkAuthorization(user, allowed);
  }
  canCreate(user: UserX): boolean {
    const allowed = ["owner"];
    return this.checkAuthorization(user, allowed);
  }

  checkAuthorization(user: UserX, allowedRoles: string[]): boolean {
    if (!user) return false;
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }
    return false;
  }
  deleteCategory(id) { 
    return firebase.firestore().collection("/categories/").doc(id).set({deleted:true},{merge:true});
  }

}
