import { Injector } from '@angular/core';
import { FormioCustomComponentInfo, registerCustomFormioComponent } from 'angular-formio';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { ImageCropperProfileComponent } from './custom-components/image-cropper/image-cropper.component';
import { PhoneNumberInputComponent } from './custom-components/phone-number-input/phone-number-input.component';
import { SelectDateComponent } from './custom-components/select-date/select-date.component';
import { SelectTimeComponent } from './custom-components/select-time/select-time.component';
import { SelectRoomComponent } from './custom-components/select-room/select-room.component';
import { DateSelectorComponent } from './custom-components/date-selector/date-selector.component';
import { LabelDescriptionComponent } from './custom-components/label-description/label-description.component';
import { PrintButtonComponent } from './custom-components/print-button/print-button.component';
import { SelectLanguageComponent } from './custom-components/select-language/select-language.component';
import { PhoneComponent } from './custom-components/phone/phone.component';
import { AddToCalendarButtonComponent } from './custom-components/add-to-calendar-button/add-to-calendar-button.component';


const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: 'phone',
  selector: 'phone-number',
  title: 'Phone',
  group: 'basic',
  icon: 'fa fa-phone',
};

const COMPONENT_OPTIONS_NEW_PHONE: FormioCustomComponentInfo = {
  type: 'phone-new',
  selector: 'phone-number-new',
  title: 'Phone New',
  group: 'basic',
  icon: 'fa fa-phone',
};

const COMPONENT_OPTIONS_CROP_IMAGE: FormioCustomComponentInfo = {
  type: 'image',
  selector: 'profile-photo-crop',
  title: 'Profile-Crop',
  group: 'basic',
  icon: 'fa fa-profile',
};
const COMPONENT_OPTIONS_DATE_PICKER: FormioCustomComponentInfo = {
  type: 'custom',
  selector: 'rooms-picker',
  title: 'Rooms Picker',
  group: 'basic',
  icon: 'fa fa-home',
  fieldOptions: ['values'], // Optional: explicit field options to get as `Input` from the schema (may edited by the editForm)
};
const COMPONENT_OPTIONS_ADD_TO_CALENDAR: FormioCustomComponentInfo = {
  type: 'add-to-calendar-button',
  selector: 'app-add-to-calendar-button',
  title: 'Add To Calendar',
  group: 'basic',
  icon: 'fa fa-calendar',
};
const COMPONENT_OPTIONS_Description: FormioCustomComponentInfo = {
  type: 'description-label',
  selector: 'description-label',
  title: 'Description Label',
  group: 'basic',
  icon: 'fa fa-clock',
};
const COMPONENT_OPTIONS_Print: FormioCustomComponentInfo = {
  type: 'print-button',
  selector: 'print-button',
  title: 'Print Button',
  group: 'basic',
  icon: 'fa fa-clock',
};
const COMPONENT_OPTIONS_SELECT_LANGUAGE: FormioCustomComponentInfo = {
  type: 'select-language',
  selector: 'select-language',
  title: 'Select Language',
  group: 'basic',
  icon: 'fa fa-clock',
};

const COMPONENT_OPTIONS_ROOM_PICKER: FormioCustomComponentInfo = {
  type: 'app-select-room',
  selector: 'room-picker',
  title: 'Room-Picker',
  group: 'basic',
  icon: 'fa fa-home'
};


export function registerCustomComponent(injector: Injector) {
  registerCustomFormioComponent(COMPONENT_OPTIONS, PhoneNumberInputComponent, injector);
  // registerCustomFormioComponent(COMPONENT_OPTIONS, PhoneNumberInputComponent, injector);
}
export function registerCustomComponentCropImage(injector: Injector) {
  registerCustomFormioComponent(COMPONENT_OPTIONS_CROP_IMAGE, ImageCropperProfileComponent, injector);
}

export function registerCustomComponentEventRoomsPicker(injector: Injector) {
  
  registerCustomFormioComponent(COMPONENT_OPTIONS_ADD_TO_CALENDAR, AddToCalendarButtonComponent, injector);
  // registerCustomFormioComponent(COMPONENT_OPTIONS_DATE_PICKER, DateSelectorComponent, injector);
  // registerCustomFormioComponent(COMPONENT_OPTIONS_Description, LabelDescriptionComponent, injector);
  // registerCustomFormioComponent(COMPONENT_OPTIONS_SELECT_LANGUAGE, SelectLanguageComponent, injector);
  registerCustomFormioComponent(COMPONENT_OPTIONS_Print, PrintButtonComponent, injector);
  registerCustomFormioComponent(COMPONENT_OPTIONS_SELECT_LANGUAGE, SelectLanguageComponent, injector);
  registerCustomFormioComponent(COMPONENT_OPTIONS_NEW_PHONE, PhoneComponent, injector);

}


export function registerCustomComponentDatePicker(injector: Injector) {
  // registerCustomFormioComponent(COMPONENT_OPTIONS_DATE_PICKER, SelectDateComponent, injector);
}
export function registerCustomComponentTimePicker(injector: Injector) {
  // registerCustomFormioComponent(COMPONENT_OPTIONS_TIME_PICKER, SelectTimeComponent, injector);
}
export function registerCustomComponentRoomPicker(injector: Injector) {
  // registerCustomFormioComponent(COMPONENT_OPTIONS_ROOM_PICKER, SelectRoomComponent, injector);
}