<div class="width">
  <ngx-intl-tel-input
    #phoneEle
    [separateDialCode]='true'
    [searchCountryFlag]='true'
    [preferredCountries]="['qa']"
    [selectedCountryISO]="countryISO.Qatar"
    [enableAutoCountrySelect]="true"
    [selectFirstCountry]="false"
    [maxLength]="30"
    [inputId]="'my-input-id'"
    (countryChange)="countryChange($event)"
    [phoneValidation]="true"
    name="phone"
    id="phone"
    class="w-100"
    [(ngModel)]="value"
    (input)="onInputChange($event)"
    class="ltr-text"
    >
  
  </ngx-intl-tel-input>
</div>
