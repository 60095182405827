import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { label } from 'aws-sdk/clients/sns';


import * as pdf from 'html-pdf-node';
import * as fs from 'fs';

 import html2canvas from 'html2canvas';
 import * as html2pdf from 'html2pdf.js';
 import domToImage from 'dom-to-image';
 import jsPDF, { jsPDFOptions } from 'jspdf';
 import moment from 'moment';

@Component({
  selector: 'app-print-button',
  templateUrl: './print-button.component.html',
  styleUrls: ['./print-button.component.scss']
})
export class PrintButtonComponent implements OnInit {
  @ViewChild('formio') formio: ElementRef;
  constructor() { }
  @Input()
  value: string;

  @Input()
  placeholder: string;

  @Input()
  disabled: boolean;

  @Input()
  formControl: any;

  @Input()
  row: any;

  @Input()
  submission: any;

  @Input()
  data: any;

  
  @Input()
  component: any;

  @Input()
  label: string;

  @Input()
  customClass: string;

  ngOnInit(): void {
  }

  generatePDF() {
    const width = this.formio.nativeElement.clientWidth;
    const height = this.formio.nativeElement.clientHeight + 40;
    let orientation :any = '';
    let imageUnit :any= 'pt';
    if (width > height) {
      orientation = 'l';
    } else {
      orientation = 'p';
    }
    domToImage
      .toPng(this.formio.nativeElement, {
        width: width,
        height: height
      })
      .then(result => {
        let jsPdfOptions :jsPDFOptions= {
          unit:imageUnit,
          orientation:orientation,
          format: [width + 50, height + 220]
        };
        const pdf = new jsPDF(jsPdfOptions);
       
        pdf.addImage(result, 'PNG', 25, 185, width, height);
        pdf.save('file_name' + '.pdf');
      })
      .catch(error => {
        console.error(error);
      });
  }
    
}
