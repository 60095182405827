const values  = [


{
    "name": "United States of America",
    "eng": "United States of America",
    "ar": "الولايات المتحدة الأمريكية"
  },
  {
    "name": "Congo {Democratic Rep}",
    "eng": "Congo {Democratic Rep}",
    "ar": "الكونغو {الجمهورية الديمقراطية}"
  },
  {
    "name": "Myanmar, (Burma)",
    "eng": "Myanmar, (Burma)",
    "ar": "ميانمار (بورما)"
  },
 
  {
    "name": "North Korea",
    "eng": "North Korea",
    "ar": "كوريا الشمالية"
  },
  {
    "name": "South Korea",
    "eng": "South Korea",
    "ar": "كوريا الجنوبية"
  },
  
    {
      "name": "ethiopia",
      "eng": "ethiopia",
      "ar": "أثيوبيا"
    },
    {
      "ar": "أذربيجان",
      "name": "azerbaijan",
      "eng": "azerbaijan"
    },
    {
      "ar": "أرمينيا",
      "name": "armenia",
      "eng": "armenia"
    },
    {
      "ar": "أستراليا",
      "name": "australia",
      "eng": "australia"
    },
    {
      "ar": "أفغانستان",
      "name": "afghanistan",
      "eng": "afghanistan"
    },
    {
      "ar": "ألبانيا",
      "name": "albania",
      "eng": "albania"
    },
    {
      "ar": "ألمانيا",
      "name": "germany",
      "eng": "germany"
    },
    {
      "name": "antigua and barbuda",
      "eng": "antigua and barbuda",
      "ar": "أنتيغوا وبربودا"
    },
    {
      "ar": "أندورا",
      "name": "andorra",
      "eng": "andorra"
    },
    {
      "ar": "أنغولا",
      "name": "angola",
      "eng": "angola"
    },
    {
      "name": "uruguay",
      "eng": "uruguay",
      "ar": "أوروغواي"
    },
    {
      "name": "uzbekistan",
      "eng": "uzbekistan",
      "ar": "أوزبكستان"
    },
    {
      "ar": "أوغندا",
      "name": "uganda",
      "eng": "uganda"
    },
    {
      "name": "ukraine",
      "eng": "ukraine",
      "ar": "أوكرانيا"
    },
    {
      "name": "ireland",
      "eng": "ireland",
      "ar": "أيرلندا"
    },
    {
      "name": "iceland",
      "eng": "iceland",
      "ar": "أيسلندا"
    },
    {
      "name": "eritrea",
      "eng": "eritrea",
      "ar": "إريتريا"
    },
    {
      "name": "spain",
      "eng": "spain",
      "ar": "إسبانيا"
    },
    {
      "name": "estonia",
      "eng": "estonia",
      "ar": "إستونيا"
    },
    {
      "ar": "إندونيسيا",
      "name": "indonesia",
      "eng": "indonesia"
    },
    {
      "name": "iran",
      "eng": "iran",
      "ar": "إيران"
    },
    {
      "ar": "إيطاليا",
      "name": "italy",
      "eng": "italy"
    },
    {
      "ar": "الأرجنتين",
      "name": "argentina",
      "eng": "argentina"
    },
    {
      "name": "jordan",
      "eng": "jordan",
      "ar": "الأردن"
    },
    {
      "name": "united arab emirates",
      "eng": "united arab emirates",
      "ar": "الإمارات العربية المتحدة"
    },
    {
      "ar": "الاتحاد الروسي",
      "name": "russian federation",
      "eng": "russian federation"
    },
    {
      "name": "ecuador",
      "eng": "ecuador",
      "ar": "الاكوادور"
    },
    {
      "ar": "البحرين",
      "name": "bahrain",
      "eng": "bahrain"
    },
    {
      "name": "brazil",
      "eng": "brazil",
      "ar": "البرازيل"
    },
    {
      "name": "portugal",
      "eng": "portugal",
      "ar": "البرتغال"
    },
    {
      "name": "bosnia herzegovina",
      "eng": "bosnia herzegovina",
      "ar": "البوسنة والهرسك"
    },
    {
      "ar": "الجابون",
      "name": "gabon",
      "eng": "gabon"
    },
    {
      "name": "montenegro",
      "eng": "montenegro",
      "ar": "مونتينيغرو"
    },
    {
      "ar": "الجزائر",
      "name": "algeria",
      "eng": "algeria"
    },
    {
      "ar": "الدنمارك",
      "name": "denmark",
      "eng": "denmark"
    },
    {
      "name": "cape verde",
      "eng": "cape verde",
      "ar": "كاب فردي"
    },
    {
      "name": "el salvador",
      "eng": "el salvador",
      "ar": "السلفادور"
    },
    {
      "ar": "السنغال",
      "name": "senegal",
      "eng": "senegal"
    },
    {
      "name": "sudan",
      "eng": "sudan",
      "ar": "السودان"
    },
    {
      "name": "sweden",
      "eng": "sweden",
      "ar": "السويد"
    },
    {
      "name": "somalia",
      "eng": "somalia",
      "ar": "الصومال"
    },
    {
      "ar": "الصين",
      "name": "china",
      "eng": "china"
    },
    {
      "ar": "العراق",
      "name": "iraq",
      "eng": "iraq"
    },
    {
      "ar": "الفلبين",
      "name": "philippines",
      "eng": "philippines"
    },
    {
      "name": "cameroon",
      "eng": "cameroon",
      "ar": "الكاميرون"
    },
    {
      "ar": "الكونغو",
      "name": "congo",
      "eng": "congo"
    },
    {
      "name": "kuwait",
      "eng": "kuwait",
      "ar": "الكويت"
    },
    {
      "ar": "المغرب",
      "name": "morocco",
      "eng": "morocco"
    },
    {
      "name": "mexico",
      "eng": "mexico",
      "ar": "المكسيك"
    },
    {
      "ar": "المملكة العربية السعودية",
      "name": "saudi arabia",
      "eng": "saudi arabia"
    },
    {
      "name": "united kingdom",
      "eng": "united kingdom",
      "ar": "المملكة المتحدة"
    },
    {
      "ar": "النرويج",
      "name": "norway",
      "eng": "norway"
    },
    {
      "name": "austria",
      "eng": "austria",
      "ar": "النمسا"
    },
    {
      "ar": "النيجر",
      "name": "niger",
      "eng": "niger"
    },
    {
      "ar": "الهند",
      "name": "india",
      "eng": "india"
    },
    {
      "ar": "الولايات المتحدة الأمريكية",
      "name": "united states",
      "eng": "united states"
    },
    {
      "name": "japan",
      "eng": "japan",
      "ar": "اليابان"
    },
    {
      "ar": "اليمن",
      "name": "yemen",
      "eng": "yemen"
    },
    {
      "ar": "اليونان",
      "name": "greece",
      "eng": "greece"
    },
    {
      "ar": "بابوا غينيا الجديدة",
      "name": "papua new guinea",
      "eng": "papua new guinea"
    },
    {
      "ar": "باراغواي",
      "name": "paraguay",
      "eng": "paraguay"
    },
    {
      "name": "pakistan",
      "eng": "pakistan",
      "ar": "باكستان"
    },
    {
      "ar": "بالاو",
      "name": "palau",
      "eng": "palau"
    },
    {
      "name": "barbados",
      "eng": "barbados",
      "ar": "بربادوس"
    },
    {
      "ar": "بروناي",
      "name": "brunei",
      "eng": "brunei"
    },
    {
      "ar": "بلجيكا",
      "name": "belgium",
      "eng": "belgium"
    },
    {
      "name": "bulgaria",
      "eng": "bulgaria",
      "ar": "بلغاريا"
    },
    {
      "ar": "باليز",
      "name": "belize",
      "eng": "belize"
    },
    {
      "ar": "بنغلاديش",
      "name": "bangladesh",
      "eng": "bangladesh"
    },
    {
      "ar": "بنما",
      "name": "panama",
      "eng": "panama"
    },
    {
      "ar": "بنين",
      "name": "benin",
      "eng": "benin"
    },
    {
      "ar": "بوتان",
      "name": "bhutan",
      "eng": "bhutan"
    },
    {
      "ar": "بوتسوانا",
      "name": "botswana",
      "eng": "botswana"
    },
    {
      "ar": "بوركينا فاسو",
      "name": "burkina",
      "eng": "burkina"
    },
    {
      "ar": "بوروندي",
      "name": "burundi",
      "eng": "burundi"
    },
    {
      "name": "poland",
      "eng": "poland",
      "ar": "بولندا"
    },
    {
      "name": "bolivia",
      "eng": "bolivia",
      "ar": "بوليفيا"
    },
    {
      "ar": "بيرو",
      "name": "peru",
      "eng": "peru"
    },
    {
      "ar": "بيلاروسيا",
      "name": "belarus",
      "eng": "belarus"
    },
    {
      "name": "thailand",
      "eng": "thailand",
      "ar": "تايلاند"
    },
    {
      "name": "taiwan",
      "eng": "taiwan",
      "ar": "تايوان"
    },
    {
      "name": "turkmenistan",
      "eng": "turkmenistan",
      "ar": "تركمانستان"
    },
    {
      "ar": "تركيا",
      "name": "türkiye",
      "eng": "türkiye"
    },
    {
      "name": "trinidad & tobago",
      "eng": "trinidad & tobago",
      "ar": "ترينيداد وتوباجو"
    },
    {
      "ar": "تشاد",
      "name": "chad",
      "eng": "chad"
    },
    {
      "ar": "تشيلي",
      "name": "chile",
      "eng": "chile"
    },
    {
      "ar": "تنزانيا",
      "name": "tanzania",
      "eng": "tanzania"
    },
    {
      "name": "togo",
      "eng": "togo",
      "ar": "توجو"
    },
    {
      "name": "tuvalu",
      "eng": "tuvalu",
      "ar": "توفالو"
    },
    {
      "name": "tunisia",
      "eng": "tunisia",
      "ar": "تونس"
    },
    {
      "name": "tonga",
      "eng": "tonga",
      "ar": "تونغا"
    },
    {
      "ar": "تيمور الشرقية",
      "name": "east timor",
      "eng": "east timor"
    },
    {
      "ar": "جامايكا",
      "name": "jamaica",
      "eng": "jamaica"
    },
    {
      "name": "bahamas",
      "eng": "bahamas",
      "ar": "جزر البهاما"
    },
    {
      "name": "comoros",
      "eng": "comoros",
      "ar": "جزر القمر"
    },
    {
      "ar": "جزر المالديف",
      "name": "maldives",
      "eng": "maldives"
    },
    {
      "name": "solomon islands",
      "eng": "solomon islands",
      "ar": "جزر سليمان"
    },
    {
      "ar": "جزر مارشال",
      "name": "marshall islands",
      "eng": "marshall islands"
    },
    {
      "ar": "جمهورية أفريقيا الوسطى",
      "name": "central african rep",
      "eng": "central african rep"
    },
    {
      "ar": "جمهورية التشيك",
      "name": "czech republic",
      "eng": "czech republic"
    },
    {
      "ar": "جمهورية الدومينيكان",
      "name": "dominican republic",
      "eng": "dominican republic"
    },
    {
      "name": "congo democratic rep}",
      "eng": "congo democratic rep}",
      "ar": "جمهورية الكونغو الديمقراطية"
    },
    {
      "name": "south africa",
      "eng": "south africa",
      "ar": "جنوب أفريقيا"
    },
    {
      "ar": "جنوب السودان",
      "name": "south sudan",
      "eng": "south sudan"
    },
    {
      "ar": "جورجيا",
      "name": "georgia",
      "eng": "georgia"
    },
    {
      "ar": "جيبوتي",
      "name": "djibouti",
      "eng": "djibouti"
    },
    {
      "ar": "قطر",
      "name": "qatar",
      "eng": "qatar"
    },
    {
      "ar": "دومينيكا",
      "name": "dominica",
      "eng": "dominica"
    },
    {
      "ar": "رواندا",
      "name": "rwanda",
      "eng": "rwanda"
    },
    {
      "name": "romania",
      "eng": "romania",
      "ar": "رومانيا"
    },
    {
      "ar": "زامبيا",
      "name": "zambia",
      "eng": "zambia"
    },
    {
      "ar": "زيمبابوي",
      "name": "zimbabwe",
      "eng": "zimbabwe"
    },
    {
      "ar": "ساحل العاج",
      "name": "ivory coast",
      "eng": "ivory coast"
    },
    {
      "name": "samoa",
      "eng": "samoa",
      "ar": "ساموا"
    },
    {
      "ar": "سان تومي وبرينسيبي",
      "name": "sao tome & principe",
      "eng": "sao tome & principe"
    },
    {
      "ar": "سان مارينو",
      "name": "san marino",
      "eng": "san marino"
    },
    {
      "name": "saint vincent & the grenadines",
      "eng": "saint vincent & the grenadines",
      "ar": "سانت فنسنت وجزر غرينادين"
    },
    {
      "name": "st kitts & nevis",
      "eng": "st kitts & nevis",
      "ar": "سانت كيتس ونيفيس"
    },
    {
      "name": "st lucia",
      "eng": "st lucia",
      "ar": "سانت لوسيا"
    },
    {
      "ar": "سلطنة عمان",
      "name": "oman",
      "eng": "oman"
    },
    {
      "ar": "سلوفاكيا",
      "name": "slovakia",
      "eng": "slovakia"
    },
    {
      "ar": "سلوفينيا",
      "name": "slovenia",
      "eng": "slovenia"
    },
    {
      "name": "singapore",
      "eng": "singapore",
      "ar": "سنغافورة"
    },
    {
      "name": "swaziland",
      "eng": "swaziland",
      "ar": "سوازيلاند"
    },
    {
      "name": "syria",
      "eng": "syria",
      "ar": "سوريا"
    },
    {
      "name": "suriname",
      "eng": "suriname",
      "ar": "سورينام"
    },
    {
      "ar": "سويسرا",
      "name": "switzerland",
      "eng": "switzerland"
    },
    {
      "name": "sierra leone",
      "eng": "sierra leone",
      "ar": "سيرا ليون"
    },
    {
      "ar": "سيريلانكا",
      "name": "sri lanka",
      "eng": "sri lanka"
    },
    {
      "name": "seychelles",
      "eng": "seychelles",
      "ar": "سيشيل"
    },
    {
      "name": "serbia",
      "eng": "serbia",
      "ar": "صربيا"
    },
    {
      "name": "tajikistan",
      "eng": "tajikistan",
      "ar": "طاجيكستان"
    },
    {
      "name": "gambia",
      "eng": "gambia",
      "ar": "غامبيا"
    },
    {
      "name": "ghana",
      "eng": "ghana",
      "ar": "غانا"
    },
    {
      "name": "grenada",
      "eng": "grenada",
      "ar": "غرينادا"
    },
    {
      "ar": "غواتيمالا",
      "name": "guatemala",
      "eng": "guatemala"
    },
    {
      "name": "guyana",
      "eng": "guyana",
      "ar": "غيانا"
    },
    {
      "ar": "غينيا الإستوائية",
      "name": "equatorial guinea",
      "eng": "equatorial guinea"
    },
    {
      "name": "guinea-bissau",
      "eng": "guinea-bissau",
      "ar": "غينيا بيساو"
    },
    {
      "name": "guinea",
      "eng": "guinea",
      "ar": "غينيا"
    },
    {
      "ar": "فانواتو",
      "name": "vanuatu",
      "eng": "vanuatu"
    },
    {
      "name": "france",
      "eng": "france",
      "ar": "فرنسا"
    },
    {
      "ar": "فلسطين",
      "name": "palestine",
      "eng": "palestine"
    },
    {
      "ar": "فنزويلا",
      "name": "venezuela",
      "eng": "venezuela"
    },
    {
      "name": "finland",
      "eng": "finland",
      "ar": "فنلندا"
    },
    {
      "name": "vietnam",
      "eng": "vietnam",
      "ar": "فيتنام"
    },
    {
      "ar": "فيجي",
      "name": "fiji",
      "eng": "fiji"
    },
    {
      "name": "cyprus",
      "eng": "cyprus",
      "ar": "قبرص"
    },
    {
      "name": "kyrgyzstan",
      "eng": "kyrgyzstan",
      "ar": "قيرغيزستان"
    },
    {
      "name": "kazakhstan",
      "eng": "kazakhstan",
      "ar": "كازاخستان"
    },
    {
      "name": "croatia",
      "eng": "croatia",
      "ar": "كرواتيا"
    },
    {
      "ar": "كمبوديا",
      "name": "cambodia",
      "eng": "cambodia"
    },
    {
      "name": "canada",
      "eng": "canada",
      "ar": "كندا"
    },
    {
      "ar": "كوبا",
      "name": "cuba",
      "eng": "cuba"
    },
    {
      "ar": "كوريا الجنوبية",
      "name": "korea south",
      "eng": "korea south"
    },
    {
      "name": "korea north",
      "eng": "korea north",
      "ar": "كوريا الشمالية"
    },
    {
      "ar": "كوستا ريكا",
      "name": "costa rica",
      "eng": "costa rica"
    },
    {
      "ar": "كوسوفو",
      "name": "kosovo",
      "eng": "kosovo"
    },
    {
      "ar": "كولومبيا",
      "name": "colombia",
      "eng": "colombia"
    },
    {
      "ar": "كيريباتي",
      "name": "kiribati",
      "eng": "kiribati"
    },
    {
      "name": "kenya",
      "eng": "kenya",
      "ar": "كينيا"
    },
    {
      "name": "latvia",
      "eng": "latvia",
      "ar": "لاتفيا"
    },
    {
      "name": "laos",
      "eng": "laos",
      "ar": "لاوس"
    },
    {
      "ar": "لبنان",
      "name": "lebanon",
      "eng": "lebanon"
    },
    {
      "ar": "لوكسمبورغ",
      "name": "luxembourg",
      "eng": "luxembourg"
    },
    {
      "name": "libya",
      "eng": "libya",
      "ar": "ليبيا"
    },
    {
      "ar": "ليبيريا",
      "name": "liberia",
      "eng": "liberia"
    },
    {
      "name": "lithuania",
      "eng": "lithuania",
      "ar": "ليتوانيا"
    },
    {
      "ar": "ليختنشتاين",
      "name": "liechtenstein",
      "eng": "liechtenstein"
    },
    {
      "name": "lesotho",
      "eng": "lesotho",
      "ar": "ليسوتو"
    },
    {
      "ar": "مالطا",
      "name": "malta",
      "eng": "malta"
    },
    {
      "name": "mali",
      "eng": "mali",
      "ar": "مالي"
    },
    {
      "name": "malaysia",
      "eng": "malaysia",
      "ar": "ماليزيا"
    },
    {
      "name": "madagascar",
      "eng": "madagascar",
      "ar": "مدغشقر"
    },
    {
      "name": "vatican city",
      "eng": "vatican city",
      "ar": "مدينة الفاتيكان"
    },
    {
      "ar": "مصر",
      "name": "egypt",
      "eng": "egypt"
    },
    {
      "ar": "مقدونيا",
      "name": "macedonia",
      "eng": "macedonia"
    },
    {
      "name": "malawi",
      "eng": "malawi",
      "ar": "ملاوي"
    },
    {
      "name": "mongolia",
      "eng": "mongolia",
      "ar": "منغوليا"
    },
    {
      "name": "mauritania",
      "eng": "mauritania",
      "ar": "موريتانيا"
    },
    {
      "ar": "موريشيوس",
      "name": "mauritius",
      "eng": "mauritius"
    },
    {
      "ar": "موزمبيق",
      "name": "mozambique",
      "eng": "mozambique"
    },
    {
      "name": "moldova",
      "eng": "moldova",
      "ar": "مولدوفا"
    },
    {
      "name": "monaco",
      "eng": "monaco",
      "ar": "موناكو"
    },
    {
      "name": "myanmar",
      "eng": "myanmar",
      "ar": "ميانمار ، (بورما)"
    },
    {
      "ar": "ميكرونيزيا",
      "name": "micronesia",
      "eng": "micronesia"
    },
    {
      "ar": "ناميبيا",
      "name": "namibia",
      "eng": "namibia"
    },
    {
      "name": "nauru",
      "eng": "nauru",
      "ar": "ناورو"
    },
    {
      "name": "nepal",
      "eng": "nepal",
      "ar": "نيبال"
    },
    {
      "ar": "نيجيريا",
      "name": "nigeria",
      "eng": "nigeria"
    },
    {
      "name": "nicaragua",
      "eng": "nicaragua",
      "ar": "نيكاراغوا"
    },
    {
      "ar": "نيوزيلاندا",
      "name": "new zealand",
      "eng": "new zealand"
    },
    {
      "ar": "هايتي",
      "name": "haiti",
      "eng": "haiti"
    },
    {
      "ar": "هندوراس",
      "name": "honduras",
      "eng": "honduras"
    },
    {
      "ar": "هنغاريا",
      "name": "hungary",
      "eng": "hungary"
    },
    {
      "ar": "هولندا",
      "name": "netherlands",
      "eng": "netherlands"
    }
  ]

export default values