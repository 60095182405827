import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormioCustomComponent, FormioEvent } from "angular-formio";
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from "ngx-intl-tel-input";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-phone-number-input",
  templateUrl: "./phone-number-input.component.html",
  styleUrls: ["./phone-number-input.component.scss"]
})
export class PhoneNumberInputComponent
  implements OnInit, FormioCustomComponent<number>
{
  constructor(private translateService: TranslateService) {}
  translatedPreferredCountries: string[] = []; // Store translated preferred country names
  separateDialCode = true;
  searchCountryField = SearchCountryField;
  countryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Qatar, // Default preferred countries
    // Add more default preferred countries here
  ];

  

  @Input()
  value: number;

  @Output()
  valueChange = new EventEmitter<number>();

  @Input()
  disabled: boolean;

  @Input()
  formControl: any;

  @Input()
  row: any;

  @Input()
  submission: any;

  @Input()
  data: any;

  @Input()
  component: any;

  getValue() {

    return this.value;
  }

  setValue(value: any) {
    this.value = value;
  }

  onInputChange(event) {
    this.valueChange.emit(event);
  }

  ngOnInit(): void {

  }
 

  countryChange(e) {}
  
}
