import { Component, Injector, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-tel-input";

import { CategoriesService } from "src/app/core/services/categories.service";
import { CompanyEventsService } from "src/app/core/services/company-events.service";
import { FormService } from "src/app/core/services/form.service";
import { RoomsService } from "src/app/core/services/rooms.service";
import { SubmitService } from "src/app/core/services/submits.service";
import { TranslationsService } from "src/app/core/services/translations.service";
import { firebase } from "src/app/fbInitialize";
import {
  registerCustomComponent,
  registerCustomComponentCropImage,
  registerCustomComponentEventRoomsPicker,
  registerCustomComponentRoomPicker,
  registerCustomComponentTimePicker,
} from "src/app/formio/rating-wrapper.formio";
import AWS from "aws-sdk";

@Component({
  selector: "app-gims-formio",
  templateUrl: "./gims-formio.component.html",
  styleUrls: ["./gims-formio.component.scss"],
})
export class GimsFormioComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private injector: Injector,
    private router: Router,
    private formService: FormService,
    private submitService: SubmitService,
    private eventService: CompanyEventsService,
    private categoryService: CategoriesService,
    private translationService: TranslationsService,
    private translateService: TranslateService,
    private translate: TranslateService,
    private roomsService: RoomsService
  ) {
    try {
      registerCustomComponent(injector);
      registerCustomComponentCropImage(injector);
      registerCustomComponentEventRoomsPicker(injector);
      registerCustomComponentTimePicker(injector);
      registerCustomComponentRoomPicker(injector);
    } catch (error) { }
  }
  roomID: string;
  eventID = "JX8mA3R9gKYUH6CkQaiv";
  formID = "At1A1rR1eoRc6NPeXe0a";
  categoryID = "02MzaoSyJfZiZWtmbSxu";
  separateDialCode = true;
  searchCountryField = SearchCountryField;
  countryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  public editFormID: any;
  formData: any;
  // @ViewChild("formio") formio: any;
  breadCrumbItems: Array<{}>;
  form: any;
  submitted = false;
  error = "";
  successmsg = false;
  inputs: Array<{}>;
  formx: any;
  profile: any;
  passport: any;
  loading = true;
  oneoftwo: true;
  event: any;
  font: any;
  language = "english";
  lang: any;
  languageFromURL: any;
  mainSuper: any;
  hasCategory = false;
  category: any;
  stylesObj: any;
  isClosed = false;
  categoryData: any = {
    disabled: false,
  };
  selectedRoom: any;
  room: any;
  rooms = [];
  countryNames: any = {}; // Store translated country names

  async ngOnInit(): Promise<void> {
    this.loading = true
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      const roomID = params.get("roomID");

      this.roomID = roomID;

      this.roomsService.getRoomByID(roomID).then((data)=>
      {
       this.room =  data.data()
       if(this.room.form.uid){
        this.formID = this.room.form.uid
       }
       this.loading = false
      })
      this.languageFromURL = params.get("lang");
      
    });
    this.eventService.getEventByID(this.eventID).then((data) => {
      this.event = data.data();
      // console.log({event:this.event})
    });

    this.stylesObj = { "font-family": "FontAwesome", src: this.font };
    const routeParams = this.router.routerState.snapshot;
    const urlSegments = routeParams.url.split("/");
    const length = urlSegments.length;

    await this.getCategoryData(this.categoryID);

    // if (length >= 4) {
    //   if (urlSegments[length - 1]) {
    //     const categoryID = urlSegments[length - 1];
    //     await getCategoryData(categoryID);
    //   }
    //   this.editFormID = urlSegments[length - 2];
    // } else if (length == 5) {
    //   if (urlSegments[length - 1]) {
    //     this.languageFromURL = urlSegments[length - 1];
    //     const categoryID = urlSegments[length - 2];
    //     await getCategoryData(categoryID);
    //   }
    //   this.editFormID = urlSegments[length - 3];
    // } else {
    //   this.editFormID = urlSegments[length - 1];
    // }

    this.breadCrumbItems = [
      { label: "Admin" },
      { label: "Users" },
      { label: "Edit User", active: true },
    ];

    try {
      this.loading = true
      // alert(this.formID);
      const res: any = await this.formService.getFormByID(this.formID);
      this.formx = res.data();
      console.log(this.formx);
      if (this.formx.name.startsWith("AR -")) {
        this.language = "lt";
        this.lang = {
          language: "lt",
          i18n: {
            lt: {
              browse: "تصفح",
              "Drop files to attach,": "قم بإسقاط الملفات لإرفاقها ",
              // Add other translations
            },
          },
        };
      }

      if (this.formx?.inputs) {
        this.form = {
          components: this.formx.inputs,
        };
      }
      const translationsArray = await this.translationService
        .getTranslationsCollection()
        .get()
        .then((data) => {
          return data.docs.map((doc) => doc.data());
        })
        .catch((error) => {
          console.error("Error fetching translations:", error);
          return [];
        });
        if (this.languageFromURL === "ar") {
        // console.log('-----  ------  - - - - - - - --')
        // console.log(this.translateLabels(this.formx.inputs, translationsArray))
        this.formx.inputs = this.translateLabels(this.formx.inputs, translationsArray);
        // Apply RTL class to Formio

        const formioElement = document.querySelector(".formio");
        if (formioElement) {
          formioElement.classList.add("rtl-formio");
        }
        this.language = "lt";
        this.lang = {
          language: "lt",
          i18n: {
            lt: {
              browse: "تصفح",
              "Drop files to attach,": "قم بإسقاط الملفات لإرفاقها ",
              // Add other translations
            },
          },
        };
        // Load translations here
        this.translateService
          .get("countryCodes")
          .subscribe((translations: any) => {
            this.countryNames = translations;
          });

        // Check if the language is 'ar'
        if (this.languageFromURL === "ar") {
          this.formx.inputs = this.translateLabels(this.formx.inputs, translationsArray);

          // Apply RTL class to Formio
          const formioElement = document.querySelector(".formio");
          if (formioElement) {
            formioElement.classList.add("rtl-formio");
          }
          this.loading = false
          // Switch to the Arabic language
          this.translate.use("ar");
        }
      }
    } catch (error) {
      // Handle error
    }

    try {
      const eventID = this.formx?.eventID; // Replace with your event ID

      localStorage.setItem("eventID", eventID);
    } catch (error) {
      console.error("Error fetching rooms:", error);
      // Handle the error, e.g., show an error message
    } finally {
      this.loading = false;
    }
  }

  getCategoryData = async (categoryID: string) => {
    try {
      const data = await this.categoryService.getCategoriesByID(categoryID);
      this.categoryData = data.data();
      this.category = this.categoryData.name;
    } catch (error) {
      // Handle error
    }
  };

  findRoomByUid(uid: string): any {
    return this.rooms.find((room) => room.uid === uid);
  }

  formatDate(timestamp: number): string {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
  selectableHours(startTime: any, endTime: any): number[] {
    const hours = [];
    for (
      let i = this.timeToInteger(startTime);
      i <= this.timeToInteger(endTime);
      i++
    ) {
      hours.push(i.toString());
    }
    return hours;
  }
  timeToInteger(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours;
  }
  integerToTime(hours: number): string {
    // Ensure the hours are within a valid range (0-23)
    hours = Math.min(23, Math.max(0, hours));

    // Format the hours as two digits
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;

    // Return the formatted time string with minutes set to "00"
    return `${formattedHours}:00`;
  }

  toggleOtherTitleField() {
    // Determine whether to show the "Other Title" field based on the selected value
    const selectedTitle = this.form.get("title").value;
    if (selectedTitle === "other") {
      this.form.get("otherTitle").enable(); // Show the field
    } else {
      this.form.get("otherTitle").disable(); // Hide the field
    }
  }

  showOtherTitleField() {
    // Check if the "Other Title" field should be shown
    return this.form.get("title").value === "other";
  }
  numberToFourDigits(number) {
    return number.toString().padStart(4, '0');
  }

  getFirstSixChars(id: string): string {
    return id.substring(0, 6);
}


  handleUpload(
    photoType: string,
    urlKey: string,
    fileName: string,
    data: any,
    id: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let varName = "";
      for (const [key, value] of Object.entries(data.data)) {
        if (key.includes("personal")) {
          varName = key;
          break;
        }
      }

      const base64Data = data.data[varName][photoType][0].url
        .split("base64,")
        .pop();
      const mimeType = "image/jpeg"; // Adjust the mime type accordingly
      const byteCharacters = atob(base64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const photoBlob = new Blob(byteArrays, { type: mimeType });
      const filePath =
        fileName === "PP"
          ? `passports/${id}_${fileName}.jpg`
          : `profiles/${id}_${fileName}.jpg`;

      const s3 = new AWS.S3({
        accessKeyId: "AKIA5J6SD6XVX4LRHKIW",
        secretAccessKey: "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",
        region: "eu-central-1",
      });

      const params = {
        Bucket: "diggri",
        Key: filePath,
        Body: photoBlob,
        ContentType: mimeType,
      };

      s3.upload(params, (err, res) => {
        if (err) {
          reject(err);
        } else {
          if (data.data[varName][photoType])
            delete data.data[varName][photoType];
          data[urlKey] = res.Location;
          resolve(res.Location);
        }
      });
    });
  }

  async onSubmit(formData: any) {

    this.loading = true;
    const db = firebase.firestore();
    const ref = db.collection("submits").doc();
    const id = ref.id;
    const data = {
      ...formData,
      uid: id,
    };
    let varName = "";
    let boolProfile = false;
    let boolPassport = false;

    const uploadTasks = [];
    for (const [key, value] of Object.entries(data.data)) {
      if (key.includes("personal")) {
        varName = key;
        const innerValue = data.data[key];
        boolProfile = innerValue && innerValue.hasOwnProperty("profilePhoto");
        boolPassport = innerValue && innerValue.hasOwnProperty("passportPhoto");
        break;
      }
    }
    if (boolProfile || boolPassport) {
      uploadTasks.push(
        this.handleUpload(
          "passportPhoto",
          "passportPictureURL",
          "PP",
          data,
          id
        ),
        this.handleUpload("profilePhoto", "profilePictureURL", "PH", data, id)
      );
    } else if (boolPassport) {
      uploadTasks.push(
        this.handleUpload("passportPhoto", "passportPictureURL", "PP", data, id)
      );
    } else if (boolProfile) {
      uploadTasks.push(
        this.handleUpload("profilePhoto", "profilePictureURL", "PH", data, id)
      );
    }


    try {
      Promise.all(uploadTasks)
        .then(() => {
          data.formID = this.formx.uid;
          data.eventsIDs = this.formx.eventsIDs;
          data.eventID = this.formx.eventsIDs[0];
          data.company = this.formx.company;
          this.replaceUndefinedWithEmptyString(data);
          return this.formService.submitForm(data);
        })
        .then(() => {
          this.eventService.getEventByID(this.eventID).then(async (res) => {
            this.event = res.data();
            // console.log({event:this.event})
            const submitNumber = this.event.submitsCounter.submits | 0
            const data = {
              data: {
                ...formData.data,
                code: `${this.event.code}-${this.getFirstSixChars(id)}`,
              },
              eventID: this.eventID,
              formID: "At1A1rR1eoRc6NPeXe0a",
              uid: id,
            };
            console.log({ formData });
            const hasCapacity = await this.roomsService.checkCapacity(
              this.roomID,
              this.timeToInteger(
                formData.data.personalInformation1.selectTimeSlot.selectedTime
              ),
              formData.data.personalInformation1.selectTimeSlot.selectedDate
            );
            if (hasCapacity) {
              await this.formService.submitForm(data);
              await this.roomsService.incrementRoom(
                this.roomID,
                this.timeToInteger(
                  formData.data.personalInformation1.selectTimeSlot.selectedTime
                ),
                formData.data.personalInformation1.selectTimeSlot.selectedDate
              );
      
              const categoryRef = await this.categoryService.getCategoriesByID(
                this.categoryID
              );
              const category = categoryRef.data();
              console.log(category);
              console.log(formData.data);
              // Send confirmation email
              const emailData = {
                ...data.data?.scannedData,
                ...data.data.personalInformation1,
                code: data.data.code,
                url:"https://diggri.com/thankyou/"+category.tyPage.uid+'/'+id,
                ...data.data.personalInformation1.selectTimeSlot,
              };
              await this.submitService.sendEmail(
                emailData,
                "register",
                category,
                category,data.uid
              );
      
              // 02MzaoSyJfZiZWtmbSxu
              this.loading = false;
              if(category?.tyPage?.uid){
                this.router.navigate(["/thankyou/"+category.tyPage.uid+'/'+id])
              }
              else
              this.router.navigate([
                "/thankyou",
                {
                  typ1: category?.typ1 ? category?.typ1 : "Registration Successful",
                  typ2: category?.typ2
                    ? category?.typ2
                    : "Thank you, your registration has been successful. Our team will contact you within 24-48 hours.",
                },
              ]);
            } else {
              alert("full");
            }
          });
        })

    
   
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  replaceUndefinedWithEmptyString(obj) {
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        this.replaceUndefinedWithEmptyString(obj[key]); // Recursively check nested objects
      } else if (
        typeof obj[key] === "undefined" ||
        !obj[key] ||
        obj[key] === null ||
        obj[key] === ""
      ) {
        obj[key] = ""; // Replace undefined with an empty string
      }
    }
  }
  
  isDateHourFull(hour, date): boolean {
    const data = this.selectedRoom;
    const key = `${date}:${hour}`;
    console.log("key :" + key);
    if (data[key] !== undefined && data.capacity !== undefined) {
      console.log("defined");
      return data[key] < data.capacity;
    }
    console.log("undefined");
    console.log(key);
    console.log(data);
    console.log(data[key]);
    return true;
  }

  translateLabels(components: any[], translations: any[]) {
    components.forEach((component: any) => {
      if (component.label) {
        const matchingTranslation = translations.find(
          (translation) => translation.name === component.label
        );
        if (matchingTranslation) {
          component.label = matchingTranslation.ar; // Replace with the translated label
       
        }
      }
      if (component.placeholder) {
        const matchingTranslation = translations.find(
          (translation) => translation.name === component.placeholder
        );
        if (matchingTranslation) {
          component.placeholder = matchingTranslation.ar; // Replace with the translated placeholder
        }
      }
      // Translate options if applicable
      if (component.data && component.data.values) {
        for (const option of component.data.values) {
          const matchingTranslation = translations.find(
            (translation) => translation.name === option.label
          );
          if (matchingTranslation) {
            option.label = matchingTranslation.ar; // Replace with the translated option label
          }
        }
      }

      // Recursively traverse nested components
      if (component.components) {
        this.translateLabels(component.components, translations);
      }
      if (component.columns) {
        component.columns.forEach((column: any) => {
          if (column.components) {
            this.translateLabels(column.components, translations);
          }
        });
      }
    });
    console.log({components})
    return components
  }

}
