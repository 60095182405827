import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { getFirebaseBackend, firebase } from "../../authUtils";
import { User } from "../models/auth.models";
import { AuthenticationService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class CompanyEventsService {
  private selectedEventSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  selectedEvent$ = this.selectedEventSubject.asObservable();
  private selectedEvent = null;

  setSelectedEvent(event: string): void {
    this.selectedEvent = event;
    this.selectedEventSubject.next(event);
  }

  constructor(private authService: AuthenticationService) {}

  getSelectedEvent(): string {
    return this.selectedEvent;
  }

  getEventsDocuments() {
    return firebase.firestore().collection("/events/");
  }

  getUsersByEventID(eventID: string) {
    return firebase.firestore().collection('users')
      .where('eventsIDs', 'array-contains', eventID)
      .where('roles.owner', '!=', true);
  }

  async getEventsByUserID(user: User) {
    const event = user.event;
    const company : any = user.company;
    const events = [];

    if (event === "All") {
      if (company === "All") {
        const snapshot = await this.getEventsDocuments().get();
        snapshot.forEach((element) => {
          events.push(element.data());
        });
      } else {
        const snapshot = await firebase.firestore()
          .collection("/events/")
          .where("company.uid", "==", user.company.uid)
          .get();

        snapshot.forEach((data) => {
          events.push(data.data());
        });
      }
    } else {
      for (const element of user.eventsIDs) {
        const snapshot = await firebase.firestore()
          .collection("/events/")
          .where("uid", "==", element)
          .get();

        if (snapshot.docs.length > 0) {
          events.push(snapshot.docs[0].data());
        }
      }
    }

    return events;
  }

  async getEventsByUser() {
    const user: User = this.authService.currentUserValue;
    return this.getEventsByUserID(user);
  }

  getEventByID(id) {
    return firebase.firestore().collection("/events/").doc(id).get();
  }

  getEventByCompanyID(id) {
    return firebase.firestore().collection("/events/").where("company.uid", "==", id).get();
  }

  async updateEvent(id, data) {
    await firebase.firestore().collection("/events/").doc(id).update({ ...data }, { merge: true });
    return data;
  }

  async createEvent(data) {
    const user = this.authService.currentUserData();
    const db = firebase.firestore();
    const ref = db.collection("events").doc();
    const id = ref.id;
    const createdAt = new Date().getTime();

    await db.collection("/events/").doc(id).set({ ...data, uid: id, createdAt: createdAt, createdBy: user.uid });
    return data;
  }

  async submitEvent(data) {
    const user = this.authService.currentUserData();
    const db = firebase.firestore();
    const ref = db.collection("submits").doc();
    const id = ref.id;
    const createdAt = new Date().getTime();

    await db.collection("/submits/").doc(id).set({ ...data, uid: id, createdAt: createdAt, createdBy: user.uid });
    return data;
  }

  canRead(user: User): boolean {
    const allowed = ["owner", "admin", "user", "mofa"];
    return this.checkAuthorization(user, allowed);
  }

  canEdit(user: User): boolean {
    const allowed = ["owner", "admin", "mofa"];
    return this.checkAuthorization(user, allowed);
  }

  canDelete(user: User): boolean {
    const allowed = ["owner"];
    return this.checkAuthorization(user, allowed);
  }

  canCreate(user: User): boolean {
    const allowed = ["owner"];
    return this.checkAuthorization(user, allowed);
  }

  checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) return false;
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }
    return false;
  }
}
