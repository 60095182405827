<section dir="ltr">
    <div class="input-group" ngbDropdown>
       
        <button class="btn countryListDropDown" type="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
            <img [src]="selectedCountryFlag" width="20" height="10" style="background-color: gray;"/>
            {{selectedCountryCode}}
        </button>
        <div class="wrapper" ngbDropdownMenu>
            <input class="form-control" type="text" placeholder="Search country" (input)="onSearch($event.target.value)">
            <ul class="options-list">
                <li *ngFor="let country of filteredCountries" (click)="onCountrySelect(country)" ngbDropdownToggle>
                  

                  <img [src]="'https://flagcdn.com/' + (country.iso2 | lowercase) + '.svg'" alt="{{ country.name }} Flag" width="20" height="10">
                  <span class="country-name">{{ country.name }}</span>
                  <span>(+{{ country.phoneCode }})</span>
                </li>
            </ul>
        </div>
        <!-- <input type="tel" class="form-control" aria-label="Text input with dropdown button" placeholder="Enter Phone no"> -->
        <input type="tel" [(ngModel)]="number"  class="form-control" aria-label="Text inpu`t with dropdown button" 
       placeholder="" 
        (change)="onInputChange($event)"
        (input)="onInputChange($event)"
        >

      </div>
</section>