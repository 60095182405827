import { Injectable } from '@angular/core';
import axios from "axios";

@Injectable({
  providedIn: 'root'
})
export class MindeeService {

  constructor() { }
  
  async sendPassportData(passportURL) {
    try {
      const response = await axios.post("https://us-central1-diggri.cloudfunctions.net/checkPassport", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        body: {
          passportURL: passportURL,
        },
        passportURL: passportURL,
      });
      return response.data;
    } catch (error) {
      console.error("Error sending passport data:", error);
      throw error;
    }
  }

  async checkPassportServerSide(submitID,passportURL) {
    try {
      const response = await axios.post("https://us-central1-diggri.cloudfunctions.net/checkPassportServerSide", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        body: {
          submitID: submitID,
          passportURL: passportURL,
        },
        submitID: submitID,
        passportURL: passportURL,
      });
      return response.data;
    } catch (error) {
      console.error("Error sending passport data:", error);
      throw error;
    }
  }

  

  async checkProfileServerSide(submitID,profileURL) {
    try {
      const response = await axios.post("https://us-central1-diggri.cloudfunctions.net/checkProfileServerSide", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        body: {
          submitID: submitID,
          profileURL: profileURL,
        },
        submitID: submitID,
        profileURL: profileURL,
      });
      return response.data;
    } catch (error) {
      console.error("Error sending passport data:", error);
      throw error;
    }
  }

  extractData(item) {
    const prediction = item.prediction;
    if (prediction?.nationality?.values) {
      return {
        nationality: prediction.nationality.values.map((value) => value.content).join(" "),
        documentID: prediction.id_number.values.map((value) => value.content).join(" "),
        full_name: prediction.full_name.values.map((value) => value.content).join(" "),
        expiry_date: prediction.expiry_date.values.map((value) => value.content).join(" "),
        birth_date: prediction.birthday.values.map((value) => value.content).join(" "),
      };
    } else {
      return {
        birth_date: prediction.birth_date.value,
        birth_place: prediction.birth_place.value,
        country: prediction.country.value,
        expiry_date: prediction.expiry_date.value,
        gender: prediction.gender.value,
        full_name: prediction.given_names.map((value) => value.value).join(" "),
        documentID: prediction.id_number.value,
        issuance_date: prediction.issuance_date.value,
        mrz1: prediction.mrz1.value,
        mrz2: prediction.mrz2.value,
        orientation_degrees: prediction.orientation.degrees,
        surname: prediction.surname.value,
      };
    }
  }

  async scanPassport(url) {
    try {
      const res = await this.sendPassportData(url);
      console.log(res);
      const extractedData = res.response.data.rawHttp.document.inference.pages.map(this.extractData);
      console.log(extractedData[0]);
      let finalData = {...extractedData[0],type:res?.response?.stringType};
      finalData.hasUndefinedValue = false;
      finalData.message = '100%';
      for (const key in finalData) {
        if (Object.prototype.hasOwnProperty.call(finalData, key)) {
          if (finalData[key] === undefined || finalData[key] === "") {
            if (key) {
              finalData.hasUndefinedValue = true;
              finalData.message += `Value for ${key} is undefined. `;
            }
          }
        }
      }
      return finalData;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
