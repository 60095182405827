  <add-to-calendar-button 
  [attr.name]="eventName" 
  options="'Apple','Google'" 
  [attr.location]="eventLocation"
  [attr.startDate]="eventStartDate" 
  [attr.endDate]="eventEndDate" 
  [attr.startTime]="eventStartTime"
  [attr.endTime]="eventEndTime" 
  [attr.timeZone]="eventTimeZone">
  </add-to-calendar-button>
