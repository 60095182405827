<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.8/css/intlTelInput.css" />

<div class="d-flex justify-content-center align-content-center">
  <span *ngIf="loading"   class="loader"></span>
</div>
<div *ngIf="(isClosed || categoryData.disabled) && !loading">
  <app-closed-forms></app-closed-forms>
</div>
<div *ngIf="(!isClosed && !categoryData.disabled) && !loading" class="row" [ngStyle]="stylesObj">
  <div id="font">
    
    <div *ngIf="loading" tabindex="-1" style=" pointer-events: none;">
      <formio class="rtl-formio" [(renderOptions)]="lang" [form]="form" [submission]='submitData' (submit)="onSubmit($event)"></formio>
    </div>
    <!-- <div *ngIf="!loading">
      <formio class="rtl-formio"  [(renderOptions)]="lang" [form]="form" [submission]='submitData' (submit)="onSubmit($event)"></formio>
    </div> -->
    <div *ngIf="!loading && languageFromURL==='ar'">
      <formio class="rtl-formio"  [(renderOptions)]="lang" [form]="form" [submission]='submitData' (submit)="onSubmit($event)"></formio>
    </div>
    <div *ngIf="!loading && languageFromURL!='ar'">
      <formio [(renderOptions)]="lang" [form]="form" [submission]='submitData' (submit)="onSubmit($event)"></formio>
    </div>
    
  </div>

</div>