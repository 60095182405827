import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { countries } from "./countries-data";

type CountryCode = string;

@Component({
  selector: 'phone-number-component',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent implements OnInit {
  countries: any[] = countries;
  number :any
  filteredCountries: any[] = [];
  @Input() phoneNumber: any;
  @Output() phoneNumberChange = new EventEmitter<any>();
  selectedCountryFlag = 'https://flagcdn.com/qa.svg'
  selectedCountryCode: CountryCode = ' +' + '974';
  selectedISO ='QA'
  @Input()
  value: any;

  onInputChange(event: any) {
    const eventNumber = event?.target?.value
    let phoneNumber = {
      countryCode:this.selectedISO,
      dialCode:this.selectedCountryCode,
      e164Number:this.selectedCountryCode+eventNumber,
      internationalNumber:this.selectedCountryCode+eventNumber,
      nationalNumber:eventNumber,
      number:eventNumber,
      selectedCountryFlag:this.selectedCountryFlag
    }
    if (eventNumber && eventNumber != "") {
      this.value = phoneNumber;
      this.phoneNumberChange.emit(phoneNumber);
    } else{    
      this.phoneNumberChange.emit(null);
    }
   
  }


  onCountrySelect(country) {
    this.selectedCountryCode = ' +' + country.phoneCode;
    this.selectedISO = country.iso2.toLowerCase()
    this.selectedCountryFlag = 'https://flagcdn.com/' + country.iso2.toLowerCase() + '.svg'
    let phoneNumber = {
      countryCode:this.selectedISO,
      dialCode:this.selectedCountryCode,
      e164Number:this.selectedCountryCode+this.number,
      internationalNumber:this.selectedCountryCode+this.number,
      nationalNumber:this.number,
      number:this.number,
      selectedCountryFlag:this.selectedCountryFlag
    }
    if (phoneNumber.number && phoneNumber.number != "") {
      this.value = phoneNumber
      this.phoneNumberChange.emit(phoneNumber);
    }
    else{     
      this.phoneNumberChange.emit(null);
    }
   
   
  }

  onSearch(searchTerm: string) {
    this.filteredCountries = this.countries.filter(country =>
      country.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }


  constructor() { }

  ngOnInit(): void {
    this.filteredCountries = this.countries
    if(this.phoneNumber){
      this.number = this.phoneNumber.number
      this.selectedCountryCode = this.phoneNumber.dialCode
      this.selectedISO = this.phoneNumber.countryCode
      this.selectedCountryFlag =this.phoneNumber.selectedCountryFlag
    }
  }

}
