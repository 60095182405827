import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { CompanyEventsService } from "src/app/core/services/company-events.service";
import { revenueBarChart, statData } from "./data";
import AWS from "aws-sdk";
import { ChartType } from "./profile.model";
import { SubmitService } from "src/app/core/services/submits.service";
import { firebase } from "src/app/fbInitialize";
import { CategoriesService } from "src/app/core/services/categories.service";

@Component({
  selector: "app-profile-submit-update",
  templateUrl: "./profile-submit-update.component.html",
  styleUrls: ["./profile-submit-update.component.scss"],
})
export class ProfileSubmitUpdateComponent implements OnInit {
  // bread crumb items
  breadCrumbItems: Array<{}>;
  scannedData:any
  revenueBarChart: ChartType;
  statData;
  image = "";
  file = "";
  userEvent: any;
  submit: any;
  itsMe = false;
  editedName = "";
  error :any
  imageSrc : any
  selectedImage:any 
  readerFile:any
  loading = false
  categoryID:any
  passportBase64:any
  profileBase64:any
  userID :any
  constructor(
    private authService: AuthenticationService,
    private eventService: CompanyEventsService,
    private router: Router,
    private submitService: SubmitService,
    private categoryService:CategoriesService
  ) {}

  ngOnInit() {
    this.loading = true
    this.breadCrumbItems = [
      { label: "Contacts" },
      { label: "Profile", active: true },
    ];
    const routeParams = this.router.routerState.snapshot;
    const length = routeParams.url.split("/").length;
    let userID = routeParams.url.split("/")[length - 1];
    this.userID = userID
    if (length > 2) {
      this.submitService.getSubmitByID(userID).then((data) => {
     
 
        data.docs.forEach((res)=>{
          this.submit = res.data()
          });
        this.categoryID = this.submit.categoryID
        console.log(this.submit);
        this.eventService.getEventByID(this.submit.eventID).then((events) => {
          this.userEvent = events.data();
          this.loading = false
        });
      });
    }
    // alert(JSON.stringify(this.submit))
  }
  handleUpload(
    photoType: string,
    data: any,
    fileName: string,
    base64DataParam: any,
    id: string,
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
     
      let varName = "";
      for (const [key, value] of Object.entries(data.data)) {
        if (key.includes("personal")) {
          varName = key;
          break;
        }
      }
      console.log({base64DataParam})
      const base64Data = base64DataParam.split(',')[1]; // Get everything after the comma
      console.log(base64Data)
      const mimeType = "image/jpeg"; // Adjust the mime type accordingly
      const byteCharacters = atob(base64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const photoBlob = new Blob(byteArrays, { type: mimeType });
      let passportCounter = 1
      let profileCounter = 1
      
      if( this.submit?.passportPictures?.length>0){
        passportCounter = passportCounter+this.submit?.passportPictures.length-1
      }
      if( this.submit?.profilePictures?.length>0){
        profileCounter = profileCounter+this.submit?.profilePictures.length-1
      }
      const filePath =
        fileName === "PP"
          ? `passports/${id}_${fileName}_${passportCounter}.jpg`
          : `profiles/${id}_${fileName}_${profileCounter}.jpg`;
         
      const s3 = new AWS.S3({
        accessKeyId: "AKIA5J6SD6XVX4LRHKIW",
        secretAccessKey: "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",
        region: "eu-central-1",
      });

      const params = {
        Bucket: "diggri",
        Key: filePath,
        Body: photoBlob,
        ContentType: mimeType,
      };

      s3.upload(params, async (err, res) => {
        if (err) {
          reject(err);
        } else {
          // if (photoType === "passportPhoto") {
          //   let scannedData = await this.mindeeService
          //     .scanPassport(res.Location)
          //     .catch((error) => {
          //       this.loading = false;
          //       alert("Something went wrong");
          //     });

          //   this.scannedData = scannedData;
          //   data.scannedData = scannedData;
          // }
          delete data.data[varName][photoType];

          if (photoType === "profilePhoto") {
            data.data[varName].profilePictureURL = res.Location;
            data.profilePictureURL = res.Location;
          }
          if (photoType === "passportPhoto") {
            data.data[varName].passportPictureURL = res.Location;
            data.passportPictureURL = res.Location;
          }
          // if (data.data[varName][photoType])
          // data[urlKey] = data.data[varName][photoType];

          resolve(res.Location);
        }
      });
  
    }).catch((e)=>{
      console.log(e)
    });
    
  }


  async checkfilePassport(event) {
    if (!event.target.files || event.target.files.length === 0) {
        console.error("No files uploaded");
        return;
    }
    const file = event.target.files[0];
    if (!file.type.startsWith("image/")) {
      this.error = "File should be an image";
        console.error(this.error);
    } else if (file.size > 5e6) {
        this.error = "File is too large. Over 8MB";
        console.error(this.error);
    } else {
        // Preview the image
        const reader = new FileReader();
        reader.readAsDataURL(file);
        // Set the selected image for upload
        this.selectedImage = file;
        this.readerFile = reader;
        this.error = "";

        // Set up an event handler for when the reader finishes reading the file
        reader.onload = async (e) => {
          const base64Data = e.target.result as string;
          console.log({ base64: base64Data });
          this.passportBase64 = base64Data
        // Call handleUpload if the file is valid
        try {
          
        } catch (error) {
            console.error("Upload failed", error);
            // Handle upload error
        }
        };
      
    }
}

async checkfileProfile(event) {
  if (!event.target.files || event.target.files.length === 0) {
      console.error("No files uploaded");
      return;
  }
  const file = event.target.files[0];
  if (!file.type.startsWith("image/")) {
    this.error = "File should be an image";
      console.error(this.error);
  } else if (file.size > 5e6) {
      this.error = "File is too large. Over 8MB";
      console.error(this.error);
  } else {
      // Preview the image
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // Set the selected image for upload
      this.selectedImage = file;
      this.readerFile = reader;
      this.error = "";

      // Set up an event handler for when the reader finishes reading the file
      reader.onload = async (e) => {
        const base64Data = e.target.result as string;
        console.log({ base64: base64Data });
        this.profileBase64 = base64Data
      // Call handleUpload if the file is valid
      try {
        
      } catch (error) {
          console.error("Upload failed", error);
          // Handle upload error
      }
      };
    
  }
}




async onSubmit() {
  let formData = this.submit
  this.loading = true;
  const id = this.submit.uid;
  let boolProfile = this.submit?.data?.profileStatus?.status === 0;
  let boolPassport = this.submit?.data?.hasUndefinedValue;

  const uploadTasks = [];

  
  if (boolPassport) {
    uploadTasks.push(
      this.handleUpload("passportPhoto", this.submit, "PP", this.passportBase64, id)
    );
  } 
   if (boolProfile) {
    uploadTasks.push(
      this.handleUpload("profilePhoto", this.submit, "PH",this.profileBase64, id)
    );
  }


  try {
    Promise.all(uploadTasks)
      .then((data: any) => {
        this.replaceUndefinedWithEmptyString(data);
        return data;
      })
      .then(async(finalData) => {
        let categoryRef: any;
        let category: any;
        if (this.categoryID) {
          categoryRef = await this.categoryService.getCategoriesByID(
            this.categoryID
          );
          category = categoryRef.data();
        }
        let passportPictures = []
        if(this.submit?.passportPictures){
          passportPictures= [...this.submit?.passportPictures]
        }
        else{
          passportPictures = [...passportPictures,{
            passportPictureURL:this.submit.passportPictureURL,
            createdAt:this.submit.createdAt
          }]
        }
        console.log({finalData})
       
        if(finalData["0"] && boolPassport ){
          passportPictures = [...passportPictures,{
            passportPictureURL:finalData["0"],
            createdAt:Date.now()
          }]
        }

        let profilePictures = []
        if(this.submit?.profilePictures){
          profilePictures= [...this.submit?.profilePictures]
        }
        else{
          profilePictures = [...profilePictures,{
            profilePictureURL:this.submit.profilePictureURL,
            createdAt:this.submit.createdAt
          }]
        }
        if(finalData["1"] && boolProfile  && boolPassport){
          profilePictures = [...profilePictures,{
            profilePictureURL:finalData["1"],
            createdAt:Date.now()
          }]
        }else{
          if(finalData["0"] && boolProfile  && !boolPassport){
            profilePictures = [...profilePictures,{
              profilePictureURL:finalData["0"],
              createdAt:Date.now()
            }]
          }
        }
//finalData.length.toString()
        let data: any = {
          status:'pending',
          passportPictures:passportPictures,
          profilePictures:profilePictures,
          passportPictureURL:  passportPictures?.length>0?passportPictures[passportPictures?.length-1].passportPictureURL:this.submit?.passportPictureURL,
          profilePictureURL: profilePictures?.length>0?profilePictures[profilePictures?.length-1].profilePictureURL:this.submit.profilePictureURL,
          categoryID :  this.categoryID,
          data: {
            category:category?.name,
            categoryClass:category?.categoryClass?category?.categoryClass:'',
            passportPictureURL:  passportPictures?.length>0?passportPictures[passportPictures?.length-1].passportPictureURL:  this.submit?.passportPictureURL,
            profilePictureURL:  profilePictures?.length>0?profilePictures[profilePictures?.length-1].profilePictureURL:  this.submit.profilePictureURL,
            ...formData.data,
          },
    
        };
        console.log({data})
        if (this.scannedData) {
          data.data.scannedData = {
            ...data?.data?.scannedData,
            ...this.scannedData
          };
        }
    
       
        await this.submitService.updateSubmit(this.submit.uid,data);
        this.submit = {
          ...this.submit,
          status:'pending'
        }
        this.loading = false;
      
      });
  } catch (error) {
    this.loading = false;
  }
}

replaceUndefinedWithEmptyString(obj) {
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      this.replaceUndefinedWithEmptyString(obj[key]); // Recursively check nested objects
    } else if (
      typeof obj[key] === "undefined" ||
      !obj[key] ||
      obj[key] === null ||
      obj[key] === ""
    ) {
      obj[key] = ""; // Replace undefined with an empty string
    }
  }
}

}
