import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "app-select-language",
  templateUrl: "./select-language.component.html",
  styleUrls: ["./select-language.component.scss"],
})
export class SelectLanguageComponent implements OnInit {
  selectedLanguage: string;
  baseUrl: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    // Check the current URL to determine the default language
    if (this.router.url.endsWith("lang=ar")) {
      this.selectedLanguage = "Arabic";
    } else
    if (this.router.url.endsWith("lang=fr")) {
      this.selectedLanguage = "French";
    } 
    else {
      this.selectedLanguage = "English";
    }
  }

  ngOnInit(): void {}

  extractBaseUrl(): void {
    const urlTree = this.router.parseUrl(this.router.url);
    const primaryUrl = urlTree.root.children['primary'].segments.map(it => it.path).join('/');
    this.baseUrl = `${window.location.origin}/${primaryUrl}`;
    console.log(this.baseUrl); // For debugging
  }

  updateLanguage() {
    let currentUrl = this.router.url;
  
    // Remove existing lang query parameters
    currentUrl = currentUrl.replace(/(&|\?)lang=[^&]*/g, '');
  
    let queryParams = {};
  
    // Check the selected language and set the queryParams accordingly
    switch (this.selectedLanguage) {
      case "Arabic":
        queryParams = { lang: "ar" };
        break;
      case "French":
        queryParams = { lang: "fr" };
        break;
      default:
        queryParams = {lang: "en"}; // Default to English by removing the lang parameter
        break;
    }
  
    // Navigate with the updated queryParams
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    }).then(() => {
      // Reload the page with the updated URL
      window.location.href = this.router.url;
    });
  }
  
}
