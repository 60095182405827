import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { url } from "inspector";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { CompanyEventsService } from "src/app/core/services/company-events.service";
import { EventService } from "src/app/core/services/event.service";
import { FormService } from "src/app/core/services/form.service";
import { SubmitService } from "src/app/core/services/submits.service";
import { firebase } from "src/app/fbInitialize";
import type {} from "css-font-loading-module";
@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormFilledCustomComponent implements OnInit {
  public editFormID: any;
  breadCrumbItems: Array<{}>;
  form: any;
  submitted = false;
  error = "";
  successmsg = false;
  inputs: Array<{}>;
  formx: any;
  loading = false;
  submitData: any;
  hasCategory: any;
  event: any;
  font: any;
  category: any;
  stylesObj: any;
  email: any;
  photo = false
  constructor(
    private router: Router,
    private formService: FormService,
    private submitService: SubmitService,
    private authService: AuthenticationService,
    private eventService: CompanyEventsService
  ) {}

  ngOnInit(): void {
    const routeParams = this.router.routerState.snapshot;
    this.loading = true;
    const length = routeParams.url?.split("/").length;

  
    
    if (routeParams.url.startsWith("/galadinner/")) {
      this.breadCrumbItems = [
        { label: "Admin" },
        { label: "Users" },
        { label: "Edit User", active: true },
      ];

  
      this.loading = true;

  
      const length = routeParams.url?.split("?").length;
  
      
      let temp = routeParams.url?.split("?")[length - 2];
  
      let length2  = routeParams.url?.split("/").length;
      this.editFormID = temp?.split("/")[length2 - 1];
      //this.editFormID = routeParams.url?.split("/")[length - 1];

      
        this.submitService.getSubmitByID(this.editFormID).then((res) => {
          let data :any;
          res.docs.forEach((res)=>{
            data = res.data()
            });
          let dataRSVP = {
            data: {personalInformation:{...data.data.personalInformation,
              rsvp: "yes"
            } },
          };
          this.submitService.updateSubmit(this.editFormID,{...data,...dataRSVP}).then(() => {
          this.loading = false;
          let varName = "";
     
          for (let [key, value] of Object.entries(data.data)) {
            if (key.includes("personal")) {
              varName = key;
            }
          }
          if (data.passportPictureURL) {
            this.toDataURL(data.passportPictureURL, function (dataUrl) {
            let image =  [{
                hash: "afbecb356e7368b25bb0fce694dbddf5",
                name: "photo-1675882350147-fa2b1c94-06a6-414d-a5ba-3998279bf31d.png",
                originalName: "photo-1675882350147.png",
                storage: "base64",
                type: "image/png",
                url :dataUrl
              }]
              data.data[varName].passportPhoto = image
              // data.data[varName].profilePhoto = image
              // data.data[varName].profilePhoto = dataUrl
            
            });
          }
          if (data.profilePictureURL) {
            this.toDataURL(data.profilePictureURL, function (dataUrl) {
            let image =  [{
                hash: "afbecb356e7368b25bb0fce694dbddf5",
                name: "photo-1675882350147-fa2b1c94-06a6-414d-a5ba-3998279bf31d.png",
                originalName: "photo-1675882350147.png",
                storage: "base64",
                type: "image/png",
                url :dataUrl
              }]
              // data.data[varName].passportPhoto = image
              data.data[varName].profilePhoto = image
              // data.data[varName].profilePhoto = dataUrl
            
            });
          }
       this.email =  data.uid
  
          this.submitData = data;
          delete this.submitData.data.language;
          Object.keys(this.submitData.data).forEach((element) => {
            let length = element.length;
            for (let i = 0; i < length - 1; i++) {
              const str = element[i] || "";
              if (
                element[i] == element[i].toUpperCase() &&
                element[i + 1] == element[i + 1].toUpperCase()
              ) {
                data.data[element.substring(0, length - 2)] = data.data[element];
                delete data.data[element];
              }
            }
          });
          this.formService.getFormByID(data.formID).then(async (res: any) => {
            this.formx = res.data();
         
            if(this.photo){
              if(this.formx.inputs[0].components[0].components){
              //  this.formx.inputs[0].components = [this.formx.inputs[0].components[0]]
                this.formx.inputs[0].components[0].components = [...this.formx.inputs[0].components[0].components.slice(0,2),this.formx.inputs[0].components[0].components[this.formx.inputs[0].components[0].components.length-1]]
              }
              else{
                this.formx.inputs[0].components[1].components = [...this.formx.inputs[0].components[1].components.slice(0,2),this.formx.inputs[0].components[1].components[this.formx.inputs[0].components[1].components.length-1]]
              }
            }
            this.form = {
              components: this.formx.inputs,
            };
            let array = [];
            this.formx?.event.forEach((element) => {
              array.push(element.uid);
            });
            this.eventService
              .getEventByID(this.formx.event[0].uid)
              .then((data) => {
                this.event = data.data();
                this.font = this?.event?.font_url;
                if (this.font) {
                  var f = new FontFace("fingerpaint", `url(${this.font})`, {});
                  document.fonts.add(f);
                  document.getElementById("font").style.fontFamily =
                    "fingerpaint";
                }
              });
          });
        });
      });
     
    } else {
      this.breadCrumbItems = [
        { label: "Admin" },
        { label: "Users" },
        { label: "Create Users", active: true },
      ];
    }
  }
  onSubmit(formData: any) {
    this.loading = true;
    let data = formData;
    let i = 1;
    let varName = "";
    let docsBool = false;
    let profilePictureURL = "";
    let passportPictureURL = "";
    formData = {...formData,
      data: {personalInformation:{...formData.data.personalInformation,
        rsvp: "yes"
      } },
    };
    for (let [key, value] of Object.entries(data.data)) {
      if (key.includes("personal")) {
        varName = key;
      }
    }
    for (let [key, value] of Object.entries(data.data)) {
      if (key.includes("Documents")) {
        docsBool = true;
        for (let [key, value] of Object.entries(data.data.Documents)) {
          if (key.includes("personal")) {
            varName = key;
          }
        }
      }
    }
  
    let boolProfile = false;
    let boolPassport = false;
    if (varName != "") {
      if (this.hasCategory) {
        data.data[varName].category = this.category;
      }
      for (let [key, value] of Object.entries(data.data[varName])) {
        if (key == "passportPhoto") {
          boolPassport = true;
        }
        if (key == "profilePhoto") {
          boolProfile = true;
        }
      }

      if (boolProfile && boolPassport) {
        //alert(4)
        var filePath = `uploaded-data/${
          data.uid
        }${"_PP"}.${"jpg"}`;
        let storageRef = firebase.storage().ref(filePath);
        var filePath2 = `uploaded-data/${
         data.uid
        }${"_PH"}.${"jpg"}`;
        let storageRef2 = firebase.storage().ref(filePath2);

        storageRef
          .put(
            this._base64ToArrayBuffer(
              data.data[varName].passportPhoto[0].url?.split("base64,").pop()
            ),
            { contentType: "jpg" }
          )
          .then(async (uploadSnapshot: firebase.storage.UploadTaskSnapshot) => {
            storageRef.getDownloadURL().then((url) => {
              passportPictureURL = url;
              data.passportPictureURL = url;
              //   //alert(url);

              storageRef2
                .put(
                  this._base64ToArrayBuffer(
                    data.data[varName].profilePhoto[0].url
                      ?.split("base64,")
                      .pop()
                  ),
                  { contentType: "jpg" }
                )
                .then(
                  async (
                    uploadSnapshot: firebase.storage.UploadTaskSnapshot
                  ) => {
                    storageRef2.getDownloadURL().then((url2) => {
                      profilePictureURL = url2;
                      data.profilePictureURL = url2;
                      delete data.data[varName].passportPhoto;
                      delete data.data[varName].profilePhoto;
                      //   //alert(url);
                      // //alert("boolProfile && boolPassport")
                      let array = [];
                      this.formx.event.forEach((element) => {
                        array.push(element.uid);
                      });
                      data.formID = this.formx.uid;
                      data.eventsIDs = array;
                      data.eventID = this.editFormID;
                      data.company = this.formx.company;
                      data.event = this.formx.event;
                      if (data.profilePictureURL && data.passportPictureURL)
                        this.submitService
                          .updateSubmit(this.editFormID, formData)
                          .then(() => {
                            this.submitService
                              .sendEmail(
                                data.data[varName],
                                "register",
                                this.formx,
                                this.event,data.uid
                              )
                              .then(() => {
                                  this.router.navigate(['/thankyou',{
                      typ1:this?.formx?.typ1?this?.formx?.typ1:'Registration Successful',
                      typ2:this?.formx?.typ2?this?.formx?.typ2:'Thank you, your registration has been successful. our team will contact you within 24-48 hours.'
                    }]);
                                this.loading = false;
                              });
                          });
                    });
                  }
                )
                .catch((e) => {});
            });
          })
          .catch((e) => {});
      } else if (boolPassport) {
        //alert(3)
        var filePath = `uploaded-data/${
         data.uid
        }${"_PP"}.${"jpg"}`;
        let storageRef = firebase.storage().ref(filePath);
        storageRef
          .put(
            this._base64ToArrayBuffer(
              data.data[varName].passportPhoto[0].url?.split("base64,").pop()
            ),
            { contentType: "jpg" }
          )
          .then(async (uploadSnapshot: firebase.storage.UploadTaskSnapshot) => {
            storageRef.getDownloadURL().then((url) => {
              passportPictureURL = url;
              data.passportPictureURL = url;
              delete data.data[varName].passportPhoto;
              //    //alert('boolPassport')
              let array = [];
              this.formx.event.forEach((element) => {
                array.push(element.uid);
              });
              data.formID = this.formx.uid;
              data.eventsIDs = array;
              data.eventID = this.editFormID;
              data.company = this.formx.company;
              data.event = this.formx.event;
              this.submitService
                .updateSubmit(this.editFormID, formData)
                .then(() => {
                  this.submitService
                    .sendEmail(
                      data.data[varName],
                      "register",
                      this.formx,
                      this.event,data.uid
                    )
                    .then(() => {
                      this.loading = false;
                        this.router.navigate(['/thankyou',{
                      typ1:this?.formx?.typ1?this?.formx?.typ1:'Registration Successful',
                      typ2:this?.formx?.typ2?this?.formx?.typ2:'Thank you, your registration has been successful. our team will contact you within 24-48 hours.'
                    }]);
                    });
                });
            });
          });
      } else if (boolProfile) {
        //alert(2)
        var filePath = `uploaded-data/${
         data.uid
        }${"_PH"}.${"jpg"}`;
        let storageRef = firebase.storage().ref(filePath);
        storageRef
          .put(
            this._base64ToArrayBuffer(
              data.data[varName].profilePhoto[0].url?.split("base64,").pop()
            ),
            { contentType: "jpg" }
          )
          .then(async (uploadSnapshot: firebase.storage.UploadTaskSnapshot) => {
            storageRef.getDownloadURL().then((url) => {
              profilePictureURL = url;
              data.profilePictureURL = url;
              delete data.data[varName].profilePhoto;
              //         //alert('boolProfile')
              let array = [];
              this.formx.event.forEach((element) => {
                array.push(element.uid);
              });
              data.formID = this.formx.uid;
              data.eventsIDs = array;
              data.eventID = this.editFormID;
              data.company = this.formx.company;
              data.event = this.formx.event;
              this.submitService
                .updateSubmit(this.editFormID, formData)
                .then(() => {
                  this.submitService
                    .sendEmail(
                      data.data[varName],
                      "register",
                      this.formx,
                      this.event,data.uid
                    )
                    .then(() => {
                      this.loading = false;
                        this.router.navigate(['/thankyou',{
                      typ1:this?.formx?.typ1?this?.formx?.typ1:'Registration Successful',
                      typ2:this?.formx?.typ2?this?.formx?.typ2:'Thank you, your registration has been successful. our team will contact you within 24-48 hours.'
                    }]);
                    });
                });
            });
          });
      } else {
        let array = [];
        this.formx.event.forEach((element) => {
          array.push(element.uid);
        });
        data.formID = this.formx.uid;
        data.eventsIDs = array;
        data.eventID = this.editFormID;
        data.company = this.formx.company;
        data.event = this.formx.event;

        this.submitService.updateSubmit(this.editFormID, formData).then(() => {
          this.submitService
            .sendEmail(data.data[varName], "register", this.formx, this.event,data.uid)
            .then(() => {
              this.loading = false;
                this.router.navigate(['/thankyou',{
                      typ1:this?.formx?.typ1?this?.formx?.typ1:'Registration Successful',
                      typ2:this?.formx?.typ2?this?.formx?.typ2:'Thank you, your registration has been successful. our team will contact you within 24-48 hours.'
                    }]);
            });
        });
      }
    } else {
      //alert(1)
      let array = [];
      this.formx.event.forEach((element) => {
        array.push(element.uid);
      });
      data.formID = this.formx.uid;
      data.eventsIDs = array;
      data.eventID = this.editFormID;
      data.company = this.formx.company;
      data.event = this.formx.event;

      this.submitService.updateSubmit(this.editFormID, formData).then(() => {
        this.submitService
          .sendEmail(data.data[varName], "register", this.formx, this.event,data.uid)
          .then(() => {
            this.loading = false;
              this.router.navigate(['/thankyou',{
                      typ1:this?.formx?.typ1?this?.formx?.typ1:'Registration Successful',
                      typ2:this?.formx?.typ2?this?.formx?.typ2:'Thank you, your registration has been successful. our team will contact you within 24-48 hours.'
                    }]);
          });
      });
    }

  }
  isUserAdminOrOwner() {
    const user = this.authService.currentUserValue;
    return user.roles.admin || user.roles.owner;
  }
  showVisa(url) {
    url ? window.open(url) : alert("No Passport Image Found");
  }
  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }
}
