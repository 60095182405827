// import { EventInput } from '@fullcalendar/core';

let eventGuid = 0;
export function createEventId() {
    return String(eventGuid++);
}

const category = [
    {
        name: 'Danger',
        value: 'bg-danger'
    },
    {
        name: 'Success',
        value: 'bg-success'
    },
    {
        name: 'Primary',
        value: 'bg-primary'
    },
    {
        name: 'Info',
        value: 'bg-info'
    },
    {
        name: 'Dark',
        value: 'bg-dark'
    },
    {
        name: 'Warning',
        value: 'bg-warning'
    },
    {
        name: 'Disabled',
        value: 'bg-disabled',
        disabledClassName: 'bg-dark' // Add a custom class name for the disabled state
    },
];

// const today = new Date();
// const start = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 8, 0, 0); // Set the start time to 8 am
// const end = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 9, 0, 0); // Set the end time to 9 am

// const start2 = new Date(); // Get the current time in milliseconds
const start = new Date(); // Get the current time in milliseconds
const end = new Date(start.getTime() + 60 * 60 * 1000); // Add 1 hour (60 minutes * 60 seconds * 1000 milliseconds) to the start time
const currentDateTime = new Date();
// Set the start time to 1 am
const startTime = new Date(currentDateTime);
startTime.setHours(1, 0, 0); // Set hours, minutes, and seconds to 1:00:00 am

// Set the end time to 2 am
const endTime = new Date(currentDateTime);
endTime.setHours(2, 0, 0); // Set hours, minutes, and seconds to 2:00:00 am

const calendarEvents = []
// const calendarEvents: EventInput[] = [
//     {
//         id: createEventId(),
//         title: 'Meeting',
//         durationEditable:false,
//         start: new Date(),
//         end: new Date(),
//         className: 'bg-disabled text-white',
//     },
//     {
//         id: createEventId(),
//         title: 'Meeting',
//         durationEditable: false,
//         editable:false,
//         start: startTime, // Set the start time to the current time
//         end: endTime, // Set the end time to 1 hour later
//         className: 'bg-disabled text-white',
//       },
//     {
//         id: createEventId(),
//         title: 'Lunch',
//         start: new Date(),
//         end: new Date(),
//         durationEditable:false,
//         className: 'bg-dark text-white',
//     },
//     {
//         id: createEventId(),
//         title: 'Lunch',
//         start: start,
//         end: end,
//         durationEditable:false,
//         editable:false,
//         className: 'bg-dark text-white',
//     },
//     {
//         id: createEventId(),
//         durationEditable:false,
//         title: 'Birthday - party',
//         start: new Date().setDate(new Date().getDate() + 1),
//         className: 'bg-disabled text-white',
//     },
//     {
//         id: createEventId(),
//         title: 'Long Event',
//         durationEditable:false,
//         start: start,
//         end: end,
   
//         // actualEnd:'end + serverDeltaEnd', // 
//         // serverDeltaEnd:'', // 
//         className: 'bg-primary text-white'
//     }
// ];

const nationalities = [
    {
      label: "Afghanistan",
      value: "afghanistan",
    },
    {
      label: "Albania",
      value: "albania",
    },
    {
      label: "Algeria",
      value: "algeria",
    },
    {
      label: "Andorra",
      value: "andorra",
    },
    {
      label: "Angola",
      value: "angola",
    },
    {
      label: "Antigua and Barbuda",
      value: "antigua and barbuda",
    },
    {
      label: "Argentina",
      value: "argentina",
    },
    {
      label: "Armenia",
      value: "armenia",
    },
    {
      label: "Australia",
      value: "australia",
    },
    {
      label: "Austria",
      value: "austria",
    },
    {
      label: "Azerbaijan",
      value: "azerbaijan",
    },
    {
      label: "Bahamas",
      value: "bahamas",
    },
    {
      label: "Bahrain",
      value: "bahrain",
    },
    {
      label: "Bangladesh",
      value: "bangladesh",
    },
    {
      label: "Barbados",
      value: "barbados",
    },
    {
      label: "Belarus",
      value: "belarus",
    },
    {
      label: "Belgium",
      value: "belgium",
    },
    {
      label: "Belize",
      value: "belize",
    },
    {
      label: "Benin",
      value: "benin",
    },
    {
      label: "Bhutan",
      value: "bhutan",
    },
    {
      label: "Bolivia",
      value: "bolivia",
    },
    {
      label: "Bosnia Herzegovina",
      value: "bosnia herzegovina",
    },
    {
      label: "Botswana",
      value: "botswana",
    },
    {
      label: "Brazil",
      value: "brazil",
    },
    {
      label: "Brunei",
      value: "brunei",
    },
    {
      label: "Bulgaria",
      value: "bulgaria",
    },
    {
      label: "Burkina",
      value: "burkina",
    },
    {
      label: "Burundi",
      value: "burundi",
    },
    {
      label: "Cambodia",
      value: "cambodia",
    },
    {
      label: "Cameroon",
      value: "cameroon",
    },
    {
      label: "Canada",
      value: "canada",
    },
    {
      label: "Cape Verde",
      value: "cape verde",
    },
    {
      label: "Central African Rep",
      value: "central african rep",
    },
    {
      label: "Chad",
      value: "chad",
    },
    {
      label: "Chile",
      value: "chile",
    },
    {
      label: "China",
      value: "china",
    },
    {
      label: "Colombia",
      value: "colombia",
    },
    {
      label: "Comoros",
      value: "comoros",
    },
    {
      label: "Congo",
      value: "congo",
    },
    {
      label: "Congo {Democratic Rep}",
      value: "congo {democratic rep}",
    },
    {
      label: "Costa Rica",
      value: "costa rica",
    },
    {
      label: "Croatia",
      value: "croatia",
    },
    {
      label: "Cuba",
      value: "cuba",
    },
    {
      label: "Cyprus",
      value: "cyprus",
    },
    {
      label: "Czech Republic",
      value: "czech republic",
    },
    {
      label: "Denmark",
      value: "denmark",
    },
    {
      label: "Djibouti",
      value: "djibouti",
    },
    {
      label: "Dominica",
      value: "dominica",
    },
    {
      label: "Dominican Republic",
      value: "dominican republic",
    },
    {
      label: "East Timor",
      value: "east timor",
    },
    {
      label: "Ecuador",
      value: "ecuador",
    },
    {
      label: "Egypt",
      value: "egypt",
    },
    {
      label: "El Salvador",
      value: "el salvador",
    },
    {
      label: "Equatorial Guinea",
      value: "equatorial guinea",
    },
    {
      label: "Eritrea",
      value: "eritrea",
    },
    {
      label: "Estonia",
      value: "estonia",
    },
    {
      label: "Ethiopia",
      value: "ethiopia",
    },
    {
      label: "Fiji",
      value: "fiji",
    },
    {
      label: "Finland",
      value: "finland",
    },
    {
      label: "France",
      value: "france",
    },
    {
      label: "Gabon",
      value: "gabon",
    },
    {
      label: "Gambia",
      value: "gambia",
    },
    {
      label: "Georgia",
      value: "georgia",
    },
    {
      label: "Germany",
      value: "germany",
    },
    {
      label: "Ghana",
      value: "ghana",
    },
    {
      label: "Greece",
      value: "greece",
    },
    {
      label: "Grenada",
      value: "grenada",
    },
    {
      label: "Guatemala",
      value: "guatemala",
    },
    {
      label: "Guinea",
      value: "guinea",
    },
    {
      label: "Guinea-Bissau",
      value: "guinea-bissau",
    },
    {
      label: "Guyana",
      value: "guyana",
    },
    {
      label: "Haiti",
      value: "haiti",
    },
    {
      label: "Honduras",
      value: "honduras",
    },
    {
      label: "Hungary",
      value: "hungary",
    },
    {
      label: "Iceland",
      value: "iceland",
    },
    {
      label: "India",
      value: "india",
    },
    {
      label: "Indonesia",
      value: "indonesia",
    },
    {
      label: "Iran",
      value: "iran",
    },
    {
      label: "Iraq",
      value: "iraq",
    },
    {
      label: "Ireland",
      value: "ireland",
    },
    {
      label: "Italy",
      value: "italy",
    },
    {
      label: "Ivory Coast",
      value: "ivory coast",
    },
    {
      label: "Jamaica",
      value: "jamaica",
    },
    {
      label: "Japan",
      value: "japan",
    },
    {
      label: "Jordan",
      value: "jordan",
    },
    {
      label: "Kazakhstan",
      value: "kazakhstan",
    },
    {
      label: "Kenya",
      value: "kenya",
    },
    {
      label: "Kiribati",
      value: "kiribati",
    },
    {
      label: "North Korea",
      value: "korea north",
    },
    {
      label: "South Korea",
      value: "korea south",
    },
    {
      label: "Kosovo",
      value: "kosovo",
    },
    {
      label: "Kuwait",
      value: "kuwait",
    },
    {
      label: "Kyrgyzstan",
      value: "kyrgyzstan",
    },
    {
      label: "Laos",
      value: "laos",
    },
    {
      label: "Latvia",
      value: "latvia",
    },
    {
      label: "Lebanon",
      value: "lebanon",
    },
    {
      label: "Lesotho",
      value: "lesotho",
    },
    {
      label: "Liberia",
      value: "liberia",
    },
    {
      label: "Libya",
      value: "libya",
    },
    {
      label: "Liechtenstein",
      value: "liechtenstein",
    },
    {
      label: "Lithuania",
      value: "lithuania",
    },
    {
      label: "Luxembourg",
      value: "luxembourg",
    },
    {
      label: "Macedonia",
      value: "macedonia",
    },
    {
      label: "Madagascar",
      value: "madagascar",
    },
    {
      label: "Malawi",
      value: "malawi",
    },
    {
      label: "Malaysia",
      value: "malaysia",
    },
    {
      label: "Maldives",
      value: "maldives",
    },
    {
      label: "Mali",
      value: "mali",
    },
    {
      label: "Malta",
      value: "malta",
    },
    {
      label: "Marshall Islands",
      value: "marshall islands",
    },
    {
      label: "Mauritania",
      value: "mauritania",
    },
    {
      label: "Mauritius",
      value: "mauritius",
    },
    {
      label: "Mexico",
      value: "mexico",
    },
    {
      label: "Micronesia",
      value: "micronesia",
    },
    {
      label: "Moldova",
      value: "moldova",
    },
    {
      label: "Monaco",
      value: "monaco",
    },
    {
      label: "Mongolia",
      value: "mongolia",
    },
    {
      label: "Montenegro",
      value: "montenegro",
    },
    {
      label: "Morocco",
      value: "morocco",
    },
    {
      label: "Mozambique",
      value: "mozambique",
    },
    {
      label: "Myanmar, (Burma)",
      value: "myanmar",
    },
    {
      label: "Namibia",
      value: "namibia",
    },
    {
      label: "Nauru",
      value: "nauru",
    },
    {
      label: "Nepal",
      value: "nepal",
    },
    {
      label: "Netherlands",
      value: "netherlands",
    },
    {
      label: "New Zealand",
      value: "new zealand",
    },
    {
      label: "Nicaragua",
      value: "nicaragua",
    },
    {
      label: "Niger",
      value: "niger",
    },
    {
      label: "Nigeria",
      value: "nigeria",
    },
    {
      label: "Norway",
      value: "norway",
    },
    {
      label: "Oman",
      value: "oman",
    },
    {
      label: "Pakistan",
      value: "pakistan",
    },
    {
      label: "Palau",
      value: "palau",
    },
    {
      label: "Palestine",
      value: "palestine",
    },
    {
      label: "Panama",
      value: "panama",
    },
    {
      label: "Papua New Guinea",
      value: "papua new guinea",
    },
    {
      label: "Paraguay",
      value: "paraguay",
    },
    {
      label: "Peru",
      value: "peru",
    },
    {
      label: "Philippines",
      value: "philippines",
    },
    {
      label: "Poland",
      value: "poland",
    },
    {
      label: "Portugal",
      value: "portugal",
    },
    {
      label: "Qatar",
      value: "qatar",
    },
    {
      label: "Romania",
      value: "romania",
    },
    {
      label: "Russian Federation",
      value: "russian federation",
    },
    {
      label: "Rwanda",
      value: "rwanda",
    },
    {
      label: "St Kitts & Nevis",
      value: "st kitts & nevis",
    },
    {
      label: "St Lucia",
      value: "st lucia",
    },
    {
      label: "Saint Vincent & the Grenadines",
      value: "saint vincent & the grenadines",
    },
    {
      label: "Samoa",
      value: "samoa",
    },
    {
      label: "San Marino",
      value: "san marino",
    },
    {
      label: "Sao Tome & Principe",
      value: "sao tome & principe",
    },
    {
      label: "Saudi Arabia",
      value: "saudi arabia",
    },
    {
      label: "Senegal",
      value: "senegal",
    },
    {
      label: "Serbia",
      value: "serbia",
    },
    {
      label: "Seychelles",
      value: "seychelles",
    },
    {
      label: "Sierra Leone",
      value: "sierra leone",
    },
    {
      label: "Singapore",
      value: "singapore",
    },
    {
      label: "Slovakia",
      value: "slovakia",
    },
    {
      label: "Slovenia",
      value: "slovenia",
    },
    {
      label: "Solomon Islands",
      value: "solomon islands",
    },
    {
      label: "Somalia",
      value: "somalia",
    },
    {
      label: "South Africa",
      value: "south africa",
    },
    {
      label: "South Sudan",
      value: "south sudan",
    },
    {
      label: "Spain",
      value: "spain",
    },
    {
      label: "Sri Lanka",
      value: "sri lanka",
    },
    {
      label: "Sudan",
      value: "sudan",
    },
    {
      label: "Suriname",
      value: "suriname",
    },
    {
      label: "Swaziland",
      value: "swaziland",
    },
    {
      label: "Sweden",
      value: "sweden",
    },
    {
      label: "Switzerland",
      value: "switzerland",
    },
    {
      label: "Syria",
      value: "syria",
    },
    {
      label: "Taiwan",
      value: "taiwan",
    },
    {
      label: "Tajikistan",
      value: "tajikistan",
    },
    {
      label: "Tanzania",
      value: "tanzania",
    },
    {
      label: "Thailand",
      value: "thailand",
    },
    {
      label: "Togo",
      value: "togo",
    },
    {
      label: "Tonga",
      value: "tonga",
    },
    {
      label: "Trinidad & Tobago",
      value: "trinidad & tobago",
    },
    {
      label: "Tunisia",
      value: "tunisia",
    },
    {
      label: "Türkiye",
      value: "türkiye",
    },
    {
      label: "Turkmenistan",
      value: "turkmenistan",
    },
    {
      label: "Tuvalu",
      value: "tuvalu",
    },
    {
      label: "Uganda",
      value: "uganda",
    },
    {
      label: "Ukraine",
      value: "ukraine",
    },
    {
      label: "United Arab Emirates",
      value: "united arab emirates",
    },
    {
      label: "United Kingdom",
      value: "united kingdom",
    },
    {
      label: "United States of America",
      value: "united states",
    },
    {
      label: "Uruguay",
      value: "uruguay",
    },
    {
      label: "Uzbekistan",
      value: "uzbekistan",
    },
    {
      label: "Vanuatu",
      value: "vanuatu",
    },
    {
      label: "Vatican City",
      value: "vatican city",
    },
    {
      label: "Venezuela",
      value: "venezuela",
    },
    {
      label: "Vietnam",
      value: "vietnam",
    },
    {
      label: "Yemen",
      value: "yemen",
    },
    {
      label: "Zambia",
      value: "zambia",
    },
    {
      label: "Zimbabwe",
      value: "zimbabwe",
    },
  ];
  const titles = [
    {
      label: "Mr.",
      value: "mr",
    },
    {
      label: "Mrs.",
      value: "mrs",
    },
    {
      label: "Ms.",
      value: "ms",
    },
    {
      label: "Dr.",
      value: "dr",
    },
    {
      label: "Prof.",
      value: "prof",
    },
    {
      label: "His Excellency",
      value: "hisExcellency",
    },
    {
      label: "Her Excellency",
      value: "herExcellency",
    },
    {
      label: "Other",
      value: "other",
    },
  ];

export { category, calendarEvents,nationalities,titles };
