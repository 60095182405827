import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'app-face-detector',
  templateUrl: './face-detector.component.html',
  styleUrls: ['./face-detector.component.scss']
})
export class FaceDetectorComponent {
  image: any;
  faces: any[];

 
  @Input()
  value: number;

  @Output()
  valueChange = new EventEmitter<number>();

  
  @Input()
  disabled: boolean;

  @Input()
  formControl: any;

  @Input()
  row: any;

  @Input()
  submission: any;

  @Input()
  data: any;

  @Input()
  component: any;

  ngOnInit(): void {}

  getValue() {
    // alert(JSON.stringify(this.value))
    return this.value;
  }

  setValue(value: any) {
    // alert(JSON.stringify(this.value))
    this.value = value;
  }

  onInputChange(event) {
    this.valueChange.emit(event);
  }


}
