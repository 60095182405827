import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormService } from "src/app/core/services/form.service";
import { SubmitService } from "src/app/core/services/submits.service";

@Component({
  selector: "app-submit-no-page",
  templateUrl: "./submit-no-page.component.html",
  styleUrls: ["./submit-no-page.component.scss"],
})
export class SubmitNoUpdatePageComponent implements OnInit {
  constructor(private router: Router, private formService: FormService,private submitService:SubmitService) {}

  email: any;
  uid: any;
  loading: boolean = false;
  ngOnInit(): void {
    this.loading = true;
    const routeParams = this.router.routerState.snapshot;

    const length = routeParams.url.split("?").length;

    
    let temp = routeParams.url.split("?")[length - 2];

    let length2  = routeParams.url.split("/").length;
    this.uid = temp.split("/")[length2 - 1];
   
    let data = {
      formID: this.uid,
      data: { personalInformation:{
        rsvp: "no", emailAddress: this.email
      } },
    };
    this.submitService.getSubmitByID(this.uid).then((res) => {
      let data :any;
      res.docs.forEach((res)=>{
        data = res.data()
        });
      
      let dataRSVP = {
        data: {personalInformation:{...data.data.personalInformation,
          rsvp: "no"
        } },
      };
    this.submitService.updateSubmit( this.uid,dataRSVP).then(() => {
      this.loading = false;
    });
  });
  }
}
