import { Injectable } from "@angular/core";
import { getFirebaseBackend, firebase } from "../../authUtils";
import { UserX } from "../models/users";
import { AuthenticationService } from "./auth.service";
import { CompanyEventsService } from "./company-events.service";

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService {


  constructor(
    private eventService: CompanyEventsService
  ) {}
  getTermsAndConditionsDocuments(collection) {
    let termsAndConditions = [];
    let events = [];
    return new Promise<any>(async (resolve, reject) => {
      await firebase
        .firestore()
        .collection(collection)
        .get()
        .then((result) => {
          result.forEach((data) => {
            let termsAndConditionsData = data.data();
            termsAndConditions.push(termsAndConditionsData);
          });

          resolve(termsAndConditions);
        });
    });
  }

  getTermsAndConditionsByEventID(collection,id) {
  
    let termsAndConditions = [];
    let events = [];
    return new Promise<any>(async (resolve, reject) => {
      await firebase
        .firestore()
        .collection(collection)
        .where("eventsIDs", "array-contains", id)
        .get()
        .then((result) => {
          result.forEach((data) => {
            let termsAndConditionsData = data.data();
            termsAndConditions.push(termsAndConditionsData);
          });

          resolve(termsAndConditions);
        });
    });
  }

  getTermsAndConditionsByEvents(collection) {

    let termsAndConditions = [];
    let events = [];
    return new Promise<any>((resolve, reject) => {
      this.eventService.getEventsByUser().then((res: any) => {
        return new Promise<any>((resolve, reject) => {
          res.forEach(async (element) => {
            await firebase
              .firestore()
              .collection(collection)
              .where("eventsIDs", "array-contains", element.uid)
              .get()
              .then((result) => {
                result.forEach((data) => {
                  let termsAndConditionsData = data.data();
                  termsAndConditions.push(termsAndConditionsData);
                });
                events.push(element);
                if (events.length == res.length) resolve(termsAndConditions);
              });
          });
        }).then(() => {
          resolve(termsAndConditions);
        });
      });
    });
  }

  getTermsAndConditionsByEventsInput(collection,events) {

    let termsAndConditions = [];

    return new Promise<any>((resolve, reject) => {
      if (events.companyID) {
        firebase
          .firestore()
          .collection(collection)
          .where("company.uid", "==", events.companyID)
          .get()
          .then((result) => {
            result.forEach((data) => {
              let termsAndConditionsData = data.data();
              termsAndConditions.push(termsAndConditionsData);
            });
            resolve(termsAndConditions);
          });
      } else if (events.length > 0) {
        events.forEach(async (element, i) => {
          await firebase
            .firestore()
            .collection("terms-and-conditions")
            .where("eventsIDs", "array-contains", element.uid)
            .get()
            .then((result) => {
              result.forEach((data) => {
                let termsAndConditionsData = data.data();
                termsAndConditions.push(termsAndConditionsData);
              });
              //   events.push(element);
              if (i == events.length - 1) {
                resolve(termsAndConditions);
                //   alert(JSON.stringify(termsAndConditions))
              }
            });
        });
      } else {
        resolve([]);
      }
    });
  }

  getTermsAndConditionsByID(collection,id) {
    return firebase.firestore().collection(collection).doc(id).get();
  }

  isTermsAndConditionsClosed(id) {
    return firebase.firestore().collection("terms-and-conditions").doc(id).get();
  }
  deleteTermsAndConditions(collection,id) {
    return firebase.firestore().collection(collection).doc(id).delete();
  }

  getTermsAndConditionsByCompanyID() {}
  updateTermsAndConditions(collection,id, data) {
    return firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .update({ ...data }, { merge: true })
      .then((response: any) => {
        const termsAndConditionsData = response;
        return termsAndConditionsData;
      });
  }
  createTermsAndConditions(collection,data) {

    const db = firebase.firestore();
    const ref = db.collection(collection).doc();
    const id = ref.id;
    const createdAt = new Date().getTime();

    data?.inputs?.map((data) => {
      if (data.key == "file") {
        data.storage = "base64";
      }
    });

    return db
      .collection(collection)
      .doc(id)
      .set({ ...data, uid: id, createdAt: createdAt})
      .then((response: any) => {
        const termsAndConditionsData = response;
        return termsAndConditionsData;
      });
  }
 
  statusCounterEventUpdate(id, type, incrementAmount) {
    const documentRef = firebase.firestore().collection("/events/").doc(id);
    const increment = firebase.firestore.FieldValue.increment(incrementAmount);
    const varName = `submitsCounter.${type}`;
    return documentRef.update({
      [varName]: increment,
    });
  }
  submitTermsAndConditions(collection,data) {
    return new Promise<any>((resolve, reject) => {
      data.status = "pending";
      // let user = this.authService.currentUserData();
      const db = firebase.firestore();
      const ref = db.collection(collection).doc();
      const refID = ref.id;
      let id = data.uid ? data.uid : refID;
      const createdAt = new Date().getTime();

      return db
        .collection("/submits/")
        .doc(refID)
        .set({
          ...data,
          uid: refID,
          createdAt: createdAt,
          // createdBy: user?.uid ? user?.uid : "",
        })
        .then(async (response: any) => {

          const termsAndConditionsData = response;
          resolve({
            ...data,
            uid: refID,
            createdAt: createdAt,
            // createdBy: user?.uid ? user?.uid : "",
          });
        });
    });
  }
  canRead(user: UserX): boolean {
    const allowed = ["owner", "admin", "user", "mofa"];
    return this.checkAuthorization(user, allowed);
  }

  canEdit(user: UserX): boolean {
    const allowed = ["owner", "admin", "mofa"];
    return this.checkAuthorization(user, allowed);
  }

  canDelete(user: UserX): boolean {
    const allowed = ["owner"];
    return this.checkAuthorization(user, allowed);
  }
  canCreate(user: UserX): boolean {
    const allowed = ["owner"];
    return this.checkAuthorization(user, allowed);
  }

  checkAuthorization(user: UserX, allowedRoles: string[]): boolean {
    if (!user) return false;
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }
    return false;
  }

}
