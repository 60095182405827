import firebase from "firebase/app";
import "firebase/storage";

import { environment } from "../environments/environment";

if (!firebase.apps.length) {

  firebase.initializeApp(environment.firebaseConfig,'firebase');
} else {
  firebase.app(); // if already initialized, use that one
}

export { firebase };
