const nationalities = [
  {
      "value": "United States of America",
      "label": "United States of America",
      "arabicLabel": "الولايات المتحدة الأمريكية"
    },
    {
      "value": "Congo {Democratic Rep}",
      "label": "Congo {Democratic Rep}",
      "arabicLabel": "الكونغو {الجمهورية الديمقراطية}"
    },
    {
      "value": "Myanmar, (Burma)",
      "label": "Myanmar, (Burma)",
      "arabicLabel": "ميانمار (بورما)"
    },
   
    {
      "value": "North Korea",
      "label": "North Korea",
      "arabicLabel": "كوريا الشمالية"
    },
    {
      "value": "South Korea",
      "label": "South Korea",
      "arabicLabel": "كوريا الجنوبية"
    },
    
      {
        "value": "ethiopia",
        "label": "ethiopia",
        "arabicLabel": "أثيوبيا"
      },
      {
        "arabicLabel": "أذربيجان",
        "value": "azerbaijan",
        "label": "azerbaijan"
      },
      {
        "arabicLabel": "أرمينيا",
        "value": "armenia",
        "label": "armenia"
      },
      {
        "arabicLabel": "أستراليا",
        "value": "australia",
        "label": "australia"
      },
      {
        "arabicLabel": "أفغانستان",
        "value": "afghanistan",
        "label": "afghanistan"
      },
      {
        "arabicLabel": "ألبانيا",
        "value": "albania",
        "label": "albania"
      },
      {
        "arabicLabel": "ألمانيا",
        "value": "germany",
        "label": "germany"
      },
      {
        "value": "antigua and barbuda",
        "label": "antigua and barbuda",
        "arabicLabel": "أنتيغوا وبربودا"
      },
      {
        "arabicLabel": "أندورا",
        "value": "andorra",
        "label": "andorra"
      },
      {
        "arabicLabel": "أنغولا",
        "value": "angola",
        "label": "angola"
      },
      {
        "value": "uruguay",
        "label": "uruguay",
        "arabicLabel": "أوروغواي"
      },
      {
        "value": "uzbekistan",
        "label": "uzbekistan",
        "arabicLabel": "أوزبكستان"
      },
      {
        "arabicLabel": "أوغندا",
        "value": "uganda",
        "label": "uganda"
      },
      {
        "value": "ukraine",
        "label": "ukraine",
        "arabicLabel": "أوكرانيا"
      },
      {
        "value": "ireland",
        "label": "ireland",
        "arabicLabel": "أيرلندا"
      },
      {
        "value": "iceland",
        "label": "iceland",
        "arabicLabel": "أيسلندا"
      },
      {
        "value": "eritrea",
        "label": "eritrea",
        "arabicLabel": "إريتريا"
      },
      {
        "value": "spain",
        "label": "spain",
        "arabicLabel": "إسبانيا"
      },
      {
        "value": "estonia",
        "label": "estonia",
        "arabicLabel": "إستونيا"
      },
      {
        "arabicLabel": "إندونيسيا",
        "value": "indonesia",
        "label": "indonesia"
      },
      {
        "value": "iran",
        "label": "iran",
        "arabicLabel": "إيران"
      },
      {
        "arabicLabel": "إيطاليا",
        "value": "italy",
        "label": "italy"
      },
      {
        "arabicLabel": "الأرجنتين",
        "value": "argentina",
        "label": "argentina"
      },
      {
        "value": "jordan",
        "label": "jordan",
        "arabicLabel": "الأردن"
      },
      {
        "value": "united arab emirates",
        "label": "united arab emirates",
        "arabicLabel": "الإمارات العربية المتحدة"
      },
      {
        "arabicLabel": "الاتحاد الروسي",
        "value": "russian federation",
        "label": "russian federation"
      },
      {
        "value": "ecuador",
        "label": "ecuador",
        "arabicLabel": "الاكوادور"
      },
      {
        "arabicLabel": "البحرين",
        "value": "bahrain",
        "label": "bahrain"
      },
      {
        "value": "brazil",
        "label": "brazil",
        "arabicLabel": "البرازيل"
      },
      {
        "value": "portugal",
        "label": "portugal",
        "arabicLabel": "البرتغال"
      },
      {
        "value": "bosnia herzegovina",
        "label": "bosnia herzegovina",
        "arabicLabel": "البوسنة والهرسك"
      },
      {
        "arabicLabel": "الجابون",
        "value": "gabon",
        "label": "gabon"
      },
      {
        "value": "montenegro",
        "label": "montenegro",
        "arabicLabel": "مونتينيغرو"
      },
      {
        "arabicLabel": "الجزائر",
        "value": "algeria",
        "label": "algeria"
      },
      {
        "arabicLabel": "الدنمارك",
        "value": "denmark",
        "label": "denmark"
      },
      {
        "value": "cape verde",
        "label": "cape verde",
        "arabicLabel": "كاب فردي"
      },
      {
        "value": "el salvador",
        "label": "el salvador",
        "arabicLabel": "السلفادور"
      },
      {
        "arabicLabel": "السنغال",
        "value": "senegal",
        "label": "senegal"
      },
      {
        "value": "sudan",
        "label": "sudan",
        "arabicLabel": "السودان"
      },
      {
        "value": "sweden",
        "label": "sweden",
        "arabicLabel": "السويد"
      },
      {
        "value": "somalia",
        "label": "somalia",
        "arabicLabel": "الصومال"
      },
      {
        "arabicLabel": "الصين",
        "value": "china",
        "label": "china"
      },
      {
        "arabicLabel": "العراق",
        "value": "iraq",
        "label": "iraq"
      },
      {
        "arabicLabel": "الفلبين",
        "value": "philippines",
        "label": "philippines"
      },
      {
        "value": "cameroon",
        "label": "cameroon",
        "arabicLabel": "الكاميرون"
      },
      {
        "arabicLabel": "الكونغو",
        "value": "congo",
        "label": "congo"
      },
      {
        "value": "kuwait",
        "label": "kuwait",
        "arabicLabel": "الكويت"
      },
      {
        "arabicLabel": "المغرب",
        "value": "morocco",
        "label": "morocco"
      },
      {
        "value": "mexico",
        "label": "mexico",
        "arabicLabel": "المكسيك"
      },
      {
        "arabicLabel": "المملكة العربية السعودية",
        "value": "saudi arabia",
        "label": "saudi arabia"
      },
      {
        "value": "united kingdom",
        "label": "united kingdom",
        "arabicLabel": "المملكة المتحدة"
      },
      {
        "arabicLabel": "النرويج",
        "value": "norway",
        "label": "norway"
      },
      {
        "value": "austria",
        "label": "austria",
        "arabicLabel": "النمسا"
      },
      {
        "arabicLabel": "النيجر",
        "value": "niger",
        "label": "niger"
      },
      {
        "arabicLabel": "الهند",
        "value": "india",
        "label": "india"
      },
      {
        "arabicLabel": "الولايات المتحدة الأمريكية",
        "value": "united states",
        "label": "united states"
      },
      {
        "value": "japan",
        "label": "japan",
        "arabicLabel": "اليابان"
      },
      {
        "arabicLabel": "اليمن",
        "value": "yemen",
        "label": "yemen"
      },
      {
        "arabicLabel": "اليونان",
        "value": "greece",
        "label": "greece"
      },
      {
        "arabicLabel": "بابوا غينيا الجديدة",
        "value": "papua new guinea",
        "label": "papua new guinea"
      },
      {
        "arabicLabel": "باراغواي",
        "value": "paraguay",
        "label": "paraguay"
      },
      {
        "value": "pakistan",
        "label": "pakistan",
        "arabicLabel": "باكستان"
      },
      {
        "arabicLabel": "بالاو",
        "value": "palau",
        "label": "palau"
      },
      {
        "value": "barbados",
        "label": "barbados",
        "arabicLabel": "بربادوس"
      },
      {
        "arabicLabel": "بروناي",
        "value": "brunei",
        "label": "brunei"
      },
      {
        "arabicLabel": "بلجيكا",
        "value": "belgium",
        "label": "belgium"
      },
      {
        "value": "bulgaria",
        "label": "bulgaria",
        "arabicLabel": "بلغاريا"
      },
      {
        "arabicLabel": "باليز",
        "value": "belize",
        "label": "belize"
      },
      {
        "arabicLabel": "بنغلاديش",
        "value": "bangladesh",
        "label": "bangladesh"
      },
      {
        "arabicLabel": "بنما",
        "value": "panama",
        "label": "panama"
      },
      {
        "arabicLabel": "بنين",
        "value": "benin",
        "label": "benin"
      },
      {
        "arabicLabel": "بوتان",
        "value": "bhutan",
        "label": "bhutan"
      },
      {
        "arabicLabel": "بوتسوانا",
        "value": "botswana",
        "label": "botswana"
      },
      {
        "arabicLabel": "بوركينا فاسو",
        "value": "burkina",
        "label": "burkina"
      },
      {
        "arabicLabel": "بوروندي",
        "value": "burundi",
        "label": "burundi"
      },
      {
        "value": "poland",
        "label": "poland",
        "arabicLabel": "بولندا"
      },
      {
        "value": "bolivia",
        "label": "bolivia",
        "arabicLabel": "بوليفيا"
      },
      {
        "arabicLabel": "بيرو",
        "value": "peru",
        "label": "peru"
      },
      {
        "arabicLabel": "بيلاروسيا",
        "value": "belarus",
        "label": "belarus"
      },
      {
        "value": "thailand",
        "label": "thailand",
        "arabicLabel": "تايلاند"
      },
      {
        "value": "taiwan",
        "label": "taiwan",
        "arabicLabel": "تايوان"
      },
      {
        "value": "turkmenistan",
        "label": "turkmenistan",
        "arabicLabel": "تركمانستان"
      },
      {
        "arabicLabel": "تركيا",
        "value": "türkiye",
        "label": "türkiye"
      },
      {
        "value": "trinidad & tobago",
        "label": "trinidad & tobago",
        "arabicLabel": "ترينيداد وتوباجو"
      },
      {
        "arabicLabel": "تشاد",
        "value": "chad",
        "label": "chad"
      },
      {
        "arabicLabel": "تشيلي",
        "value": "chile",
        "label": "chile"
      },
      {
        "arabicLabel": "تنزانيا",
        "value": "tanzania",
        "label": "tanzania"
      },
      {
        "value": "togo",
        "label": "togo",
        "arabicLabel": "توجو"
      },
      {
        "value": "tuvalu",
        "label": "tuvalu",
        "arabicLabel": "توفالو"
      },
      {
        "value": "tunisia",
        "label": "tunisia",
        "arabicLabel": "تونس"
      },
      {
        "value": "tonga",
        "label": "tonga",
        "arabicLabel": "تونغا"
      },
      {
        "arabicLabel": "تيمور الشرقية",
        "value": "east timor",
        "label": "east timor"
      },
      {
        "arabicLabel": "جامايكا",
        "value": "jamaica",
        "label": "jamaica"
      },
      {
        "value": "bahamas",
        "label": "bahamas",
        "arabicLabel": "جزر البهاما"
      },
      {
        "value": "comoros",
        "label": "comoros",
        "arabicLabel": "جزر القمر"
      },
      {
        "arabicLabel": "جزر المالديف",
        "value": "maldives",
        "label": "maldives"
      },
      {
        "value": "solomon islands",
        "label": "solomon islands",
        "arabicLabel": "جزر سليمان"
      },
      {
        "arabicLabel": "جزر مارشال",
        "value": "marshall islands",
        "label": "marshall islands"
      },
      {
        "arabicLabel": "جمهورية أفريقيا الوسطى",
        "value": "central african rep",
        "label": "central african rep"
      },
      {
        "arabicLabel": "جمهورية التشيك",
        "value": "czech republic",
        "label": "czech republic"
      },
      {
        "arabicLabel": "جمهورية الدومينيكان",
        "value": "dominican republic",
        "label": "dominican republic"
      },
      {
        "value": "congo democratic rep}",
        "label": "congo democratic rep}",
        "arabicLabel": "جمهورية الكونغو الديمقراطية"
      },
      {
        "value": "south africa",
        "label": "south africa",
        "arabicLabel": "جنوب أفريقيا"
      },
      {
        "arabicLabel": "جنوب السودان",
        "value": "south sudan",
        "label": "south sudan"
      },
      {
        "arabicLabel": "جورجيا",
        "value": "georgia",
        "label": "georgia"
      },
      {
        "arabicLabel": "جيبوتي",
        "value": "djibouti",
        "label": "djibouti"
      },
      {
        "arabicLabel": "قطر",
        "value": "qatar",
        "label": "qatar"
      },
      {
        "arabicLabel": "دومينيكا",
        "value": "dominica",
        "label": "dominica"
      },
      {
        "arabicLabel": "رواندا",
        "value": "rwanda",
        "label": "rwanda"
      },
      {
        "value": "romania",
        "label": "romania",
        "arabicLabel": "رومانيا"
      },
      {
        "arabicLabel": "زامبيا",
        "value": "zambia",
        "label": "zambia"
      },
      {
        "arabicLabel": "زيمبابوي",
        "value": "zimbabwe",
        "label": "zimbabwe"
      },
      {
        "arabicLabel": "ساحل العاج",
        "value": "ivory coast",
        "label": "ivory coast"
      },
      {
        "value": "samoa",
        "label": "samoa",
        "arabicLabel": "ساموا"
      },
      {
        "arabicLabel": "سان تومي وبرينسيبي",
        "value": "sao tome & principe",
        "label": "sao tome & principe"
      },
      {
        "arabicLabel": "سان مارينو",
        "value": "san marino",
        "label": "san marino"
      },
      {
        "value": "saint vincent & the grenadines",
        "label": "saint vincent & the grenadines",
        "arabicLabel": "سانت فنسنت وجزر غرينادين"
      },
      {
        "value": "st kitts & nevis",
        "label": "st kitts & nevis",
        "arabicLabel": "سانت كيتس ونيفيس"
      },
      {
        "value": "st lucia",
        "label": "st lucia",
        "arabicLabel": "سانت لوسيا"
      },
      {
        "arabicLabel": "سلطنة عمان",
        "value": "oman",
        "label": "oman"
      },
      {
        "arabicLabel": "سلوفاكيا",
        "value": "slovakia",
        "label": "slovakia"
      },
      {
        "arabicLabel": "سلوفينيا",
        "value": "slovenia",
        "label": "slovenia"
      },
      {
        "value": "singapore",
        "label": "singapore",
        "arabicLabel": "سنغافورة"
      },
      {
        "value": "swaziland",
        "label": "swaziland",
        "arabicLabel": "سوازيلاند"
      },
      {
        "value": "syria",
        "label": "syria",
        "arabicLabel": "سوريا"
      },
      {
        "value": "suriname",
        "label": "suriname",
        "arabicLabel": "سورينام"
      },
      {
        "arabicLabel": "سويسرا",
        "value": "switzerland",
        "label": "switzerland"
      },
      {
        "value": "sierra leone",
        "label": "sierra leone",
        "arabicLabel": "سيرا ليون"
      },
      {
        "arabicLabel": "سيريلانكا",
        "value": "sri lanka",
        "label": "sri lanka"
      },
      {
        "value": "seychelles",
        "label": "seychelles",
        "arabicLabel": "سيشيل"
      },
      {
        "value": "serbia",
        "label": "serbia",
        "arabicLabel": "صربيا"
      },
      {
        "value": "tajikistan",
        "label": "tajikistan",
        "arabicLabel": "طاجيكستان"
      },
      {
        "value": "gambia",
        "label": "gambia",
        "arabicLabel": "غامبيا"
      },
      {
        "value": "ghana",
        "label": "ghana",
        "arabicLabel": "غانا"
      },
      {
        "value": "grenada",
        "label": "grenada",
        "arabicLabel": "غرينادا"
      },
      {
        "arabicLabel": "غواتيمالا",
        "value": "guatemala",
        "label": "guatemala"
      },
      {
        "value": "guyana",
        "label": "guyana",
        "arabicLabel": "غيانا"
      },
      {
        "arabicLabel": "غينيا الإستوائية",
        "value": "equatorial guinea",
        "label": "equatorial guinea"
      },
      {
        "value": "guinea-bissau",
        "label": "guinea-bissau",
        "arabicLabel": "غينيا بيساو"
      },
      {
        "value": "guinea",
        "label": "guinea",
        "arabicLabel": "غينيا"
      },
      {
        "arabicLabel": "فانواتو",
        "value": "vanuatu",
        "label": "vanuatu"
      },
      {
        "value": "france",
        "label": "france",
        "arabicLabel": "فرنسا"
      },
      {
        "arabicLabel": "فلسطين",
        "value": "palestine",
        "label": "palestine"
      },
      {
        "arabicLabel": "فنزويلا",
        "value": "venezuela",
        "label": "venezuela"
      },
      {
        "value": "finland",
        "label": "finland",
        "arabicLabel": "فنلندا"
      },
      {
        "value": "vietnam",
        "label": "vietnam",
        "arabicLabel": "فيتنام"
      },
      {
        "arabicLabel": "فيجي",
        "value": "fiji",
        "label": "fiji"
      },
      {
        "value": "cyprus",
        "label": "cyprus",
        "arabicLabel": "قبرص"
      },
      {
        "value": "kyrgyzstan",
        "label": "kyrgyzstan",
        "arabicLabel": "قيرغيزستان"
      },
      {
        "value": "kazakhstan",
        "label": "kazakhstan",
        "arabicLabel": "كازاخستان"
      },
      {
        "value": "croatia",
        "label": "croatia",
        "arabicLabel": "كرواتيا"
      },
      {
        "arabicLabel": "كمبوديا",
        "value": "cambodia",
        "label": "cambodia"
      },
      {
        "value": "canada",
        "label": "canada",
        "arabicLabel": "كندا"
      },
      {
        "arabicLabel": "كوبا",
        "value": "cuba",
        "label": "cuba"
      },
      {
        "arabicLabel": "كوريا الجنوبية",
        "value": "korea south",
        "label": "korea south"
      },
      {
        "value": "korea north",
        "label": "korea north",
        "arabicLabel": "كوريا الشمالية"
      },
      {
        "arabicLabel": "كوستا ريكا",
        "value": "costa rica",
        "label": "costa rica"
      },
      {
        "arabicLabel": "كوسوفو",
        "value": "kosovo",
        "label": "kosovo"
      },
      {
        "arabicLabel": "كولومبيا",
        "value": "colombia",
        "label": "colombia"
      },
      {
        "arabicLabel": "كيريباتي",
        "value": "kiribati",
        "label": "kiribati"
      },
      {
        "value": "kenya",
        "label": "kenya",
        "arabicLabel": "كينيا"
      },
      {
        "value": "latvia",
        "label": "latvia",
        "arabicLabel": "لاتفيا"
      },
      {
        "value": "laos",
        "label": "laos",
        "arabicLabel": "لاوس"
      },
      {
        "arabicLabel": "لبنان",
        "value": "lebanon",
        "label": "lebanon"
      },
      {
        "arabicLabel": "لوكسمبورغ",
        "value": "luxembourg",
        "label": "luxembourg"
      },
      {
        "value": "libya",
        "label": "libya",
        "arabicLabel": "ليبيا"
      },
      {
        "arabicLabel": "ليبيريا",
        "value": "liberia",
        "label": "liberia"
      },
      {
        "value": "lithuania",
        "label": "lithuania",
        "arabicLabel": "ليتوانيا"
      },
      {
        "arabicLabel": "ليختنشتاين",
        "value": "liechtenstein",
        "label": "liechtenstein"
      },
      {
        "value": "lesotho",
        "label": "lesotho",
        "arabicLabel": "ليسوتو"
      },
      {
        "arabicLabel": "مالطا",
        "value": "malta",
        "label": "malta"
      },
      {
        "value": "mali",
        "label": "mali",
        "arabicLabel": "مالي"
      },
      {
        "value": "malaysia",
        "label": "malaysia",
        "arabicLabel": "ماليزيا"
      },
      {
        "value": "madagascar",
        "label": "madagascar",
        "arabicLabel": "مدغشقر"
      },
      {
        "value": "vatican city",
        "label": "vatican city",
        "arabicLabel": "مدينة الفاتيكان"
      },
      {
        "arabicLabel": "مصر",
        "value": "egypt",
        "label": "egypt"
      },
      {
        "arabicLabel": "مقدونيا",
        "value": "macedonia",
        "label": "macedonia"
      },
      {
        "value": "malawi",
        "label": "malawi",
        "arabicLabel": "ملاوي"
      },
      {
        "value": "mongolia",
        "label": "mongolia",
        "arabicLabel": "منغوليا"
      },
      {
        "value": "mauritania",
        "label": "mauritania",
        "arabicLabel": "موريتانيا"
      },
      {
        "arabicLabel": "موريشيوس",
        "value": "mauritius",
        "label": "mauritius"
      },
      {
        "arabicLabel": "موزمبيق",
        "value": "mozambique",
        "label": "mozambique"
      },
      {
        "value": "moldova",
        "label": "moldova",
        "arabicLabel": "مولدوفا"
      },
      {
        "value": "monaco",
        "label": "monaco",
        "arabicLabel": "موناكو"
      },
      {
        "value": "myanmar",
        "label": "myanmar",
        "arabicLabel": "ميانمار ، (بورما)"
      },
      {
        "arabicLabel": "ميكرونيزيا",
        "value": "micronesia",
        "label": "micronesia"
      },
      {
        "arabicLabel": "ناميبيا",
        "value": "namibia",
        "label": "namibia"
      },
      {
        "value": "nauru",
        "label": "nauru",
        "arabicLabel": "ناورو"
      },
      {
        "value": "nepal",
        "label": "nepal",
        "arabicLabel": "نيبال"
      },
      {
        "arabicLabel": "نيجيريا",
        "value": "nigeria",
        "label": "nigeria"
      },
      {
        "value": "nicaragua",
        "label": "nicaragua",
        "arabicLabel": "نيكاراغوا"
      },
      {
        "arabicLabel": "نيوزيلاندا",
        "value": "new zealand",
        "label": "new zealand"
      },
      {
        "arabicLabel": "هايتي",
        "value": "haiti",
        "label": "haiti"
      },
      {
        "arabicLabel": "هندوراس",
        "value": "honduras",
        "label": "honduras"
      },
      {
        "arabicLabel": "هنغاريا",
        "value": "hungary",
        "label": "hungary"
      },
      {
        "arabicLabel": "هولندا",
        "value": "netherlands",
        "label": "netherlands"
      }
    ]
  const titles = [
    {
      label: "Mr.",
      arabicLabel: "السيد",
      value: "mr",
    },
    {
      label: "Mrs.",
      arabicLabel: "السيدة",
      value: "mrs",
    },
    {
      label: "Ms.",
      arabicLabel: "الآنسة",
      value: "ms",
    },
    {
      label: "Dr.",
      arabicLabel: "دكتور",
      value: "dr",
    },
    {
      label: "Prof.",
      arabicLabel: "بروفيسور",
      value: "prof",
    },
    {
      label: "His Excellency",
      arabicLabel:  "صاحب سعادة",
      value: "hisExcellency",
    },
    {
      label: "Her Excellency",
      arabicLabel:  "صاحبة سعادة",
      value: "herExcellency",
    },
    {
      label: "Sheikh",
      arabicLabel:"الشيخ",
      value: "Sheikh",
    },
    {
      label: "Sheikha",
      arabicLabel:"الشيخة",
      value: "Sheikha",
    },
    {
      label: "Other",
      arabicLabel: "آخر",
      value: "other",
    },
  ];
  const flags = [
    { label: 'QatarEnergy', value: 'QatarEnergy', arabicLabel: 'قطر للطاقة' },
    { label: 'Organization of Arab Petroleum Exporting Countries', value: 'Organization of Arab Petroleum Exporting Countries', arabicLabel: 'منظمة الأقطار العربية المصدرة للبترول' },
    { label: 'United Arab Emirates', value: 'United Arab Emirates', arabicLabel: 'الإمارات العربية المتحدة' },
    { label: 'Bahrain', value: 'Bahrain', arabicLabel: 'البحرين' },
    { label: 'Algeria', value: 'Algeria', arabicLabel: 'الجزائر' },
    { label: 'Kingdom of Saudi Arabia', value: 'Kingdom of Saudi Arabia', arabicLabel: 'المملكة العربية السعودية' },
    { label: 'Syria', value: 'Syria', arabicLabel: 'سوريا' },
    { label: 'Iraq', value: 'Iraq', arabicLabel: 'العراق' },
    { label: 'Qatar', value: 'Qatar', arabicLabel: 'قطر' },
    { label: 'Kuwait', value: 'Kuwait', arabicLabel: 'الكويت' },
    { label: 'Libya', value: 'Libya', arabicLabel: 'ليبيا' },
    { label: 'Egypt', value: 'Egypt', arabicLabel: 'مصر' },
    { label: 'Tunisia', value: 'Tunisia', arabicLabel: 'تونس' },
    { label: 'Oman', value: 'Oman', arabicLabel: 'عُمان' },
    { label: 'Jordan', value: 'Jordan', arabicLabel: 'الأردن' },
    { label: 'Mauritania', value: 'Mauritania', arabicLabel: 'موريتانيا' },
    { label: 'Morocco', value: 'Morocco', arabicLabel: 'المغرب' },
    { label: 'Lebanon', value: 'Lebanon', arabicLabel: 'لبنان' },
    { label: 'Sudan', value: 'Sudan', arabicLabel: 'السودان' },
    { label: 'Yemen', value: 'Yemen', arabicLabel: 'اليمن' },
    { label: 'Palestine', value: 'Palestine', arabicLabel: 'فلسطين' }
  ];
  

  export {
    nationalities,titles,flags
  }