export class MrzParser {

    static parseTwoLines(line1: string, line2: string): any {
        let mrzInfo: any = {};
        let type = line1.substring(0, 1);
        if (!(/[I|P|V]/.test(type))) return false;
        if (type === 'P') {
            mrzInfo.type = 'PASSPORT (TD-3)';
        } 
        return mrzInfo;
    };

    static parseThreeLines(line1: string, line2: string, line3: string): any {
        let mrzInfo: any = {};
        let type = line1.substring(0, 1);
        if (!(/[I|P|V]/.test(type))) return false;
        mrzInfo.type = 'ID CARD (TD-1)';
        return mrzInfo;
    }

}