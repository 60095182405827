import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import { environment } from "../environments/environment.prod";

import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "./core/services/auth.service";

class FirebaseAuthBackend {
  secondaryApp: any;

  constructor(firebaseConfig) {
    if (firebaseConfig) {
      this.secondaryApp = firebase.initializeApp(
        environment.firebaseConfig,
        "UserCreation"
      );

      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
          // Set Firestore settings
    const firestoreSettings = { timestampsInSnapshots: true, cacheSizeBytes: 9000000 };
    firebase.firestore().settings(firestoreSettings);
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          sessionStorage.setItem("authUser", JSON.stringify(user));
        } else {
          sessionStorage.removeItem("authUser");
        }
      });
    }
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (user: any) => {
            var user: any = firebase.auth().currentUser;
            resolve(user);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  addUser = (email, password) => {
    return new Promise((resolve, reject) => {
      this.secondaryApp
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (user: any) => {
            var user: any = this.secondaryApp.auth().currentUser;
            this.secondaryApp.auth().signOut();
            resolve(user);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
      // firebase.auth().createUserWithEmailAndPassword(email, password).then((user: any) => {
      //     var user: any = firebase.auth().currentUser;
      //     resolve(user);
      // }, (error) => {
      //     reject(this._handleError(error));
      // });
    });
  };

  getUsersDate() {}

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          (user: any) => {
            // eslint-disable-next-line no-redeclare
            firebase
              .firestore()
              .collection("users")
              .where("email", "==", email)
              .limit(1)
              .get()
              .then(async (res) => {
                let user = res.docs[0]?.data();
                user.navigate = true;
                resolve(user);
              });
          },
          (error) => {
            if (
              error ==
              "Error: Proof of ownership of a second factor is required to complete sign-in."
            ) {
              const phoneInfoOptions = {
                multiFactorHint: error.resolver.hints[0],
                session: error.resolver.session,
              };

              firebase
                .firestore()
                .collection("users")
                .where("email", "==", email)
                .limit(1)
                .get()
                .then(async (res) => {
                  let user = res.docs[0].data();
                  let userPhoneNumber = user.phone.e164Number;
                  var reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
                    "recaptcha-container",
                    {
                      size: "invisible",
                    }
                  );

                  const phoneAuthProvider =
                    new firebase.auth.PhoneAuthProvider();
                  let verificationId: any;
                  await phoneAuthProvider
                    .verifyPhoneNumber(phoneInfoOptions, reCaptchaVerifier)
                    .then((e) => {
                      verificationId = e;
                    });

                  user.resolver = error.resolver;
                  user.verificationId = verificationId;
                  resolve(user);
                });
            } else {
              reject(this._handleError(error));
            }
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      // tslint:disable-next-line: max-line-length
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  setLoggeedInUser = (user) => {
    sessionStorage.setItem("authUser", JSON.stringify(user));
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!sessionStorage.getItem("authUser")) {
      return null;
    }
    return JSON.parse(sessionStorage.getItem("authUser"));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // tslint:disable-next-line: prefer-const
    var errorMessage = error.message;
    return errorMessage;
  }
}

// tslint:disable-next-line: variable-name
let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend, firebase };
