import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
  OnChanges,
} from "@angular/core";
import { MetisMenu } from "metismenujs";
import { EventService } from "../../core/services/event.service";
import { Router, NavigationEnd } from "@angular/router";

import { HttpClient } from "@angular/common/http";

import { MENU } from "./menu";
import { MenuItem } from "./menu.model";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { CompanyEventsService } from "src/app/core/services/company-events.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild("componentRef") scrollRef;
  @Input() isCondensed = false;
  menu: any;
  data: any;
  user: any;
  event: any;
  menuItems = [];

  @ViewChild("sideMenu") sideMenu: ElementRef;

  constructor(
    private eventService: EventService,
    private eventsService: CompanyEventsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    public translate: TranslateService,
    private http: HttpClient
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this._activateMenuDropdown();
        this._scrollElement();
      }
    });
  }
  isItemActive(item: any): boolean {
    // Replace 'link' with the property name that represents the item's link
    return true;
  }
  updateMenuItems(event) {
    this.event = JSON.parse(localStorage.getItem("event"));
  }
  ngOnInit() {
    this.eventsService.selectedEvent$.subscribe((event) => {
      this.updateMenuItems(event);
    });

    const currentEvent = this.eventsService.getSelectedEvent();
    if (currentEvent) {
      this.updateMenuItems(currentEvent);
    }
    this.authenticationService
      .getUser(this.authenticationService.currentUserData().uid)
      .then((e) => {
        this.user = e;
      });
    this.initialize();
    this._scrollElement();
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this?.sideMenu?.nativeElement);
    this._activateMenuDropdown();
  }

  toggleMenu(event) {
    event.currentTarget.nextElementSibling.classList.toggle("mm-show");
  }

  ngOnChanges() {
    if ((!this.isCondensed && this.sideMenu) || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this?.sideMenu?.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }
  _scrollElement() {
    setTimeout(() => {
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition =
          document.getElementsByClassName("mm-active")[0]["offsetTop"];
        if (currentPosition > 500)
          if (this.scrollRef?.SimpleBar !== null)
            this.scrollRef.SimpleBar.getScrollElement().scrollTop =
              currentPosition + 300;
      }
    }, 300);
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Activate the parent dropdown
   */
  _activateMenuDropdown() {
    this.initialize();
    this._removeAllClass("mm-active");
    // this._removeAllClass("mm-show");
    const links = document.getElementsByClassName("side-nav-link-ref");
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    const paths = [];
    for (let i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      menuItemEl = links[paths.indexOf(item)];
    } else {
      menuItemEl = links[itemIndex];
    }
    if (menuItemEl) {
      menuItemEl.classList.add("active");
      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add("mm-active");
        const parent2El = parentEl.parentElement.closest("ul");
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.add("mm-show");
          const parent3El = parent2El.parentElement;
          if (parent3El && parent3El.id !== "side-menu") {
            parent3El.classList.add("mm-active");
            const childAnchor = parent3El.querySelector(".has-arrow");
            const childDropdown = parent3El.querySelector(".has-dropdown");
            if (childAnchor) {
              childAnchor.classList.add("mm-active");
            }
            if (childDropdown) {
              childDropdown.classList.add("mm-active");
            }
            const parent4El = parent3El.parentElement;
            if (parent4El && parent4El.id !== "side-menu") {
              parent4El.classList.add("mm-show");
              const parent5El = parent4El.parentElement;
              if (parent5El && parent5El.id !== "side-menu") {
                parent5El.classList.add("mm-active");
                const childanchor = parent5El.querySelector(".is-parent");
                if (childanchor && parent5El.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  }

  checkAuthorization(allowedRoles: string[]): boolean {
    if (!allowedRoles) return true;
    let user: any;
    try {
      user = JSON.parse(localStorage.getItem("currentUser"));
    } catch (e) {}

    if (!user) return false;

    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }
    return false;
  }

  /**
   * Initialize
   */
  initialize(): void {
    let data = {
      id: 2,
      label: "Project",
      userRole: ["owner", "admin", "user"],
      parentId: 2,
      subItems: [
        
        {
          userRole: ["owner", "admin", "user"],
          id: 7,
          label: "Records",
          link: "/forms",
          parentId: 2,
        },
        {
          userRole: ["owner", "admin", "user"],
          id: 7,
          label: "Stakeholders",
          link: "/stakeholders",
          parentId: 2,
        },
        {
          userRole: ["owner", "admin", "user"],
          id: 7,
          label: "Inventory",
          link: "/inventory",
          parentId: 2,
        },
        {
          userRole: ["owner", "admin", "user"],
          id: 7,
          label: "Accreditation",
          link: "/accreditation",
          parentId: 2,
        },
        {
          userRole: ["owner", "admin", "user"],
          id: 7,
          label: "File Manager",
          link: "/files",
          parentId: 2,
        },
      ],
    };

    let shouldAdd = !MENU[1].subItems.some(
      (element: any) => element.label === data.label
    );

    if (this.event?.uid) {
      if (shouldAdd) {
        this.menuItems = MENU;
        //   MENU[1].subItems.push(data);
      }
    } else {
      let index = MENU[1].subItems.findIndex(
        (element: any) => element.label === data.label
      );
      if (index !== -1) {
        MENU[1].subItems.splice(index, 1);
      }
    }

    this.menuItems = MENU;
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
}
