<input type="file" class="form-control" accept="image/*" (change)="onFileChange($event)" />

<div class="text-center col-md-4">
    <img [src]="croppedImage" />
  </div>
  <canvas id="canvas" style="width:200px;"></canvas>
<!-- <ng-template #cropperModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Crop Image</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
            [resizeToWidth]="256" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
            (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
            </div>
            </ng-template> -->
            <!-- <div >
                <canvas id="overlay"></canvas>
            </div> -->