import { Injectable } from "@angular/core";
import { getFirebaseBackend, firebase } from "../../authUtils";
import { UserX } from "../models/users";
import { AuthenticationService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {

  constructor(private authService: AuthenticationService) { }

  getTranslationsCollection() {
    return firebase.firestore().collection("/translations/");
  }

  getTranslationByID(id) {
    return this.getTranslationsCollection().doc(id).get();
  }

  updateTranslation(id, data) {
    return this.getTranslationsCollection().doc(id).update(data);
  }

  deleteTranslation(id) {
    return this.getTranslationsCollection().doc(id).set({deleted:true},{merge:true});
  }

  createTranslation(data) {
    let user = this.authService.currentUserData();
    const db = firebase.firestore();
    const ref = db.collection("translations").doc();
    const id = ref.id;
    const createdAt = new Date().getTime()
    return this.getTranslationsCollection().doc(id).set({
      ...data,
      uid: id,
      createdAt: createdAt,
      createdBy: user.uid
    });
  }

  canRead(user: UserX): boolean {
    const allowed = ["admin", "translator"]; // Modify this based on your roles
    return this.checkAuthorization(user, allowed);
  }

  canEdit(user: UserX): boolean {
    const allowed = ["admin", "translator"]; // Modify this based on your roles
    return this.checkAuthorization(user, allowed);
  }

  canDelete(user: UserX): boolean {
    const allowed = ["admin"]; // Modify this based on your roles
    return this.checkAuthorization(user, allowed);
  }

  canCreate(user: UserX): boolean {
    const allowed = ["admin"]; // Modify this based on your roles
    return this.checkAuthorization(user, allowed);
  }

  checkAuthorization(user: UserX, allowedRoles: string[]): boolean {
    if (!user) return false;
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }
    return false;
  }
}
