export const environment = {
  production: true,
  defaultauth: 'firebase',
  firebaseConfig: {
    apiKey: "AIzaSyBq6GkNWPPvMRfB3rqBL60miyq9QfTugpw",
    authDomain: "diggri.firebaseapp.com",
    projectId: "diggri",
    storageBucket: "diggri.appspot.com",
    messagingSenderId: "413373455661",
    appId: "1:413373455661:web:5590def6204103bb7f87a4",
    measurementId: "G-Y9DXQ1NM1F"
  }
};
