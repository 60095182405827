
<div class="row" [ngStyle]="stylesObj">
  <div id="font"  >
 
    <span *ngIf="loading"   class="loader mt-5"></span>
   
    <div *ngIf="!loading">
        <formio  [form]="form" [submission]='submitData' (submit)="onSubmit($event)"></formio>
    </div>
  </div>
 
</div>