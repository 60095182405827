import { Injectable } from "@angular/core";
import { getFirebaseBackend, firebase } from "../../authUtils";
import { UserX } from "../models/users";
import { AuthenticationService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class RoomsService {
  constructor(private authService: AuthenticationService) { }

  getRoomsCollection() {
    return firebase.firestore().collection("/events-rooms/");
  }
  getRoomsCollectionByEvent(eventID) {
    return firebase
      .firestore()
      .collection("/events-rooms/")
      .where("eventID", "==", eventID)
      .get();
  }
  getRoomsCollectionByEventSnap(eventID) {
    return firebase
      .firestore()
      .collection("/events-rooms/")
      .where("eventID", "==", eventID)
  }

  

  getRoomByID(id) {
    return this.getRoomsCollection().doc(id).get();
  }

  updateRoom(id, data) {
    return this.getRoomsCollection().doc(id).update(data);
  }

  deleteRoom(id) {
    return this.getRoomsCollection().doc(id).set({deleted:true},{merge:true});
  }

  createRoom(data) {
    let user = this.authService.currentUserData();
    const db = firebase.firestore();
    const ref = db.collection("events-rooms").doc();
    const id = ref.id;
    const createdAt = new Date().getTime();
    return this.getRoomsCollection()
      .doc(id)
      .set({
        ...data,
        uid: id,
        createdAt: createdAt,
        createdBy: user.uid,
      });
  }

  decrementRoom(id, hour, date) {
    // Assuming you have already initialized Firebase
    const db = firebase.firestore();
    const collectionRef = db.collection("events-rooms"); // Replace with your collection name

    // Function to increment the value of a specific hour

    collectionRef
      .doc(id)
      .get() // Replace with your document ID
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data[date + ':' + hour] !== undefined) {
            // Increment the specific hour value
            data[date + ':' + hour] -= 1;
            // Update the document in Firebase with the new data
            return collectionRef.doc(id).update(data);
          }
        }
      })
      .catch((error) => {
        console.error("Error incrementing hour value:", error);
      });
  }

  incrementRoom(id, hour, date) {
    // Assuming you have already initialized Firebase
    const db = firebase.firestore();
    const collectionRef = db.collection("events-rooms"); // Replace with your collection name

    // Function to increment the value of a specific hour

    collectionRef
      .doc(id)
      .get() // Replace with your document ID
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data[date + ':' + hour] !== undefined) {
            // Increment the specific hour value
            data[date + ':' + hour] += 1;
            // Update the document in Firebase with the new data
            return collectionRef.doc(id).update(data);
          }
        }
      })
      .catch((error) => {
        console.error("Error incrementing hour value:", error);
      });
  }

  async checkCapacity(id, hour, date): Promise<boolean> {
    try {
      // Assuming you have already initialized Firebase
      const db = firebase.firestore();
      const collectionRef = db.collection('events-rooms'); // Replace with your collection name

      const doc = await collectionRef.doc(id).get(); // Replace with your document ID
      console.log(date + ':' + hour)
      if (doc.exists) {
        const data = doc.data();

        if (data[date + ':' + hour] !== undefined && data.capacity !== undefined) {
          const capacity = data.capacity;
          return data[date + ':' + hour] < capacity;
        } else {
          return true;
        }
      }
      return false;
    } catch (error) {
      return false;
    }
  }




  canRead(user: UserX): boolean {
    const allowed = ["admin", "translator"]; // Modify this based on your roles
    return this.checkAuthorization(user, allowed);
  }

  canEdit(user: UserX): boolean {
    const allowed = ["admin", "translator"]; // Modify this based on your roles
    return this.checkAuthorization(user, allowed);
  }

  canDelete(user: UserX): boolean {
    const allowed = ["admin"]; // Modify this based on your roles
    return this.checkAuthorization(user, allowed);
  }

  canCreate(user: UserX): boolean {
    const allowed = ["admin"]; // Modify this based on your roles
    return this.checkAuthorization(user, allowed);
  }

  checkAuthorization(user: UserX, allowedRoles: string[]): boolean {
    if (!user) return false;
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }
    return false;
  }
}
