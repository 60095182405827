import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ImageCroppedEvent, LoadedImage } from "ngx-image-cropper";
import { FormioCustomComponent, FormioEvent } from "angular-formio";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-tel-input";
import { NgxMrzSdkService } from "ngx-mrz-sdk";
import { OverlayManagerData } from "src/app/core/services/overlay";
import { LabelRecognizer } from "dynamsoft-label-recognizer";
import { MrzParser } from "src/app/core/services/parser";
import * as smartcrop from "../../../../../node_modules/smartcrop/smartcrop.js";

@Component({
  selector: "app-image-cropper",
  templateUrl: "./image-cropper.component.html",
  styleUrls: ["./image-cropper.component.scss"],
})
export class ImageCropperProfileComponent
  implements OnInit, FormioCustomComponent<any>
{
  reader: any;
  mrzParser: MrzParser;
  isLoaded = false;
  videoElement: HTMLVideoElement;
  canvasElement: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  videoSelect: HTMLSelectElement;
  mediaStream: MediaStream;

  imageChangedEvent = "";
  croppedImage = "";
  finalImage = "";
  closeResult: any;

  constructor(
    private modalService: NgbModal,
    private overlayManager: OverlayManagerData
  ) {}

  separateDialCode = true;
  searchCountryField = SearchCountryField;
  countryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  @Input()
  value: any;

  @Output()
  valueChange = new EventEmitter<any>();

  @Input()
  disabled: boolean;

  @Input()
  formControl: any;

  @Input()
  row: any;

  @Input()
  submission: any;

  @Input()
  data: any;

  @Input()
  component: any;

  ngOnInit(): void {
    this.mrzParser = new MrzParser();
    this.videoElement = document.getElementById(
      "videoElement"
    ) as HTMLVideoElement;
    this.canvasElement = document.getElementById("canvas") as HTMLCanvasElement;
    if (this.canvasElement) {
      this.context = this.canvasElement.getContext("2d");
    }
    this.videoSelect = document.getElementById(
      "videoSource"
    ) as HTMLSelectElement;

    // Select the video source and set constraints
    const videoConstraints = {
      video: {
        width: { ideal: 1920 },
        height: { ideal: 1080 },
        facingMode: { ideal: "environment" }, // Use the back camera
      },
    };

    navigator.mediaDevices
      .enumerateDevices()
      .then((devices: MediaDeviceInfo[]) => {
        const videoDevices = devices.filter(
          (device: MediaDeviceInfo) => device.kind === "videoinput"
        );

        if (videoDevices.length > 0) {
          const videoSource = videoDevices[0].deviceId;
          const constraints = {
            video: {
              deviceId: videoSource ? { exact: videoSource } : undefined,
              ...videoConstraints.video,
            },
          };

          navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream: MediaStream) => {
              this.mediaStream = stream;
              this.videoElement.srcObject = stream;
              this.videoElement.play();
            })
            .catch((err) => {
            });
        }
      });

    this.overlayManager.initOverlay(
      document.getElementById("overlay") as HTMLCanvasElement
    );

    (async () => {
      LabelRecognizer.onResourcesLoaded = (resourcePath) => {
        this.isLoaded = true;
      };
      this.reader = await LabelRecognizer.createInstance();
      await this.reader.updateRuntimeSettingsFromString("MRZ");
    })();
  }

  getValue() {
    return this.value;
  }

  setValue(value: any) {
    this.value = value.target.files[0];
  }

  onInputChange(event) {
    this.valueChange.emit(event);
  }

  fileChangeEvent(event: any, modal): void {
    this.imageChangedEvent = event;
    this.openModal(modal);
  }
  onFileChange(event: any): void {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = async () => {
        const cropOptions = { width: 25, height: 25 };
        const crop = await smartcrop.crop(img, cropOptions);
        const cropX = crop.topCrop?.x;
        const cropY = crop.topCrop?.y;
        const cropWidth = crop.topCrop?.width;
        const cropHeight = crop.topCrop?.height;
        const canvas = document.querySelector("canvas") as HTMLCanvasElement;
        if (canvas) {
          canvas.width = cropWidth;
          canvas.height = cropHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(
            img,
            cropX,
            cropY,
            cropWidth,
            cropHeight,
            0,
            0,
            cropWidth,
            cropHeight
          );
          this.croppedImage = canvas.toDataURL();
        }
      };
    };
    reader.readAsDataURL(file);
  }

  imageCropped(event) {
    this.value = event.base64;
    this.valueChange.emit(event);
    this.croppedImage = event.base64;
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  imgChangeEvt: any = "";
  cropImgPreview: any = "";
  // onFileChange(event: any): void {
  //   this.valueChange.emit(event.target.files[0]);
  //   this.imgChangeEvt = event;
  // }
  cropImg(e: ImageCroppedEvent) {
    this.cropImgPreview = e.base64;
  }
  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() {
    // error msg
  }
  onSave() {
    this.finalImage = this.croppedImage;

    this.modalService.dismissAll();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  openModal(modal) {
    this.modalService
      .open(modal, { size: "md", ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  countryChange(e) {}
}
