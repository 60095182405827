import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
} from "@angular/core";
import { FormioCustomComponent } from "angular-formio";
import "add-to-calendar-button";

@Component({
  selector: "app-add-to-calendar-button",
  templateUrl: "./add-to-calendar-button.component.html",
  styleUrls: ["./add-to-calendar-button.component.scss"],
})
export class AddToCalendarButtonComponent
  implements OnInit, OnChanges, FormioCustomComponent<any>
{
  @Input() value: any;
  @Output() valueChange = new EventEmitter<any>();
  @Input() disabled: boolean;
  @Input() formControl: any;
  @Input() row: any;
  @Input() submission: any;
  @Input() data: any;
  @Input() component: any;
  @Input() hideLabel: any;
  @Input() hidden: any;
  @Input() attribute: any;
  @Input() form: any;

  eventName: string = 'Default Event';
  eventOptions: string = 'Google,Apple';
  eventLocation: string = 'Online';
  eventStartDate: string = '2024-06-01';
  eventEndDate: string = '2024-06-02';
  eventStartTime: string = '10:00';
  eventEndTime: string = '11:00';
  eventTimeZone: string = 'America/New_York';
  
  eventDescription: string = 'Description';
  

  constructor() {}

  ngOnInit(): void {
    console.log("Initial Values on ngOnInit:");
    this.logInputValues();
    this.loadAddToCalendarButton();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("Values on ngOnChanges:");
    this.logInputValues();
    this.loadAddToCalendarButton();
  }

  logInputValues(): void {
    console.log("Value:", this.value);
    console.log("Disabled:", this.disabled);
    console.log("FormControl:", this.formControl);
    console.log("Row:", this.row);
    console.log("Submission:", this.submission);
    console.log("Data:", this.data);
    console.log("Component:", this.component);
    console.log("HideLabel:", this.hideLabel);
    console.log("Hidden:", this.hidden);
    console.log("Attribute:", this.attribute);
    console.log("Form:", this.form);
  }

  loadAddToCalendarButton(): void {
    this.eventName = this.value?.eventName || "Default Event";
    this.eventDescription =
      this.value?.description ||
      "This is a description of the event.";
    this.eventStartDate =
      this.value?.startDate || "2024-06-01";
    this.eventEndDate = this.value?.endDate || "2024-06-02";
    this.eventLocation = this.value?.location || "Online";
    this.eventOptions = this.value?.options || "'Google,Apple'"
    
    this.eventTimeZone = this.value?.timeZone || "Asia/Qatar";

    this.eventStartTime =
      this.value?.startTime || this.eventStartTime;
    this.eventEndTime =
      this.value?.endTime || this.eventEndTime;
    // (window as any).atcb_action({
    //   name: this.eventName,
    //   description: this.eventDescription,
    //   startDate: this.eventStartDate,
    //   endDate: this.eventEndDate,
    //   location: this.eventLocation,
    //   options: this.eventOptions,
    //   timeZone: this.eventTimeZone,
    //   trigger: "click",
    // });
  }
}
