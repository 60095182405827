import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';

@Injectable({
  providedIn: 'root'
})
export class AwsSnsService {

  private sns: AWS.SNS;

  constructor() {
    // Configure AWS credentials
    AWS.config.update({
      region: 'eu-central-1', // Replace with your AWS region
      accessKeyId: 'AKIA5J6SD6XVQGTVHQ4O',
      secretAccessKey: 'T78+RDRBfkEwHsz9bc2EXP9lYLeKnZMehfg5j6ck',
    });

    // Create an instance of AWS SNS
    this.sns = new AWS.SNS({ apiVersion: '2010-03-31' });
  }

  async sendSMS(phoneNumber: string, message: string): Promise<void> {
    const params = {
      Message: message,
      PhoneNumber: phoneNumber,
    };

    try {
      const data = await this.sns.publish(params).promise();
      console.log('SMS sent successfully:', data);
    } catch (error) {
      console.log('Error sending SMS:', error);
      throw error;
    }
  }
}
