import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Injector, NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { environment } from "../environments/environment";

import {
  NgbNavModule,
  NgbAccordionModule,
  NgbTooltipModule,
  NgbPaginationModule,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { BsModalService } from 'ngx-bootstrap/modal';
import { CarouselModule } from "ngx-owl-carousel-o";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { RouterModule } from "@angular/router";
import { LayoutsModule } from "./layouts/layouts.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { initFirebaseBackend } from "./authUtils";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ImageCropperModule } from 'ngx-image-cropper';
import { ErrorInterceptor } from "./core/helpers/error.interceptor";
import { JwtInterceptor } from "./core/helpers/jwt.interceptor";

import { FakeBackendInterceptor } from "./core/helpers/fake-backend";
import { UsersComponent } from "./pages/dashboards/users/users.component";
import { RegFilterPipe } from "./pipes/reg-filter.pipe";
import { PassportUploadComponent } from "./components/passport-upload/passport-upload.component";
import { ProfilePhotoComponent } from "./components/profile-photo/profile-photo.component";
import { QidUploadComponent } from "./components/qid-upload/qid-upload.component";
import { FormioModule } from "angular-formio";
import { FormBuilderComponent } from "./components/form-builder/form-builder.component";
import { AuthenticationService } from "./core/services/auth.service";
import { ZipUploadComponent } from './components/zip-upload/zip-upload.component';
import { StakeholdersComponent } from "./pages/stakeholders/stakeholders.component";
import { CategoriesFilterPipe } from './pipes/categories-filter.pipe';
import { NgSelectModule } from "@ng-select/ng-select";
// import { FullCalendarModule } from '@fullcalendar/angular';
import { CreateUserComponent } from "./pages/dashboards/users/create-user/create-user.component";
import { PhoneNumberInputComponent } from "./formio/custom-components/phone-number-input/phone-number-input.component";
import { registerCustomComponent } from "./formio/rating-wrapper.formio";
import { PagesModule } from "./pages/pages.module";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { FaceDetectorComponent } from './formio/custom-components/face-detector/face-detector.component';
import { ImageCropperProfileComponent } from './formio/custom-components/image-cropper/image-cropper.component';
import { AngularFirestoreModule, SETTINGS as FIRESTORE_SETTINGS } from '@angular/fire/firestore';

import { FormsModule } from "@angular/forms";

import { SelectTimeComponent } from './formio/custom-components/select-time/select-time.component';
import { SelectRoomComponent } from './formio/custom-components/select-room/select-room.component';
import { SelectDateComponent } from "./formio/custom-components/select-date/select-date.component";
import { OverlayManagerData } from "./core/services/overlay";
import { RoomsFilterPipe } from "./pipes/rooms-filter.pipe";
import { LabelDescriptionComponent } from './formio/custom-components/label-description/label-description.component';
import { PrintButtonComponent } from './formio/custom-components/print-button/print-button.component';
import { SelectLanguageComponent } from './formio/custom-components/select-language/select-language.component';
import { PhoneComponent } from './formio/custom-components/phone/phone.component';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { AddToCalendarButtonComponent } from './formio/custom-components/add-to-calendar-button/add-to-calendar-button.component';
import { BlueammoniaComponent } from './blueammonia/blueammonia.component';


if (environment.defaultauth === "firebase") {
  initFirebaseBackend(environment.firebaseConfig);
} else {
  // tslint:disable-next-line: no-unused-expression
  FakeBackendInterceptor;
}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    PassportUploadComponent,
    ProfilePhotoComponent,
    QidUploadComponent,
    FormBuilderComponent,
    ZipUploadComponent,
    FaceDetectorComponent,
    LabelDescriptionComponent,
    SelectLanguageComponent,
    PhoneComponent,
    AddToCalendarButtonComponent,
    BlueammoniaComponent
  ],
  imports: [
    FormsModule, // Add FormsModule here
    NgSelectModule,
    ImageCropperModule,
    NgxIntlTelInputModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormioModule,
    // FullCalendarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    LayoutsModule,
    AppRoutingModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    ScrollToModule.forRoot(),
    NgbModule,
    BsDatepickerModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    BsModalService,
    OverlayManagerData,
    AuthenticationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FakeBackendInterceptor,
      multi: true,
    },
    // LoaderService,
    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
  ],
})

export class AppModule {
 

}
