import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { RoomsService } from 'src/app/core/services/rooms.service';

@Component({
  selector: 'app-label-description',
  templateUrl: './label-description.component.html',
  styleUrls: ['./label-description.component.scss']
})
export class LabelDescriptionComponent implements OnInit {

  constructor(
    private roomsService: RoomsService,
    private route: ActivatedRoute
    ) { 
  
  }
  @Input()
  value: string;
  @Input()
  disabled: boolean;

  @Input()
  formControl: any;

  @Input()
  row: any;

  @Input()
  submission: any;

  @Input()
  data: any;

  @Input()
  component: any;

  @Input()
  roomID:any

  @Input()
  selectedRoom:any

  loading = false
  ngOnInit(): void {
    // this.hours = this.selectableHours();
    // const eventID = localStorage.getItem("eventID");
    // this.getRooms(eventID);
    this.loading = true
    this.route.paramMap.subscribe((params: ParamMap) => {
      const roomID = params.get("roomID");
      this.roomID = roomID;
      this.getRoom();
    });
  
  }

  async getRoom() {
    try {
      const room = await this.roomsService.getRoomByID(this.roomID);
      this.selectedRoom = room.data();
      this.loading = false
    } catch (error) {
      this.loading = false
      console.error("Error fetching rooms:", error);
   
    }
  }
}
