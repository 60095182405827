<header id="page-topbar" >
    <div class="navbar-header w-100" >
        <div class="row d-flex w-100">
            <!-- LOGO -->
            <div class="col-6">
                <div class="navbar-brand-box ">
                    <a  class="logo logo-dark">
                        <!-- Your Logo here -->
                        <img src="assets/diggri/logo/logo-dark.svg" alt="Logo" height="30">
                    </a>
                </div>

            </div>

            <!-- Other Elements... -->

            <!-- User Profile Section -->
            <div class="col-6 d-flex align-items-end justify-content-end">
                <div class=" d-inline-block" ngbDropdown>
                    <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
                        <img class="rounded-circle header-profile-user" src="assets/diggri/img/profile_photo.svg"
                            alt="Header Avatar">
                        <span
                            class="d-none d-xl-inline-block ms-1">{{submit?.data?.personalInformation1?.firstName}}</span>
                        <!-- <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i> -->
                    </button>
                    <!-- <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
             
                  <a class="dropdown-item" href="javascript: void(0);"><i class="bx bx-user font-size-16 align-middle me-1"></i>
                    {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
                  <a class="dropdown-item" href="javascript: void(0);"><i
                      class="bx bx-wallet font-size-16 align-middle me-1"></i>{{ 'HEADER.LOGIN.MY_WALLET' | translate}}</a>
                  <a class="dropdown-item d-block" href="javascript: void(0);"><span
                      class="badge bg-success float-end">11</span><i
                      class="bx bx-wrench font-size-16 align-middle me-1"></i>{{ 'HEADER.LOGIN.SETTINGS' | translate}} </a>
                  <a class="dropdown-item" href="javascript: void(0);"><i
                      class="bx bx-lock-open font-size-16 align-middle me-1"></i>
                    {{ 'HEADER.LOGIN.LOCK_SCREEN' | translate}}</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item text-danger" href="javascript: void(0);" ><i
                      class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                    {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
                </div> -->
                </div>
            </div>

        </div>
    </div>
</header>
<div  *ngIf="!loading" class="container" style="margin-top: 100px;">
    <div class="row">
        <div class="col-12">
            <div class="card overflow-hidden">
                <div class="bg-soft bg-primary">
                    <div class="row">
                        <div class="col-9">
                            <div class="text-primary p-3">
                                <h5 class="text-primary">Welcome Back !</h5>
                                <p>Review your profile information here</p>
                            </div>
                        </div>
                        <div class="col-3 align-self-end d-flex align-items-end justify-content-end">
                            <img [src]="userEvent?.image_url" style="max-height: 100px;" alt="" class="img-fluid p-3">
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- end card -->
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4">Personal Information</h4>

                    <!-- <p class="text-muted mb-4">Hi I'm {{submit?.data?.personalInformation1?.fullNameInEnglish}}, has been the industry's standard dummy text To an English
              person, it will seem like simplified English, as a skeptical Cambridge.</p> -->
                    <div class="table-responsive">
                        <table class="table table-nowrap mb-0">
                            <tbody>


                                <tr *ngIf="!itsMe">
                                    <th scope="row">Full Name :</th>
                                    <td>{{submit?.data?.personalInformation1?.fullNameInEnglish?submit?.data?.personalInformation1?.fullNameInEnglish:submit?.data?.personalInformation1?.firstName+'
                                        '+submit?.data?.personalInformation1?.lastName}}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Email :</th>
                                    <td>{{submit?.data?.personalInformation1?.emailAddress}}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Mobile :</th>
                                    <td>{{submit?.data?.personalInformation1?.phoneNumber?.e164Number}}</td>
                                </tr>
                                <!-- <tr>
                    <th scope="row">E-mail :</th>
                    <td>{{submit?.email}}</td>
                  </tr> -->
                                <!-- <tr>
                    <th scope="row">Location :</th>
                    <td>California, United States</td>
                  </tr> -->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!-- end card -->

    </div>
    <div class="row">
        <div *ngIf="(submit?.data?.hasUndefinedValue || submit?.data?.profileStatus?.status === 0) && submit.status==='rejected' && !loading" 
            class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="card">
                <div class="card-body">

                    <h4 *ngIf="submit?.data?.hasUndefinedValue" class="card-title">Upload Your Passport Picture</h4>

                    <p *ngIf="submit?.data?.hasUndefinedValue" class="text-warning mt-3">
                        Your previous passport picture was rejected. Please upload a new image that meets our
                        requirements.
                    </p>

                    <!-- File Input for Profile Photo -->
                    <input *ngIf="submit?.data?.hasUndefinedValue" type="file" class="form-control" accept="image/*"
                        (change)="checkfilePassport($event)" />

                    <h4 class="card-title mt-3" *ngIf="submit?.data?.profileStatus?.status === 0">Upload Your  Profile Picture</h4>
                    <p *ngIf="submit?.data?.profileStatus?.status === 0" class="text-warning mt-3">
                        Your previous profile picture was rejected. Please upload a new image that meets our
                        requirements.
                    </p>

                    <!-- File Input for Profile Photo -->
                    <input *ngIf="submit?.data?.profileStatus?.status === 0" type="file" class="form-control"
                        (change)="checkfileProfile($event)" accept="image/*" />
                    <!-- Submit Button -->
                    <div class="text-end mt-4">
                        <button type="button" (click)="onSubmit()" class="btn btn-primary">Submit</button>
                    </div>
                </div>
            </div>


        </div>
        <div class=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-5">Event</h4>
                    <div class="">
                        <ul class="verti-timeline list-unstyled">
                            <li class="event-list">
                                <div class="event-timeline-dot">
                                    <i *ngIf="userEvent?.status === 'In Progress'"
                                        class="bx bx-right-arrow-circle bx-fade-right"></i>
                                    <i *ngIf="userEvent?.status != 'In Progress'" class="bx bx-right-arrow-circle"></i>
                                </div>
                                <div class="d-flex">
                                    <div class="flex-shrink-0 me-3">
                                        <i class="bx bx-server h4 text-primary"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                        <div>
                                            <h5 class="font-size-15"><a href="javascript: void(0);"
                                                    class="text-dark">{{userEvent?.name}}</a></h5>
                                            <p>{{userEvent?.eventDescription}}</p>
                                            <!-- <span class="text-primary">  {{ (item?.date && item?.date[0].toDate() | date: 'dd/MM/yyyy') || '' }}-{{ (item?.date && item?.date[1].toDate() | date: 'dd/MM/yyyy') || '' }}</span> -->
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <!-- <li class="event-list">
                    <div class="event-timeline-dot">
                      <i class="bx bx-right-arrow-circle"></i>
                    </div>
                    <div class="d-flex">
                      <div class="flex-shrink-0 me-3">
                        <i class="bx bx-code h4 text-primary"></i>
                      </div>
                      <div class="flex-grow-1">
                        <div>
                          <h5 class="font-size-15"><a href="javascript: void(0);" class="text-dark">Front end Developer</a></h5>
                          <span class="text-primary">2013 - 16</span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="event-list">
                    <div class="event-timeline-dot">
                      <i class="bx bx-right-arrow-circle"></i>
                    </div>
                    <div class="d-flex">
                      <div class="flex-shrink-0 me-3">
                        <i class="bx bx-edit h4 text-primary"></i>
                      </div>
                      <div class="flex-grow-1">
                        <div>
                          <h5 class="font-size-15"><a href="javascript: void(0);" class="text-dark">UI /UX Designer</a></h5>
                          <span class="text-primary">2011 - 13</span>
                        </div>
                      </div>
                    </div>
                  </li> -->
                        </ul>
                    </div>

                </div>
            </div>
        </div>

    </div>

</div>

<div *ngIf="loading" class="center">
    <div class="wave"></div>
    <div class="wave"></div>
    <div class="wave"></div>
    <div class="wave"></div>
    <div class="wave"></div>
    <div class="wave"></div>
    <div class="wave"></div>
    <div class="wave"></div>
    <div class="wave"></div>
    <div class="wave"></div>
  </div>

<!-- end row -->