import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./core/guards/auth.guard";
import { FormGuard } from "./core/guards/form.guard";
import { LayoutComponent } from "./layouts/layout.component";
import { ClosedFormsComponent } from "./pages/closed-forms/closed-forms.component";
import { FormFilledCustomComponent } from "./pages/form-filled-custom/form.component";
import { FormFilledComponent } from "./pages/form-filled/form.component";
import { FormComponent } from "./pages/form/form.component";
import { SubmitNoUpdatePageComponent } from "./pages/submit-no-page-update/submit-no-page.component";
import { SubmitNoPageComponent } from "./pages/submit-no-page/submit-no-page.component";
import { ThankyoupageComponent } from "./pages/thankyoupage/thankyoupage.component";
import { GIMSComponent } from "./pages/gims/gims.component";
import { GimsFormioComponent } from "./pages/gims-formio/gims-formio.component";
import { CreateTermsAndConditionsComponent } from "./pages/dashboards/terms-and-conditions/create-terms-and-conditions/create-terms-and-conditions.component";
import { TermsAndConditionsComponent } from "./pages/terms-and-conditions/terms-and-conditions.component";
import { RoomsBookingsComponent } from "./pages/rooms-bookings/rooms-bookings.component";
import { BookingRoomsComponent } from "./pages/booking-rooms/booking-rooms.component";
import { PavilionBookingComponent } from "./pages/pavilion-booking/pavilion-booking.component";
import { ProfileSubmitUpdateComponent } from "./pages/profile-submit-update/profile-submit-update.component";
import { BlueammoniaComponent } from "./blueammonia/blueammonia.component";

const routes: Routes = [
  {
    path: "account",
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
  },
  {
    path: 'form/:formid/:categoryid',
    component: FormComponent,
  },
  {
    path: 'form/:formid/:categoryid/:lang',
    component: FormComponent,
  },
  {
    path: 'form/:formid/:categoryid/:roomID/:lang',
    component: FormComponent,
  },
  {
    path: 'form/:formid/:lang',
    component: FormComponent,
  },
  {
    path: 'form',
    component: FormComponent,
  },

  {
    path: "rooms-bookings",
    component: RoomsBookingsComponent,
  },
  {
    path: "websummit",
    component: BookingRoomsComponent,
  },
  {
    path: "websummit/:lang",
    component: BookingRoomsComponent,
  },
  {
    path: "pavilion-bookings",
    component: PavilionBookingComponent,
  },
  {
    path: "pavilion-bookings/:lang",
    component: PavilionBookingComponent,
  },
  {
    path: "gims-registration/:roomID",
    component: GimsFormioComponent,
  },
  {
    path: "gims-registration/:roomID/:lang",
    component: GimsFormioComponent,
  },
  {
    path: "profile-update/:userID",
    component: ProfileSubmitUpdateComponent
  },
  // {
  //   path: "gims-registration/:roomID",
  //   component: GIMSComponent,
  // },
  { path: "submit/:formid", component: FormFilledComponent },
  // { path: "rsvp/:formid", component: FormFilledComponent },
  { path: "galadinner/:uid", component: FormFilledCustomComponent },

  { path: "submit/:formid", component: FormFilledComponent },
  { path: "submit/:formid/:photos", component: FormFilledComponent },

  { path: "thankyou", component: ThankyoupageComponent },
  { path: "thankyou/:uid", component: TermsAndConditionsComponent },
  { path: "thankyou/:uid/:submitID", component: TermsAndConditionsComponent },
  { path: "galadinner-no/:uid", component: SubmitNoUpdatePageComponent },
  {
    path: 'form/:formid',
    component: FormComponent,
  },
  {
    path: "thank-you-pages/:id",
    component: TermsAndConditionsComponent
  },
  {
    path: "terms-and-conditions/:id",
    component: TermsAndConditionsComponent
  },
  {
    path: "terms-and-conditions/:id/:lang",
    component: TermsAndConditionsComponent
  },
  
  {
    path: "email-reject-page/:id",
    component: TermsAndConditionsComponent
  },
  {
    path: "email-reject-page/:id/:submitID",
    component: TermsAndConditionsComponent
  },
  
  {
    path: 'form/:formid/:categoryid',
    component: FormComponent,
  },
  {
    path: 'guest',
    component: BlueammoniaComponent,
  },
  {
    path: 'media',
    component: BlueammoniaComponent,
  },
  {
    path: 'services',
    component: BlueammoniaComponent,
  },
  {
    path: 'register/vip',
    component: BlueammoniaComponent,
  },
  {
    path: 'rsvp/vvip',
    component: BlueammoniaComponent,
  },
  {
    path: 'staff',
    component: BlueammoniaComponent,
  },
  {
    path: 'organizer',
    component: BlueammoniaComponent,
  },
  {
    path: 'security',
    component: BlueammoniaComponent,
  },
  {
    path: 'driver',
    component: BlueammoniaComponent,
  },
  // tslint:disable-next-line: max-line-length
  {
    path: "",
    component: BlueammoniaComponent,
    // loadChildren: () =>
    //   import("./pages/pages.module").then((m) => m.PagesModule),
    // canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
