<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.8/css/intlTelInput.css" />
<app-loader *ngIf="loading"></app-loader>
<form [ngClass]="{'rtl': isArabic}" [formGroup]="formSubmit" (ngSubmit)="onSubmit()">
  <div class="col-8 container mt-3 mb-5">
    <fieldset>
      <figure class="d-flex justify-content-center align-items-center p-2">
        <img class="col-10 col-xs-10 col-sm-10 col-md-6 col-lg-4 col-xl-4"
          src="assets/events-logos/websummit.png">
      </figure>

      <div class="d-flex justify-content-center align-items-center text-center">
        <h3 class="fw-bold">{{'Welcome' | translate}}!</h3>
      </div>
      <div class="d-flex justify-content-center align-items-center text-center">
        <label>
          <h4 class="fw-normal"> {{'Please fill the required fields to book a meeting room' | translate}}. </h4>
        </label>
      </div>
      <!-- <div class="parent-container" [ngClass]="{ 'rtl': selectedLanguage === 'Arabic' }">
        <input type="radio" name="language" id="english" value="English" (change)="updateLanguage($event)">
        <label for="english" class="cursor-pointer"
          [ngClass]="{'selected-label': !isArabic, 'not-selected-label': isArabic}">EN</label>

        <input type="radio" name="language" id="arabic" value="Arabic" (change)="updateLanguage($event)">
        <label for="arabic" class="cursor-pointer"
          [ngClass]="{'selected-label': isArabic, 'not-selected-label': !isArabic}">AR</label>

      </div> -->


      <div>
        <div>
          <div class="mb-1">
            <label>{{'Title' | translate}} <span style="color:red">*</span></label><br>
            <div class="row">
              <div *ngFor="let title of titles" class="col-4 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                <div class="form-check form-check-inline mt-2 mb-2">
                  <input type="radio" [value]="title.value" formControlName="title" id="title" class="form-check-input"
                    required>
                  <label class="form-check-label">{{isArabic ? title.arabicLabel : title.label}}</label>
                </div>

                <br>
              </div>
            </div>
            <div *ngIf="formSubmit.get('title').hasError('required') && formSubmit.get('title').touched"
              class="text-danger mb-3" style="font-size:12px">
              {{'Title Is Required' | translate}}
            </div>
          </div>
          <div *ngIf="showOtherTitleField()">
            <label for="otherTitle">{{'Other Title' | translate}} <span style="color:red">*</span></label>
            <input class="form-control mb-3" id="otherTitle" type="text" formControlName="otherTitle" required />
            <div *ngIf="formSubmit.get('otherTitle').hasError('required') && formSubmit.get('otherTitle').touched"
              class="text-danger mb-3" style="font-size:12px">
              {{'Other Title Is Required' | translate}}
            </div>
          </div>

          <div class="row">
            <div class="col-xm-12 col-sm-12 col-md-6 col-lg-6">
              <div>
                <label for="firstName">{{'First Name' | translate}} <span style="color:red">*</span></label>
                <input class="form-control mb-3" id="firstName" type="text" formControlName="firstName" required />
                <div *ngIf="formSubmit.get('firstName').hasError('required') && formSubmit.get('firstName').touched"
                  class="text-danger mb-3" style="font-size:12px">
                  {{'First Name Is Required' | translate}}
                </div>
              </div>
            </div>
            <div class="col-xm-12 col-sm-12 col-md-6 col-lg-6">
              <div>
                <label for="lastName">{{'Last Name' | translate}} <span style="color:red">*</span></label>
                <input class="form-control mb-3" id="lastName" type="text" formControlName="lastName" required />
                <div *ngIf="formSubmit.get('lastName').hasError('required') && formSubmit.get('lastName').touched"
                  class="text-danger mb-3" style="font-size:12px">
                  {{'Last Name Is Required' | translate}}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- <div class="col-xm-12 col-sm-12 col-md-6 col-lg-6">
              <label for="nationality">{{'Nationality' | translate}} <span style="color:red">*</span></label>
              <select class="form-control mb-3" id="nationality" formControlName="nationality" required>
                  <option *ngFor="let title of nationalities" [value]="title.value">{{ title.label }}</option>
                </select>
              <ng-select [clearable]="false" class="mb-3" required formControlName="nationality" id="nationality">
                <ng-option class="p-2" *ngFor="let title of nationalities" [value]="title.value">{{isArabic ?
                  title.arabicLabel :title.label }}</ng-option>
              </ng-select>

              <div *ngIf="formSubmit.get('nationality').hasError('required') && formSubmit.get('nationality').touched"
                class="text-danger mb-3" style="font-size:12px">
                {{'Nationality Is Required' | translate}}
              </div>
            </div> -->

            <!-- <div class="col-xm-12 col-sm-12 col-md-6 col-lg-6">
              <label for="gender">{{'Gender' | translate}} <span style="color:red">*</span></label>
              <ng-select [clearable]="false" class="mb-3" required formControlName="gender" id="gender">
                <ng-option class="p-2" value="male" class="p-2">{{'Male' | translate}}</ng-option>
                <ng-option class="p-2" value="female" class="p-2">{{'Female' | translate}}</ng-option>
                 <ng-option  class="p-2"value="preferNotToMention" class="p-2">{{'Prefer not to mention' | translate}}</ng-option> 
              </ng-select>

              <div *ngIf="formSubmit.get('gender').hasError('required') && formSubmit.get('gender').touched"
                class="text-danger mb-3" style="font-size:12px">
                {{'Gender Is Required' | translate}}
              </div>
            </div> -->
          </div>


          <div class="row">
            <div class="col-xm-12 col-sm-12 col-md-6 col-lg-6">
              <div>
                <label for="emailAddress">{{'Email Address' | translate}} <span style="color:red">*</span></label>
                <input class="form-control mb-3 ltr-text" dir="ltr" id="emailAddress" type="email"
                  formControlName="emailAddress" required />
                <div
                  *ngIf="formSubmit.get('emailAddress').hasError('required') && formSubmit.get('emailAddress').touched"
                  class="text-danger mb-3" style="font-size:12px">
                  {{'Email Address Is Required' | translate}}
                </div>
                <div *ngIf="formSubmit.get('emailAddress').hasError('email') && formSubmit.get('emailAddress').touched"
                  class="text-danger mb-3" style="font-size:12px">
                  {{'Invalid Email Format' | translate}}
                </div>
              </div>

            </div>

            <div class="col-xm-12 col-sm-12 col-md-6 col-lg-6">
              <label for="phoneNumber">{{'Phone Number' | translate}} <span style="color:red">*</span></label>
              <br />
              <div class="mb-3" style="width: 100% !important;">
                <phone-number-component [phoneNumber]="phoneNumber"
                  (phoneNumberChange)="phoneChange($event)"></phone-number-component>
              </div>

              <div *ngIf="formSubmit.get('phoneNumber').hasError('required') && formSubmit.get('phoneNumber').touched"
                class="text-danger mb-3" style="font-size:12px">
                {{'Phone Number Is Required' | translate}}
              </div>
              <div
                *ngIf="formSubmit.get('phoneNumber').hasError('validatePhoneNumber') && formSubmit.get('phoneNumber').touched"
                class="text-danger mb-3" style="font-size:12px">
                {{'Wrong Phone Number Format' | translate}}
              </div>
            </div>

            <div class="col-xm-12 col-sm-12 col-md-6 col-lg-6">
              <div>
                <label for="organization">{{'Organization' | translate}} </label>
                <input class="form-control mb-3" id="organization" type="text" formControlName="organization"
                   />
                <div
                  *ngIf="formSubmit.get('organization').hasError('required') && formSubmit.get('organization').touched"
                  class="text-danger mb-3" style="font-size:12px">
                  {{'Organization Is Required' | translate}}
                </div>
              </div>

            </div>
            <!-- <div class="col-xm-12 col-sm-12 col-md-6 col-lg-6">
              <label for="jobTitle">{{'Job Title' | translate}} <span style="color:red">*</span></label>
              <input class="form-control mb-3" id="jobTitle" type="text" formControlName="jobTitle" required />
              <div *ngIf="formSubmit.get('jobTitle').hasError('required') && formSubmit.get('jobTitle').touched"
                class="text-danger mb-3" style="font-size:12px">
                {{'Job Title Is Required' | translate}}
              </div>
            </div> -->
          </div>
          <hr class="solid">

          <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <label for="meeting-title">{{ 'Meeting Title' | translate }} <span style="color:red">*</span></label>
            <input class="form-control mb-3 mt-2" id="meeting-title" type="text" formControlName="meetingTitle" required />
            <div *ngIf="formSubmit.get('meetingTitle').hasError('required') && formSubmit.get('meetingTitle').touched"
              class="text-danger mb-3" style="font-size:12px">
              {{ 'Meeting Title Is Required' | translate }}
            </div>
          </div>
          <!-- <div class="row">
            <div class="mb-3" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div class="row">
                <label class="d-block mb-3">{{'Meeting Type' | translate}} <span style="color:red">*</span></label>
                <div class="col-4 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                  <div class="form-check form-check-inline  mb-2 ">
                    <input formControlName="meetingType" class="form-check-input" type="radio" id="inlineRadio2"
                      value="public">
                    <label class="form-check-label mb-2" for="inlineRadio2">{{'Public' | translate}}</label>
                  </div>
                </div>
                <div class="col-4 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                  <div class="form-check form-check-inline mb-2">
                    <input formControlName="meetingType" class="form-check-input" type="radio" id="inlineRadio1"
                      value="private">
                    <label class="form-check-label mb-2" for="inlineRadio1">{{'Private' | translate}}</label>
                  </div>
                </div>
                <div *ngIf="formSubmit.get('meetingType').hasError('required') && formSubmit.get('meetingType').touched"
                  class="text-danger mb-3" style="font-size:12px">
                  {{ 'Meeting Type Is Required' | translate }}
                </div>
              </div>



            </div>
            -->

          <!-- <div class="row">
            <div class="mb-3" class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 row">
              <label class="d-block mb-3"> {{ 'Flags Needed?' | translate }} <span style="color:red">*</span></label>
              <div class="col-4 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                <div class="form-check form-check-inline  mb-3">
                  <input formControlName="flagsNeeded" class="form-check-input mb-3" type="radio" id="yes" value="yes">
                  <label class="form-check-label mb-3" for="yes">{{'Yes' | translate}}</label>
                </div>
              </div>
              <div class="col-4 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                <div class="form-check form-check-inline mb-3">

                  <input formControlName="flagsNeeded" class="form-check-input mb-3" type="radio" id="no" value="no">
                  <label class="form-check-label mb-3" for="no">{{'No' | translate}}</label>

                </div>
              </div>
              <div *ngIf="formSubmit.get('flagsNeeded').hasError('required') && formSubmit.get('flagsNeeded').touched"
                class="text-danger mb-3" style="font-size:12px">
                {{ 'Flags Needed Is Required' | translate }}
              </div>
            </div>
            <div *ngIf="formSubmit.get('flagsNeeded').value ==='yes'">
              <label for="selectedFlags">{{'Specify which country/entity is involved' | translate}} <span
                  style="color:red">*</span></label>

              <ng-select [clearable]="false" class="mb-3" required formControlName="selectedFlags" id="selectedFlags"
                [multiple]="true">
                <ng-option *ngFor="let flag of flags" [value]="flag.value" class="p-2"> {{isArabic ? flag.arabicLabel
                  :flag.label }} </ng-option>
              </ng-select>

              <div
                *ngIf="formSubmit.get('selectedFlags').hasError('required') && formSubmit.get('selectedFlags').touched"
                class="text-danger mb-3" style="font-size:12px">
                {{'Country/Entity Is Required' | translate}}
              </div>
            </div>
          </div> -->

          <!-- <div [class.custom-align]="isArabic" class="custom-align">
            <label for="flags">{{ 'Flags' | translate }} </label>
            <div [ngClass]="{'d-flex justify-content-start': isArabic}" class="form-check form-check-primary  m-0 p-0 mb-3 ">
                <label class="form-check-label m-0 p-0  ml-3 pl-3" for="formCheckcolor1" >
                    {{ 'Flags Needed?' | translate }}
                </label>
                <input formControlName="flagsNeeded" class="form-check-input" type="checkbox" id="formCheckcolor1" name="flagsNeeded" style="margin-left: 0.1rem;">
            </div>
        </div> -->


          <!-- <input bsDatepicker class="form-control" [bsConfig]="bsConfig" [(ngModel)]="selectedDate"  (bsValueChange)="onDateChange($event)"  id="example-date-input2"
        [min]="minDate" [max]="maxDate"   > -->
          <div class="mb-3">
            <label for="date" class="col-md-2 col-form-label">{{'Select Date' | translate}} <span
                style="color:red">*</span></label>
            <ng-select placeholder="Select date" formControlName="date" (change)="onDateChange($event)">
              <ng-option *ngFor="let date of dateList" [value]="date">
                {{formatDateString(date)}}
              </ng-option>
            </ng-select>
            <!-- <input (change)="onDateChange($event)" class="form-control"  type="date" id="example-date-input"
              [min]="minDate" [max]="maxDate" formControlName="date" > -->
          </div>
          <div *ngIf="formSubmit.get('date').hasError('required') && formSubmit.get('date').touched"
            class="text-danger mb-3" style="font-size:12px">
            {{'Date Is Required' | translate}}
          </div>

          <div>
            <label for="duration">{{'Select Duration' | translate}} <span style="color:red">*</span></label>
            <ng-select [clearable]="false" placeholder="Select Duration" class=" mb-3"
              (change)="onDurationChange($event)" [(ngModel)]="duration.value" formControlName="duration" id="duration"
              required>
              <ng-option *ngFor="let duration of durations" [value]="duration.value">
                {{isArabic ? duration.arabicLabel : duration.label}}
              </ng-option>
            </ng-select>
            <div *ngIf="formSubmit.get('duration').hasError('required') && formSubmit.get('duration').touched"
              class="text-danger mb-3" style="font-size:12px">
              {{'Duration Is Required' | translate}}
            </div>
          </div>

          <div>
            <label for="attendees">{{'Attendees' | translate}} <span style="color:red">*</span></label>
            <input class="form-control mb-3" id="attendees" type="number" (change)="onAttendeesChange($event,true)"
              formControlName="attendees" required />
            <div *ngIf="formSubmit.get('attendees').hasError('required') && formSubmit.get('attendees').touched"
              class="text-danger mb-3" style="font-size:12px">
              {{'Attendees Is Required' | translate}}
            </div>
            <div *ngIf="formSubmit.get('attendees').errors?.min && formSubmit.get('attendees').touched"
              class="text-danger" style="font-size:12px">
              {{ 'Attendees must be greater than 0' | translate }}
            </div>

            <div class="col-12 mt-2">
              <label for="attendeesList">{{ 'Attendees List' | translate }}</label>
              <textarea class="form-control mb-3 mt-2" id="attendeesList"
                formControlName="attendeesList"></textarea>
            </div>



            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <label for="responsibleParty">{{ 'Responsible Party' | translate }} </label>
              <input class="form-control mb-3 mt-2" id="responsibleParty" type="text" formControlName="responsibleParty"  />
             
            </div>
            
            <div class="col-12 mt-2">
              <label for="additionalRequests">{{ 'Additional Requests' | translate }}</label>
              <textarea class="form-control mb-3 mt-2" id="additionalRequests"
                formControlName="additionalRequests"></textarea>


            </div>
           

            

            <label for="room" class="mt-3"> {{'Select a Meeting Room' | translate}} <span
                style="color:red">*</span></label>
            <div *ngIf="rooms?.length===0">
              {{'No Rooms Found' | translate}}
            </div>
            <div class="container pt-2">
              <div class="row flex-nowrap overflow-auto">
                <div class="col-auto box" [ngClass]="{'box': true, 'selected': selectedRoom?.uid === room.uid}"
                  *ngFor="let room of rooms" (click)="onRoomChange(room)">
                  <div class="d-flex justify-content-center align-items-center">{{ room.roomName }} </div>

                  <div class="smaller d-flex justify-content-center align-items-center">{{'Capacity' | translate}}
                    {{convertNumberToArabic(room.capacity)}}</div>
                </div>
              </div>
            </div>
            <div *ngIf="formSubmit.get('room').hasError('required') && formSubmit.get('room').touched"
              class="text-danger mb-3 mt-3" style="font-size:12px">
              {{'Room Is Required' | translate}}
            </div>
            <label *ngIf="selectedRoom" for="room" class="mt-3"> {{'Starting Time' | translate}} <span
                style="color:red">*</span></label>
            <div *ngIf="selectedRoom" class="container pt-2">
              <div class="row flex-nowrap overflow-auto" *ngIf="isArabic">
                <div class="col-auto box" (click)="selectHour(hour,selectedRoom)"
                  *ngFor="let hour of selectedRoom.hours"
                  [ngClass]="{'box': true, 'selected': selectedHour === hour, 'disabled': !hour.isAvailable}">
                  {{ hour.arabicTime }}
                </div>
              </div>
              <div class="row flex-nowrap overflow-auto" *ngIf="!isArabic">
                <div class="col-auto box" (click)="selectHour(hour,selectedRoom)"
                  *ngFor="let hour of selectedRoom?.hours"
                  [ngClass]="{'box': true, 'selected': selectedHour === hour, 'disabled': !hour.isAvailable}">
                  {{ hour.value }}
                </div>
              </div>
              <div *ngIf="selectedRoom.hours?.length===0">
                {{'All slots for the selected duration are full for this date.' | translate}}

              </div>
            </div>
            <div *ngIf="selectedRoom && formSubmit.get('time').hasError('required') && formSubmit.get('time').touched"
              class="text-danger mb-3" style="font-size:12px">
              {{'Starting Time Is Required' | translate}}
            </div>


            <!-- <label for="room">{{'Rooms' | translate}} <span style="color:red">*</span></label>
            <div *ngFor="let room of rooms" class="mt-2">
              <div>
                <h6> {{room.roomName}}</h6>
                <p>{{'Capacity' | translate}} {{convertNumberToArabic(room.capacity)}}</p>
                <p> {{room.description | translate}}</p>
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-auto" *ngFor="let item of room.equipmentList">
                        {{item.name | translate}} <img *ngIf="item.file && item.file != ''" [src]="item.file"
                          class="icon-img" />
                      </div>
                    </div>
                  </div>
                </div>

               


               

                [ngClass]="{'box': true, 'selected': selectedHour === hour, 'disabled': !hour.isAvailable}">
                      
                
              </div>
            </div>
            <div *ngIf="formSubmit.get('time').hasError('required') && formSubmit.get('time').touched"
              class="text-danger mb-3" style="font-size:12px">
              {{'Starting Time Is Required' | translate}}
            </div> -->
            <hr class="solid">
            <div *ngIf="selectedRoom && selectedRoom?.equipmentList?.length>0">
              <label for="room" class="mt-2"> {{'Add-ons' | translate}}</label>
              <div class="container pt-2">
                <div class="row flex-nowrap overflow-auto">
                  <div class="col-auto equipment-box" *ngFor="let item of selectedRoom?.equipmentList">
                    <div class="d-flex justify-content-center align-items-center"> {{item.name | translate}} </div>


                  </div>
                </div>
              </div>
              <div *ngIf="selectedRoom?.equipmentList?.length===0">
                {{'No Add-ons are available' | translate}}

              </div>
            </div>


          </div>
          <button type="submit" class="mt-3 mb-3 earthna w-100 p-2 text-light"
            style="background-color:#2172b4; border: 0px;border-radius: 5px;">{{'Submit' | translate}}</button>

        </div>
      </div>
    </fieldset>
  </div>
</form>