import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-thankyoupage',
  templateUrl: './thankyoupage.component.html',
  styleUrls: ['./thankyoupage.component.scss']
})
export class ThankyoupageComponent implements OnInit {

  constructor(private router: Router,private route: ActivatedRoute) {}
  part1:string
  part2:string
  ngOnInit(): void {
    this.part1 = this.route.snapshot.paramMap.get('typ1')
    this.part2 = this.route.snapshot.paramMap.get('typ2')
  }

}
