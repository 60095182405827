import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { countries } from "./countries-data";
import { FormioCustomComponent } from "angular-formio";

type CountryCode = string;

@Component({
  selector: "app-phone",
  templateUrl: "./phone.component.html",
  styleUrls: ["./phone.component.scss"],
})
export class PhoneComponent
  implements OnInit, FormioCustomComponent<any>, OnChanges
{
  number: any;
  filteredCountries: any[] = [];
  @Input() phoneNumber: any;
  @Output() phoneNumberChange = new EventEmitter<any>();
  selectedCountryFlag = "https://flagcdn.com/qa.svg";
  selectedCountryCode: CountryCode = " +" + "974";
  selectedISO = "QA";
  @Input()
  value: any;

  @Output()
  valueChange = new EventEmitter<any>();

  @Input()
  disabled: boolean;

  onInputChange(event: any) {
    const eventNumber = event?.target?.value;
    let phoneNumber = {
      countryCode: this.selectedISO,
      dialCode: this.selectedCountryCode,
      e164Number: this.selectedCountryCode + eventNumber,
      internationalNumber: this.selectedCountryCode + eventNumber,
      nationalNumber: eventNumber,
      number: eventNumber,
      selectedCountryFlag: this.selectedCountryFlag,
    };
    if (event?.target?.value) {
      this.value = phoneNumber;
    }

    if (this.number) {
      this.phoneNumberChange.emit(this.number);
      this.valueChange.emit(this.number);
    }
  }

  onCountrySelect(country) {
    this.selectedCountryCode = " +" + country.phoneCode;
    this.selectedISO = country.iso2.toLowerCase();
    this.selectedCountryFlag =
      "https://flagcdn.com/" + country.iso2.toLowerCase() + ".svg";
    let phoneNumber = {
      countryCode: this.selectedISO,
      dialCode: this.selectedCountryCode,
      e164Number: this.selectedCountryCode + this.phoneNumber,
      internationalNumber: this.selectedCountryCode + this.phoneNumber,
      nationalNumber: this.phoneNumber,
      number: this.phoneNumber,
      selectedCountryFlag: this.selectedCountryFlag,
    };
    console.log(phoneNumber);
    this.value = phoneNumber;
    this.onInputChange({
      event: {
        target: {
          value: phoneNumber,
        },
      },
    });
  }

  onSearch(searchTerm: string) {
    this.filteredCountries = countries.filter((country) =>
      country.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  constructor() {}

  ngOnInit(): void {
    this.filteredCountries = countries;

    if (this.phoneNumber) {
      this.number = this.phoneNumber.number;

      this.selectedCountryCode = this.phoneNumber.dialCode;
      this.selectedISO = this.phoneNumber.countryCode;
      this.selectedCountryFlag = this.phoneNumber.selectedCountryFlag;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    // This method will be called whenever there is a change in the input properties
    if ("value" in changes) {
      const newValue = changes.value.currentValue;
      const previousValue = changes.value.previousValue;

      if (changes.value.currentValue) {
        this.phoneNumber = changes.value.currentValue;
        let phoneNumber = {
          countryCode: changes.value.currentValue.countryCode,
          dialCode: changes.value.currentValue.dialCode,
          e164Number: changes.value.currentValue.e164Number,
          internationalNumber: changes.value.currentValue.internationalNumber,
          nationalNumber: changes.value.currentValue.nationalNumber,
          number: changes.value.currentValue.number,
        };
        if (changes.value.currentValue.number) {
          this.number = changes.value.currentValue.number;
          this.selectedCountryCode = changes.value.currentValue.dialCode;
          this.selectedISO = changes.value.currentValue.countryCode;
          this.selectedCountryFlag =
            "https://flagcdn.com/" + this.selectedISO.toLowerCase() + ".svg";
        }

        console.log(phoneNumber);
        this.onInputChange(phoneNumber);
      }

      // Perform actions based on the changes
      console.log(`Previous value: ${previousValue}, New value: ${newValue}`);
    }
  }
}
