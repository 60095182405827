import { Injectable } from "@angular/core";
import { getFirebaseBackend, firebase } from "../../authUtils";
import { User } from "../models/auth.models";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import axios from "axios";
import { UserX } from "../models/users";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private verificationId: any;
  private windowRef: any;
  private resolver: any;
  public user$: Observable<User>;
  public userData$: Observable<any>;
  private confirmationResult: firebase.auth.ConfirmationResult;

  constructor(private http: HttpClient, private router: Router) {
    const user = JSON.parse(localStorage.getItem("currentUser")) || null;
    this.currentUserSubject = new BehaviorSubject<User>(user);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  /**
   * Returns the current user
   */
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  async login(email: string, password: string) {
    try {
      const response: any = await getFirebaseBackend().loginUser(email.toLocaleLowerCase(), password);
      const user = response;
      if (user.navigate) {
        this.router.navigate(["/dashboard"]);
      }
      this.verificationId = user.verificationId;
      this.resolver = response.resolver;
      return user;
    } catch (error) {
      console.error("Login Error:", error);
      throw error;
    }
  }

  async verifyLogin(code) {
    const cred = firebase.auth.PhoneAuthProvider.credential(this.verificationId, code);
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    const credential = await this.resolver.resolveSignIn(multiFactorAssertion);

    try {
      const res = await this.getUser(credential.user.uid);
      this.currentUserSubject.next(res);
      localStorage.setItem("currentUser", JSON.stringify(res));
    } catch (error) {
      console.error("Verification Error:", error);
      throw error;
    }
  }

  currentUserData() {
    return getFirebaseBackend().getAuthenticatedUser();
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  async register(email: string, password: string) {
    try {
      const response: any = await getFirebaseBackend().addUser(email.toLocaleLowerCase(), password);
      const user = response;
      
      // const res = await this.getUser(user.uid);
      // let data = res;

      var reCaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
        size: "invisible",
      });

      return user;
    } catch (error) {
      console.error("Registration Error:", error);
      throw error;
    }
  }

  async registerUserDocument(
    uid: string,
    fullname: string,
    phone: any,
    email: string,
    photo_url: string,
    userRoles: any,
    company: string,
    events: any,
    forms: any
  ) {
    try {
      const arrayEventIDs = events === "All" ? [] : events.map((element: any) => element.uid);
  
      const dbref = firebase.firestore().collection("/users/").doc(uid);
  
      const userDocumentData = {
        eventsIDs: arrayEventIDs,
        uid: uid,
        phone: phone ,
        name: fullname,
        email: email.toLocaleLowerCase(),
        photo_url: photo_url,
        roles: userRoles,
        company: company,
        event: events,
        forms: forms,
      };
      dbref
      .set(userDocumentData)
      .then(() => {
         this.addMFA(uid, phone ? phone.e164Number : null);
      });
      
    } catch (error) {
      console.error("Register User Document Error:", error);
      throw error;
    }
  }
  

  getUserDocuments() {
    let userRef = firebase.firestore().collection("/users/");
    return userRef;
  }

  async getUsersByCompanyID(id) {
    let users = [];
    try {
      const userRef = firebase
        .firestore()
        .collection("/users/")
        .where("company.uid", "==", id);

      const res = await userRef.get();

      res.docs.forEach((data) => {
        let user = data.data();
        
        users.push(user);
      });

      const allUsersRef = firebase.firestore().collection("/users/").where("company", "==", "All");
      const allUsersRes = await allUsersRef.get();

      allUsersRes.docs.forEach((data) => {
        let user = data.data();
        users.push(user);
      });

      return users;
    } catch (error) {
      console.error("Get Users by Company ID Error:", error);
      throw error;
    }
  }

  async getUsersByEventID(id) {
    let event = JSON.parse(localStorage.getItem("event"));
    let forms = [];
    let events = [];
    try {
      const result = await firebase
        .firestore()
        .collection("/users/")
        .where("eventsIDs", "array-contains", id)
        .get();

      result.forEach((data) => {
        let form = data.data();
        forms.push(form);
      });

      const allEventResult = await firebase
        .firestore()
        .collection("/users/")
        .where("event", "==", "All")
        .get();

      allEventResult.forEach((data) => {
        let form = data.data();
        forms.push(form);
      });

      return forms;
    } catch (error) {
      console.error("Get Users by Event ID Error:", error);
      throw error;
    }
  }

  async getUser(uid: String) {
    try {
      const response: any = await firebase
        .firestore()
        .collection("/users/")
        .where("uid", "==", uid)
        .limit(1)
        .get();
      const user = response.docs[0].data();
      return user;
    } catch (error) {
      console.error("Get User Error:", error);
      throw error;
    }
  }

  async enterVerificationCode(verificationCode) {
    try {
      const result = await this.confirmationResult.confirm(verificationCode.toString());
      const user = result.user;
      return user;
    } catch (error) {
      console.error("Enter Verification Code Error:", error);
      throw error;
    }
  }

  async addMFA(id, phoneNumber) {
    try {
      let Axios = axios.create({
        baseURL: "https://us-central1-diggri.cloudfunctions.net",
        timeout: 45000,
      });

      await Axios.post("/configureMFA", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        body: {
          uid: id,
          phoneNumber: phoneNumber,
        },
        uid: id,
        phoneNumber: phoneNumber,
      });
    } catch (error) {
      console.error("Add MFA Error:", error);
      throw error;
    }
  }
  async logout() {
    try {
      // logout the user
      getFirebaseBackend().logout();
      localStorage.removeItem("currentUser");
      localStorage.removeItem("event");
      this.currentUserSubject.next(null);
    } catch (error) {
      console.error("Logout Error:", error);
      throw error;
    }
  }

  async updateUserDocument(
    uid: string,
    fullname: string,
    phone: any,
    email: string,
    userRoles: any,
    company: string,
    events: any,
    forms: any
  ) {
    let arrayEventIDs = [];
    if (events != "All")
      events.forEach((element) => {
        arrayEventIDs.push(element.uid);
      });
    try {

      const phoneNumber = phone.e164Number;
      let dbref = firebase.firestore().collection("/users/").doc(uid);
      await dbref.update({
        eventsIDs: arrayEventIDs,
        name: fullname,
        phone: phone,
        email: email.toLocaleLowerCase(),
        forms: forms,
        roles: userRoles,
        company: company,
        event: events,
      });

      await this.addMFA(uid, phoneNumber).finally(()=>{
        return {
          eventsIDs: arrayEventIDs,
          name: fullname,
          phone: phone,
          email: email.toLocaleLowerCase(),
          forms: forms,
          roles: userRoles,
          company: company,
          event: events,
        };
      });
      
    } catch (error) {
      console.error("Update User Document Error:", error);
      throw error;
    }
  }


  async resetPassword(email: string) {
    try {
      const response: any = await getFirebaseBackend().forgetPassword(email.toLocaleLowerCase());
      const message = response.data;
      return message;
    } catch (error) {
      console.error("Reset Password Error:", error);
      throw error;
    }
  }

  async updateUser(uid: string, data: any) {
    try {
    
      let dbref = firebase.firestore().collection("/users/").doc(uid);
      await dbref.update({ ...data }, { merge: true });

      await this.addMFA(uid, data.phone.e164Number);

      const user = await this.getUser(uid);
      return user;
    } catch (error) {
      console.error("Update User Error:", error);
      throw error;
    }
  }

  async deleteUser(id) {
    try {
      await firebase.firestore().collection("/users/").doc(id).set({deleted:true},{merge:true});
    } catch (error) {
      console.error("Delete User Error:", error);
      throw error;
    }
  }

  // The rest of the code remains unchanged...
  // ...

  canRead(user: UserX): boolean {
    const allowed = ["owner", "admin", "user", "mofa"];
    return this.checkAuthorization(user, allowed);
  }

  canEdit(user: UserX): boolean {
    const allowed = ["owner", "admin", "mofa"];
    return this.checkAuthorization(user, allowed);
  }

  canDelete(user: UserX): boolean {
    const allowed = ["owner"];
    return this.checkAuthorization(user, allowed);
  }

  checkAuthorization(user: UserX, allowedRoles: string[]): boolean {
    if (!user) return false;
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }
    return false;
  }
}
